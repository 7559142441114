.billing-dashboard {
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.2),
      rgba(219, 225, 241, 0.2)
    ),
    #ffffff;
  padding: 32px 40px;
  min-height: 100vh;
}

.homefeed {
  width: 894px;
  max-width: 100%;
  margin: auto;
}

.grid-container {
  display: grid;
  grid-template-columns: minmax(220px, 1fr) minmax(480px, 2fr);
  gap: 42px;
}

.grid-card {
  background-color: transparent;
  border-radius: 8px;
  width: 587px;
  max-width: 100%;
  position: relative;
  height: fit-content;
  margin-bottom: 32px;
}

.billing-dashboard p {
  margin-bottom: 0;
}

.incomplete {
  color: #e78a00;
}

.reviewed {
  color: #0da789;
}

.submitted {
  color: #4f4f4f;
}

/* SORT/FILTER STYLES */
.filter-option {
  padding: 4px 12px;
  height: max-content;
  border: 0.8px solid #c6c6c6;
  border-radius: 20px;
  white-space: nowrap;
  cursor: pointer;
  color: #000000;
  margin-right: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.filter-option-active {
  padding: 4px 12px;
  height: max-content;
  border-radius: 20px;
  white-space: nowrap;
  cursor: pointer;
  background: rgba(103, 179, 173, 0.2);
  color: #00403c;
  border: 1.8px solid #018077;
  margin-right: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.5px;
  display: flex;
  flex-wrap: wrap;
}

.filter-option-disabled {
  color: #c6c6c6;
  border: 0.8px solid #737272;
}

/* CHECKBOX STYLES */
.billing-dashboard-checkbox input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  width: 24px;
  height: 24px;
  border: 3px solid #000;
  border-radius: 5px;
  -webkit-transform: translateY(-0.075em);
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
.billing-dashboard-checkbox input[type="checkbox"]:checked:before {
  transform: scale(1.25);
}
.billing-dashboard-checkbox input[type="checkbox"]:before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: -webkit-transform 0.12s ease-in-out;
  transition: transform 0.12s ease-in-out;
  transition: transform 0.12s ease-in-out, -webkit-transform 0.12s ease-in-out;
  box-shadow: inset 1em 1em #000;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-clip-path: polygon(
    28% 38%,
    41% 53%,
    75% 24%,
    86% 38%,
    40% 78%,
    15% 50%
  );
  clip-path: polygon(28% 38%, 41% 53%, 75% 24%, 86% 38%, 40% 78%, 15% 50%);
}

.billing-dashboard .callout-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.billing-dashboard__results-container {
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  gap: 24px;
  width: 587px;
  max-width: 100%;
  background: rgba(219, 225, 241, 0.5);
  border-radius: 6px;
}

.billing-dashboard__results-card {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
  background: #ffffff;
  position: relative;
  cursor: pointer;
}

.billing-dashboard__results-card ul {
  margin-bottom: 0;
}
/* NEW TAG */
.billing-dashboard__new-tag {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 10px;
  position: absolute;
  width: 41px;
  height: 16px;
  top: -8px;
  background: #018077;
  border-radius: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
}

.billing-dashboard .mobile-search__close {
  margin: 0 0 42px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.remaining-tasks__li {
  list-style-type: disc;
  margin-left: 1.25rem;
}
@media screen and (max-width: 768px) {
  .billing-dashboard {
    background: #f2f1ef;
    padding: 0;
  }

  .gray-section {
    background: #f2f1ef;
  }

  .billing-dashboard .mobile-search__close {
    margin: 0 0 24px;
  }
  .hipaa-form .mobile-search__close {
    margin-top: 0px;
  }
  .text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 16px 32px;
  }
  .page-headings {
    font-family: "Crimson Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.353796px;
    color: #000000;
  }

  .billing-dashboard__results-container {
    max-width: 100%;
    padding: 0;
    background-color: transparent;
  }

  .billing-dashboard__results-card-status {
    letter-spacing: 1.5px;
  }
  .billing-dashboard__results-card-main-text {
    font-weight: 500;
  }
  .billing-dashboard__results-card-bolded {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.5px;
    color: #000000;
  }
}
