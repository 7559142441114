@import url("https://fonts.googleapis.com/css2?family=Poppins");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@800");
@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro");
@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@600");
@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@700");
@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@200");

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  min-height: 100%;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* TEXT STYLES */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Crimson Pro", "Times New Roman", Times, serif;
  margin: 0;
  letter-spacing: normal;
}

h1.large-heading {
  font-size: 60pt;
  line-height: 67pt;
}

/* h2 {
  font-size: 48px !important;
  line-height: 53px !important;
} */

h3 {
  font-size: 43px !important;
  line-height: 48px !important;
}

h4 {
  font-size: 30px !important;
  line-height: 33px !important;
}

h5 {
  font-size: 2.25rem !important;
  line-height: 40px !important;
}
a {
  cursor: pointer;
}

p,
a {
  font-family: "Poppins";
  color: black;
}

.bold {
  font-weight: bold;
}

.text-large {
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.13px;
}

.secondary-bold {
  font-size: 20px;
  line-height: 153.5%;
}

.text-medium {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.6px;
}

.text-alt-heading {
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.text-subheading {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.132px;
}

.text-description {
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 23px;
}

.text-small {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
}

.text-xsmall {
  font-size: 10px;
  letter-spacing: 1.5px;
  line-height: 16px;
  margin: 0px !important;
}

.body-1 {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
}

.body-2 {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.body-3 {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.25px;
}

.label-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #080707;
}
.poppins {
  font-family: "Poppins";
}

.heading-poppins {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.132px;
}

.subheading-poppins {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.heading-crimson-pro {
  font-family: "Crimson Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0.353796px;
  color: #000000;
}

.subheading-crimson-pro {
  font-family: "Crimson Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 27px;
  letter-spacing: 0.353796px;
  color: #000000;
}

.center-text {
  text-align: center;
}

.visible {
  visibility: visible;
}

.uppercase {
  text-transform: uppercase;
}

/* FONT WEIGHT */
.fw-200 {
  font-weight: 200;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}
.flex-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-wrapper-space-between {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.flex-row {
  display: flex;
}

/* MARGIN */
.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-bottom-4 {
  margin-bottom: 4px !important;
}

.margin-bottom-8 {
  margin-bottom: 8px !important;
}

.margin-bottom-16 {
  margin-bottom: 16px !important;
}

.margin-bottom-24 {
  margin-bottom: 24px !important;
}

.margin-bottom-32 {
  margin-bottom: 32px !important;
}

/* WIDTH STYLES */
.width-343 {
  width: 100%;
  max-width: 343px;
}

.width-896 {
  width: 100%;
  max-width: 898px;
  margin: 0 auto;
}
/* MULTIUSE STYLES */
.progress {
  height: 0.5rem !important;
}
.pointer {
  cursor: pointer;
}

li::after {
  display: none;
}

.faq-link,
.faq-link:hover {
  color: inherit;
  text-decoration: underline;
}
.back-arrow {
  border: solid black;
  border-width: 0 2.5px 2.5px 0;
  display: inline-block;
  padding: 2.5px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  height: 2.5px;
  width: 2.5px;
  position: relative;
  z-index: 66;
  cursor: pointer;
}

.arrow-down {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid black;
  z-index: 0;
}

.arrow-right {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.generic-container {
  background-color: #f8f8f8;
}

/* COLORS */

.color-gray {
  color: #363636;
}

.color-default-blue {
  color: #4c6bbb !important;
}

.color-262525 {
  color: #262525;
}

.color-dark-blue {
  color: #2a4b9f;
}
/* miscellaneous background colors */
.bg-light-blue {
  background-color: #dbe1f1;
}

@media screen and (max-width: 768px) {
  .generic-container {
    background-color: #f7f7f7;
  }
}
