.handl-logo {
  margin: 0px;
  font-weight: 900;
}

.icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nav-link {
  margin-left: 10px;
  margin-bottom: 0px;
  cursor: pointer;
  text-decoration: none;
}

.dropdown-item:active {
  background-color: transparent !important;
}

a {
  text-decoration: none;
}

a::hover {
  text-decoration: none;
}

.nav-link::hover {
  text-decoration: none;
}

.nav-link-text {
  margin-bottom: 0px;
}

.navbar {
  max-width: 100vw;
}

.mobile-header.bg-white {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
}

.header {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
}

.white-arrow {
  border-color: white !important;
}

.back {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
}

.quote-navigation {
  padding: 80px 40px 40px 40px;
  width: 100%;
}

.back-text {
  padding-left: 24px;
  margin-bottom: 0px;
  margin-top: -4px;
}

#resources-dropdown-toggle.btn-primary {
  color: black;
  font-family: "Poppins";
}

#mobile-dropdown-toggle.dropdown-toggle::after,
#resources-dropdown-toggle.dropdown-toggle::after {
  display: none !important;
}

#mobile-dropdown-toggle.blue,
#mobile-dropdown-toggle.blue:focus,
#mobile-dropdown-toggle.blue:active,
#resources-dropdown-toggle.blue,
#resources-dropdown-toggle.blue:focus,
#resources-dropdown-toggle.blue:active {
  background-color: #b7c4e4;
  border-color: #b7c4e4;
  box-shadow: none;
}

#mobile-dropdown-toggle.white,
#mobile-dropdown-toggle.white:focus,
#mobile-dropdown-toggle.white:active,
#resources-dropdown-toggle.white,
#resources-dropdown-toggle.white:focus,
#resources-dropdown-toggle.white:active {
  background-color: white;
  border-color: white;
  box-shadow: none;
}

#mobile-dropdown-toggle.dark,
#mobile-dropdown-toggle.dark:focus,
#mobile-dropdown-toggle.dark:active {
  background-color: #333333;
  border-color: #333333;
  box-shadow: none;
}

#mobile-dropdown-toggle.black,
#mobile-dropdown-toggle.black:focus,
#mobile-dropdown-toggle.black:active {
  background-color: #080707;
  border-color: #080707;
  box-shadow: none;
}

.mobile-header .dropdown .dropdown-menu {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  right: 0px;
  top: 50px;
  left: unset;
}

.mobile-header.bg-black {
  background-color: #080707;
}

a.dropdown-item a {
  color: black;
}

.handl-logo.dark,
.handl-logo.black {
  color: white;
}

.back-arrow.quote-navigation-arrow {
  position: absolute;
  left: 40px;
  top: 82px;
}

.dark-background {
  background-color: #333333 !important;
}

/* USER NAVIGATION */

.navigation-bar {
  position: sticky;
  top: 0;
  background-color: #dbe1f1;
  display: flex;
  align-items: center;
  height: 80px;
  width: 100%;
  padding: 24px 32px;
  z-index: 9999;
  justify-content: space-between;
}

.navigation-bar .nav-left-container {
  align-items: center;
  gap: min(3vw, 36px);
}

.navigation-bar__page-links {
  display: flex;
  gap: max(3vw, 28px);
}

.navigation-bar__user-actions {
  display: flex;
  gap: min(1.25vw, 1.5rem);
}

.navigation-bar__link {
  color: #080707;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.132px;
}

.navigation-bar__page-links button.navigation-bar__dropdown {
  background-color: transparent;
  color: #080707;
  border: none;
  box-shadow: none;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.132px;
  padding: 0px;
  display: block;
}

button.navigation-bar__dropdown::after {
  display: none !important;
}

button.navigation-bar__dropdown:focus,
button.navigation-bar__dropdown:active,
button.navigation-bar__dropdown:hover,
.navigation-bar__link:hover {
  background-color: transparent;
  color: #080707;
  text-decoration: none;
}

/* UPDATED MOBILE NAV */

/* hamburger */
.nav-toggle {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 22px;
}

.nav-toggle .bar {
  height: 3px;
  width: 100%;
  background-color: black;
  transition: all 100ms ease-in-out;
}
.x:nth-of-type(1) {
  transition: all 100ms ease-in-out;
  transform: rotate(45deg);
  transform-origin: top left;
  width: 25px;
}

.x:nth-of-type(2) {
  transition: all 100ms ease-in-out;
  transform-origin: center;
  width: 0;
}

.x:nth-of-type(3) {
  transition: all 100ms ease-in-out;
  transform: rotate(-45deg) translate(-3px, 3px);
  transform-origin: bottom left;
  width: 25px;
}

.updated-mobile-nav-container {
  background-color: #dbe1f1;
  height: 100vh;
  min-height: -webkit-fill-available;
  width: 100vw;
  padding-top: 64px;
  padding-inline: 24px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  transform: translateY(-100%);
  transition: transform 250ms ease-out;
  overflow-y: auto;
}

.updated-mobile-nav-container__active {
  transform: translateY(0%);
}

.updated-mobile-nav-container__menu-options {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #0a2530;
  margin-bottom: 32px;
}

.updated-mobile-nav-container ul {
  padding-top: 10px;
  padding-left: 12px;
  visibility: hidden;
}
.updated-mobile-nav__li-options {
  margin-bottom: 16px;
}
.updated-mobile-nav__li-options,
.navigation-bar__user-actions .navigation-bar__user-actions-btn {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #080707;
}
.updated-mobile-nav-container__menu-options:hover {
  color: #4c6bbb;
}

.updated-mobile-nav-container__help-section {
  padding-top: 40px;
}
.updated-mobile-nav-container__help-section {
  margin-bottom: 24px;
}
.updated-mobile-nav-container__handl-logo {
  position: absolute;
  bottom: 0;
  right: -24px;
}

.find-an-estimate-container {
  padding-top: 42px;
  padding-bottom: 42px;
  gap: 22px;
}

.line {
  width: 327px;
  height: 0px;

  /* Blue/Dark */

  border: 1px solid #4c6bbb;
}

.updated-mobile-nav-container__home-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  letter-spacing: 0.132px;
  margin-bottom: 0px;
}
/* DESKTOP NAVIGATION */
.mobile-header {
  padding-top: 0.8125rem !important;
  padding-bottom: 0.8125rem !important;
  padding-inline: 24px !important;
}
.navigation-bar .dropdown .btn:focus {
  box-shadow: none !important;
}
p.userActions-navigation__dropdown-open,
div.userActions-navigation-dropdown-open,
div.userActions-navigation__dropdown-open > p {
  position: relative;
  color: #4c6bbb;
}
p.userActions-navigation__dropdown-open::after,
div.userActions-navigation__dropdown-open::after {
  content: "";
  height: 4px;
  background-color: #4c6bbb;
  width: 100%;
  position: absolute;
  border-radius: 99px;
  bottom: -8px;
  left: 0;
}

.navigation-bar .userActions-navigation__dropdown-menu a.dropdown-item:hover {
  text-decoration: none;
  color: #4c6bbb;
}

.navigation-bar .userActions-navigation__dropdown-menu {
  background: #dbe1f1;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 0px;
  top: 24px !important;
  left: -10px !important;
  border: none;
  min-width: 5em;
}

.navigation-bar .userActions-navigation__dropdown-menu .dropdown-item,
.userActions-navigation-bar__items {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #080707;
}

.navigation-bar .userActions-navigation__dropdown-menu .dropdown-item:active,
.navigation-bar .userActions-navigation__dropdown-menu .dropdown-item:hover {
  color: #080707;
  background-color: transparent;
}

.navigation-bar .userActions-navigation__dropdown-user-information-menu {
  background-color: #ffffff;
  padding: 45px 33px 45px;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.15);
  border: none;
  top: 10px !important;

  width: 195px;
}

.userActions-navigation__dropdown-user-information .dropdown-item {
  display: contents;
  width: 0;
  padding: 0;
  white-space: inherit;
}

.userActions-navigation__dropdown-user-information-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.25px;
  color: #080707;
  margin-bottom: 8px;
}

.userActions-navigation__dropdown-user-information a:hover {
  text-decoration-color: rgba(54, 54, 54, 0.9);
  text-decoration-line: underline;
}
.userActions-navigation__dropdown-user-information li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: rgba(54, 54, 54, 0.9);
  margin-bottom: 12px;
}

.userActions-navigation__dropdown-user-information button {
  margin: auto;
  font-weight: 500;
}
.userActions-navigation__dropdown-user-information button:nth-of-type(1) {
  margin-top: 24px;
}
.userActions-navigation__dropdown-user-information button:nth-of-type(2) {
  margin-top: 24px;
}

/* SEARCH ICON STYLES */
.navigation-bar__user-actions-search {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigation-bar__user-actions-search-icon {
  cursor: pointer;
}
.navigation-bar__user-actions-search-active {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.5),
      rgba(219, 225, 241, 0.5)
    ),
    #ffffff;
  position: absolute;
  z-index: -1;
}

.navigation-bar__user-actions-search-inactive {
  display: none;
}

@media screen and (max-width: 820px) {
  .navigation-bar {
    padding: 8px;
  }

  .userActions-navigation__dropdown-open {
    text-decoration-line: none;
  }
  p.userActions-navigation__dropdown-open::after,
  div.userActions-navigation__dropdown-open::after {
    width: 0%;
  }
}
