/* Estimate Callout */

.estimate__estimate-callout {
  padding: 40px 64px;
  background-color: #f7f7f7;
  color: #363636;
}

.estimate__estimate-width {
  max-width: 896px;
  margin: auto;
}

.estimate__estimate-width > p.pointer {
  margin-bottom: 24px;
}

.estimate__estimate-callout-overline-text {
  letter-spacing: 0.4px;
  margin-bottom: 24px;
}

.estimate__estimate-callout-facility-detail {
  text-align: center;
  letter-spacing: 1px;
  color: #080707;
  background: #dbe1f1;
  border-radius: 20px;
  width: fit-content;
  padding: 4px 16px;
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.estimate__callout-main-text {
  margin: 0px;
  max-width: 642px;

  font-family: "Crimson Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 43px;
  line-height: 127.13%;
  letter-spacing: 0.25px;
  color: #363636;
}

button.button.estimate__save-estimate-button {
  margin: 40px 0px 0px 0px;
  background: linear-gradient(
      0deg,
      rgba(103, 179, 173, 0.7),
      rgba(103, 179, 173, 0.7)
    ),
    #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.328612);
  border-radius: 18px;
  color: #363636;
}

/* Estimate Sections */
.estimate__estimate-section {
  background-color: white;
  padding: 24px 32px 0px 16px;
  padding: 32px 64px;
  margin-bottom: 32px;
}

.estimate__estimate-section-title,
.estimate__more-facilities-description {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.15px;
  color: #080707;
  margin-bottom: 24px;
}

.estimate__more-facilities-description {
  font-weight: 500;
  color: #4c6bbb;
}

.estimate__estimate-section-container {
  display: flex;
  justify-content: space-between;
}

.estimate__estimate-section-emphasized {
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: 0.132px;
  width: 40%;
  margin-bottom: 0px;
  margin-top: -8px;
  color: #4c6bbb;
}

.cost-and-coverage .estimate__estimate-section-emphasized {
  width: 246px;
}

.estimate__estimate-section-description {
  width: 100%;
  max-width: 504px;
}

.container-with-top-padding {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #c4c4c4;
}

/* Payment Breakdown */
.estimate__payment-breakdown {
  margin-top: 40px;
}
.estimate__payment-accordion-toggle,
.estimate__edit-details {
  font-weight: 500;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.estimate__payment-accordion-toggle img {
  margin-left: 1rem;
}

.estimate__payment-breakdown .card-header {
  border: none;
}

.estimate__payment-accordion-toggle-open {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  content: "";
  margin-left: 10px;
  margin-bottom: 3px;
}

.estimate__payment-accordion-toggle-close {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  content: "";
  margin-left: 10px;
}

.estimate__payment-breakdown-details.card-body {
  background: #f7f7f7;
  border-radius: 20px;
  margin-top: 37px;
  padding: 0px;
}

.estimate__payment-breakdown-overline {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
  margin-bottom: 0px;
}

.estimate__payment-breakdown-overline p {
  margin-bottom: 0px;
}

.estimate__payment-breakdown-description {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.4px;
  width: 70%;
  margin: 0px;
}

.estimate__payment-breakdown-you-pay {
  background: rgba(13, 167, 137, 0.25);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top: 0.8px solid rgba(13, 167, 137, 0.6);
}

.estimate__payment-breakdown-section {
  padding: 13px 23px;
}

/* Coverage Details */

.estimate__coverage-detail-line {
  display: flex;
  justify-content: space-between;
}

.estimate__edit-details {
  margin-top: 16px;
  margin-bottom: 0;
}

/* Update Coverage */

.estimate__update-coverage-details .cost-estimate-label {
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
  margin-top: 30px;
}

.estimate__update-coverage-details .form-group {
  margin: 0px;
}

.estimate__update-coverage-details.card-body {
  padding-top: 0px;
}

.estimate__update-coverage-details button {
  background-color: transparent;
  color: black;
  border: none;
  box-shadow: none;
}

.estimate__coverage-updated-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.15px;
}

/* Coverage Form */
.estimate__coverage-form-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
  display: block;
  position: relative;
}

.estimate__coverage-form .form-group:not(:last-of-type) {
  margin-bottom: 30px;
}

.estimate__coverage-form .form-group:last-of-type {
  margin-bottom: 0px;
}

.estimate__coverage-form-container {
  padding: 32px 40px;
  background: #f8f8f8;
  border-radius: 20px;
}

button.button.estimate__update-coverage-btn {
  margin: 40px 0px 0px 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

/* Where You'll Be */

.estimate__facility-details {
  width: 50%;
}

.estimate__facility-details-text p {
  margin-bottom: 8px;
}
.estimate__facility-details iframe {
  border-radius: 10px;
}

.estimate__facility-distance {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 12px;
  color: #080707;
}

.estimate__facility-name-and-cost {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
  font-weight: 600;
}

.estimate__facility-address {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  margin-bottom: 0px;
}

/* More Facilities */

.estimate__more-facilities {
  display: flex;
  margin-bottom: 80px;
}

.estimate__more-facilities > div {
  display: flex;
}
.estimate__more-facilities-text {
  width: 40%;
}

.estimate__facilities-list-container {
  max-width: 504px;
  width: 100%;
}
ul.estimate__facilities-list {
  list-style-type: none;
  padding: 0px;
}

.estimate__facilities-list li {
  margin-bottom: 8px;
  cursor: pointer;

  padding: 16px 24px 16px 32px;

  /* Background for Apps/Sections */

  background: #f8f8f8;
  border-radius: 10px;
}

.estimate__facility-list-item-name {
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #080707;
  max-width: 305px;
}

.estimate__facility-list-item-address {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  margin-bottom: 0px;
}

#estimate__estimate-pdf {
  display: none;
}

@media only screen and (max-width: 768px) {
  /* Estimate Callout */
  #estimate-page .estimate__estimate-callout {
    padding: 84px 16px 32px;
  }

  #estimate-page .estimate__callout-main-text {
    font-weight: 700;
    font-size: 24px;
    line-height: 127.13%;
    letter-spacing: 0.25px;
    color: #363636;
  }

  #estimate-page .estimate__estimate-callout-overline {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 15px;
    gap: 16px;
  }

  #estimate-page .estimate__estimate-callout-overline-text,
  #estimate-page .estimate__estimate-callout-facility-detail {
    margin-bottom: 0px;
  }

  #estimate-page .estimate__save-estimate-button {
    width: 100%;
    margin-top: 32px;
  }

  .estimate__estimate-callout-facility-detail {
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 1px;
    padding: 4px 8px;
  }

  .estimate__estimate-width > p.pointer {
    margin-bottom: 32px;
  }
  /* Estimate Sections */
  #estimate-page .estimate__estimate-section-text {
    width: 100%;
    margin-top: 12px;
  }

  #estimate-page .estimate__estimate-section-container {
    flex-direction: column;
  }

  #estimate-page .estimate__estimate-section-description {
    width: 100%;
  }

  #estimate-page .estimate__estimate-section-emphasized {
    width: 100%;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.13px;
    margin-bottom: 24px;
  }

  #estimate-page .estimate__payment-accordion-toggle,
  .estimate__edit-details {
    text-align: center;
  }

  #estimate-page .estimate__payment-accordion-toggle::after {
    display: none;
  }

  /* Coverage Form */

  #estimate-page .estimate__coverage-updated-text {
    text-align: center;
  }

  button.button.estimate__update-coverage-btn {
    margin: 40px auto 0px auto;
    font-size: 15px;
    line-height: 22px;
  }

  /* Where You'll Be */

  #estimate-page .estimate__facility-details {
    width: 100%;
  }

  #estimate-page .where-youll-be .estimate__estimate-section-emphasized {
    /* display: none; */
  }

  #estimate-page .estimate__facility-name-and-cost {
    flex-direction: column;
  }

  #estimate-page .estimate__facility-name-and-cost p {
    margin-bottom: 8px;
  }

  /* More Facilities */
  #estimate-page .estimate__more-facilities {
    flex-direction: column;
    margin-top: 35px;
  }

  .estimate__more-facilities > div {
    flex-direction: column;
  }

  #estimate-page .estimate__more-facilities-text {
    width: 100%;
  }

  #estimate-page ul.estimate__facilities-list {
    width: 100%;
  }

  .estimate__estimate-section {
    padding: 32px 16px;
  }

  .estimate__estimate-section-title {
    font-size: 18px;
    line-height: 27px;
  }
  .estimate__facilities-list li {
    padding: 8px 24px 8px 16px;
  }

  .estimate__facility-list-item-name {
    font-size: 15px;
    line-height: 24px;
  }
  .estimate__coverage-form-container {
    padding: 32px 24px;
  }
}
