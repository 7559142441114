.press-release__body {
  max-width: 896px;
  width: 100%;
  margin: 48px auto auto;
}

ul.press-release-list {
  list-style-type: none;
  padding: 0px;
  margin-bottom: 60px;
  margin-top: 64px;
}

.press-release__press-release-image {
  height: 270px;
  width: 178px;
  object-fit: cover;
}

.press-release {
  display: flex;
  margin-bottom: 40px;
  gap: 24px;
}

.press-release__press-release-information {
  display: flex;
  flex-direction: column;
}

.press-release__press-release-information .body-1 {
  max-width: 678px;
}
.press-release__press-release-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.6px;
}

.press-release__press-release-summary {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.6px;
  margin: 12px 0px;
}

.press-release__press-release-link {
  color: black;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.6px;
  align-self: center;
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  .press-release {
    flex-direction: column;
  }
  .press-release__body {
    margin: 72px auto auto;
  }
  ul.press-release-list {
    margin-top: 32px;
  }
  .press-release__press-release-information {
    padding-inline: 16px;
  }
  .press-release__press-release-image-wrap {
    margin: auto;
  }
  .press-release__press-release-image {
    width: 343px;
  }
}
