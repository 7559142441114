.estimate-form-section__title {
  font-weight: 700;
  font-size: 64px;
  line-height: 72px;
  letter-spacing: 0.6px;
  padding-right: 400px;
  margin-bottom: 24px;
}

.estimate-form-section__description {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.132px;
  margin-bottom: 60px;
}

button.estimate-form-section__button {
  color: white;
  background: #4C6BBB;
  border-radius: 100px;
  padding: 12px 104px;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  margin: 0;
}

button.button.estimate-form__next-btn {
  margin: 0px 0px 0px 20px;
}


/* MOBILE */
.quote-option-input.form-control {
  color: black;
  background-color: #D8D8D8;
  border-bottom: 1px solid black;

  width: 100%;
  padding: 0.75rem 0.75rem 0.75rem 3rem;


}

.estimate-form-section__button.submit-button {
  margin: auto;
  background: #B0B6C6;
}

.estimate-form-section__button.submit-button.active {
  background: #4C6BBB;
}

.estimate-form__input-container {
  position: relative;
  margin-bottom: 64px;
}

@media only screen and (max-width: 768px) {
  .estimate-form-section {
    padding: 80px 16px;
  }

  .estimate-form-section__title {
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    align-items: center;
    padding-right: 100px;
    margin-bottom: 8px;
  }

  .estimate-form-section__description {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.132px;
    margin-bottom: 48px;
  }

  .estimate-form-section__button {
    padding: 8px 64px;
    margin: auto;
  }

  .estimate-form__input-container {
    margin-bottom: 56px;
  }
}
