.first-visit-heading {
  font-family: 'Crimson Pro', 'Times New Roman', Times, serif;
  font-weight: 900;
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 12px;
}

.first-visit-text {
  margin: 0px;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 23px;
}

.first-visit-btn {
  margin-bottom: 24px !important;
}

.first-visit-modal.modal .modal-dialog {
  position:fixed;
  bottom: 10px;
  left: 10px;
  margin:0px;
}

@media only screen and (max-width: 768px) {

  .first-visit-heading {
    font-size: 25px;
    line-height: 33px; 
  }
  
  .first-visit-text {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
  }

  .first-visit-modal.modal .modal-dialog {
    width: 80vw;
  }

  .first-visit-popup .contact-form .contact-input {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
  }

}
