.our-tools {
  padding: 32px 16px;
  position: relative;
}

.our-tools p {
  margin-bottom: 0;
}

.our-tools-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 16px;
  gap: 24px;
  max-width: 100%;
  border-radius: 10px;
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.5),
      rgba(219, 225, 241, 0.5)
    ),
    #ffffff;
}

.our-tools-container img {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.our-tools-container .flex-col {
  align-items: center;
  justify-content: center;
  align-self: center;
}

.our-tools-container .text-subheading {
  margin-bottom: 10px;
}
.our-tools-container .body-2 {
  text-align: center;
  max-width: 311px;
  margin-bottom: 24px;
}

/* ARROWS */
.our-tools .swiper {
  padding-bottom: 32px !important;
}
.custom-otc-swiper-button-prev,
.custom-otc-swiper-button-next {
  bottom: 44px;
  position: absolute;
  z-index: 22;
  cursor: pointer;
  width: 10px;
}

.custom-otc-swiper-button-prev {
  left: 35%;
}

.custom-otc-swiper-button-next {
  right: 35%;
}
/* LAST TOOL CONTAINER */
.last-tools-container .body-2,
.last-tools-container .text-subheading {
  text-align: center;
}
.last-tools-container .subheading-crimson-pro {
  margin-bottom: 32px;
}

.last-tools-container > div.flex-col {
  gap: 95px;
}
@media screen and (max-width: 768px) {
  .our-tools {
    background: linear-gradient(
        0deg,
        rgba(219, 225, 241, 0.2),
        rgba(219, 225, 241, 0.2)
      ),
      #ffffff;
  }
}
