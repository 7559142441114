footer {
  overflow: hidden;
  background-color: #0a2530;
}

.footer-links > .flex-col > p {
  color: #fff;
  margin-bottom: 8px;
}

.footer-container {
  margin: auto;
  padding: 24px 80px 40px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-row {
  display: flex;
}

.footer-icons img {
  margin-left: 32px;
}

.footer-links {
  width: 100%;
  justify-content: space-between;
}

span.footer-title {
  color: white;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 23px;
}

a.footer-link {
  color: white;
  text-decoration: none !important;
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 27px;
  font-family: "Poppins";
  font-style: normal;
}

a.footer-link:hover {
  color: white;
  text-decoration: none !important;
}

.about-us-link {
  margin-left: auto;
}
@media only screen and (max-width: 768px) {
  .footer-container {
    padding: 30px 30px 40px 30px;
  }

  .footer-links {
    flex-direction: column;
  }

  .about-us-link {
    margin: 40px 0px 24px;
  }

  .footer-icons img {
    margin-left: 0;
    margin-right: 32px;
  }
}
