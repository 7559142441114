.all-services-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}

.service-container {
  padding: 1rem;
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  box-sizing: border-box;
  background-color: white;
  cursor: pointer;
}

.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.tab-background {
  background-color: #EFEFEF;
}

.handl-category-description {
  margin-top: 24px;
  margin-bottom: 40px;
}

.handl-category {
  background-color: #EFEFEF !important;
  padding-bottom: 50px;
}

.all-services-heading {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 21px;
}



@media only screen and (max-width: 768px) {

  .all-services-list {
    grid-template-columns: 1fr;
    margin-left: -15px;
    margin-right: -15px;
  }

  .service-container {
    padding: 1rem 1rem 1rem 3rem;
  }

  .arrow {
    display: none;
  }

  .all-services-label {
    width: 175px;
  }

}
