.bill-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  gap: 24px;
  width: 265px;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
}

.bill-sidebar p {
  text-align: center;
}
