.not-available-input.form-control {
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 27px;
  text-align: center;
  border-radius: 24px;
  margin: auto;
  width: 350px;
  padding: 10px 15px;
  height: auto;
}

.not-available {
  width: 700px;
  max-width: 100%;
  margin: 12% auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 24px;
  background: #ffffff;
  border-radius: 20px;
}

.not-available section {
  margin-top: 20px;
  margin-bottom: 30px;
}

.not-available-heading {
  font-family: "Crimson Pro";
  font-size: 37px;
  font-weight: 900;
  letter-spacing: 0.25px;
  line-height: 41px;
  text-align: center;
}

.not-available-btn.button {
  margin: auto;
  font-size: 13px;
  padding: 10px 40px;
  letter-spacing: 1.25px;
  line-height: 20px;
}

.search-another-estimate-btn-container {
  margin-top: 56px;
}
@media screen and (max-width: 768px) {
  .not-available {
    background-color: transparent;
    margin-top: 56px;
    padding: 0;
  }
  #not-available {
    padding: 0px 16px 135px;
  }
  .not-available .body-1 {
    font-size: 15px;
    line-height: 24px;
  }

  .not-available-main > p {
    padding-inline: 16px;
  }
}
