.legalese ul {
  list-style-type: disc;
  padding-left: 2rem;
}

.legalese {
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.2),
      rgba(219, 225, 241, 0.2)
    ),
    #fff;
}
.legalese-container {
  padding: 64px 40px;
  width: 894px;
  max-width: 100%;
  margin: auto;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 3px 10px 2px rgb(0 0 0 / 7%);
}

.legalese li {
  padding-top: 16px;
}
.legalese p {
  line-height: 1.714;
  letter-spacing: 0.08rem;
}

.legalese a {
  color: #080707;
  text-decoration: underline;
}
.legalese table,
th,
td {
  border: 1px solid;
}

@media screen and (max-width: 768px) {
  .legalese-container {
    padding-inline: 16px;
  }
}
