.see-more-estimates {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #080707;
  border: none;
  margin: auto;
  display: block;
  background-color: transparent;
}

.all-appts-visible {
  color: #737272;
  cursor: default !important;
}
