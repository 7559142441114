.currently-selected {
  background-color: rgba(103, 179, 173, 0.2) !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  letter-spacing: 0.132px !important;
  color: #018077 !important;
}

.fancy-search-sort {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
}

.dropdown > button.filter-option {
  padding: 4px 12px;
  height: max-content;
  border: 0.8px solid #c6c6c6;
  border-radius: 20px;
  white-space: nowrap;
  cursor: pointer;
  color: #000000;
  box-shadow: none;
}

.dropdown .dropdown-toggle::after {
  display: none;
}

.dropdown-item.filter-item:hover {
  background: rgba(103, 179, 173, 0.1) !important;
}

.dropdown-item.filter-item {
  padding: 19px 0px 19px 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.dropdown .btn-primary:not(.filter-option-active):focus {
  background-color: transparent !important;
  border: 0.8px solid #c6c6c6 !important;
  box-shadow: none !important;
  color: #000000 !important;
}

.dropdown > button.filter-option-active {
  padding: 4px 12px;
  height: max-content;
  border-radius: 20px;
  white-space: nowrap;
  cursor: pointer !important;
  background: rgba(103, 179, 173, 0.2) !important;
  color: #00403c !important;
  border: 1.8px solid #018077 !important;
  box-shadow: none;
}
.dropdown .btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.dropdown .btn-primary:not(:disabled):not(.disabled):active {
  background-color: transparent;
  border: 0.8px solid #c6c6c6;
  box-shadow: none;
  color: #000000;
}

@media screen and (max-width: 768px) {
  .fancy-search-sort {
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.25px;
  }
}
