.bill-tracker {
  padding: 40px;
}
.bill-tracker__top-container {
  width: 872px;
  margin: auto;
  max-width: 100%;
}

.bill-tracker__tracker-section {
  margin-top: 72px;
}

.bill-tracker .mobile-search__close {
  margin: 0 0 16px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.bill-tracker__circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid #696969;
  border-radius: 50%;
  position: relative;
  top: 30px;
}

.bill-tracker__container:not(:last-child) .bill-tracker__circle::after {
  content: "";
  height: 80px;
  width: 0.5px;
  position: absolute;
  background-color: #696969;
  top: calc(100% + 5px);
  left: 50%;
}
.bill-tracker__box p {
  margin-bottom: 0;
}
.bill-tracker__box {
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 70px 12px 16px;
  gap: 4px;
  width: 552px;
  max-width: 100%;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #080707;
  margin-bottom: 32px;
  cursor: pointer;
}

.bill-tracker__container {
  width: 600px;
  max-width: 100%;
  margin: auto;
  gap: 16px;
}

.bill-tracker__lockedIcon {
  width: 24px;
  height: 24px;
}
/* BORDER STYLES */
.border-completed {
  border: 1px solid #018077;
}

.inactive-container div {
  border: 1px solid #868686;
  color: #868686;
}

.inactive-container p {
  color: #868686;
}
.green-circle {
  background-color: #018077;
}
@media screen and (max-width: 768px) {
  .bill-tracker {
    padding: 0px;
  }
  .bill-tracker__top-container {
    background: #dbe1f1;
    padding: 60px 16px 16px;
  }

  .bill-tracker__top-container h5 {
    width: 300px;
    max-width: 100%;
    letter-spacing: 0.353796px;
  }

  .bill-tracker__tracker-section {
    margin-top: 64px;
  }
  .bill-tracker__container {
    width: 311px;
  }
  .bill-tracker__circle {
    width: 24px;
    height: 24px;
    top: 11.5px;
  }

  .bill-tracker__container:not(:last-child) .bill-tracker__circle::after {
    top: calc(100% + 18px);
  }

  .bill-tracker__box {
    width: 271px;
    max-width: 100%;
    margin-bottom: 60px;
  }

  .bill-tracker__lockedIcon {
    width: 18px;
    height: 18px;
  }
}
