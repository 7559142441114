.articles-container {
  background-color: #f8f8f8;
}

.article-content-container {
  background-color: white;
}
.article-list__body {
  max-width: 960px;
  width: 100%;
  margin: auto;
}

.article-list__page-title {
  font-family: Crimson Pro;
  font-weight: bold;
  font-size: 64px;
  line-height: 72px;
  letter-spacing: 0.6px;

  margin: 48px 0px 24px;
}

.article-list__subtitle {
  font-family: Poppins;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.6px;

  margin-bottom: 40px;
}

.article-list__see-more-button {
  padding: 8px 24px;
  width: 287px;
}

.article-list__filter-and-sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.article-sort {
  display: flex;
  gap: 32px;
}

.article-sort .filter-option,
.article-sort .filter-option-active {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 1.25px;
  margin-right: 0;
  padding: 8px 32px;
}

.article-sort .filter-option-active {
  font-weight: 600;
}
button.article-sort__button {
  background-color: transparent;
  color: #080707;
  box-shadow: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 0px;
  padding: 12px 32px;
}

button.article-sort__button.active-tab {
  border: 2px solid #080707;
  border-radius: 130px;
  font-weight: 600;
}

.article-filter {
  display: flex;
  position: relative;
  border-bottom: 1px solid #080707;
  width: 100%;
  max-width: 412px;
}

.article-filter .input-container div input,
.article-filter .input-container select {
  border-bottom: none;
}

.article-filter .input-and-label {
  padding-top: 0px;
}
.article-filter__search-icon-container {
  border-bottom: 1px solid #636363;
  padding-right: 12px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

ul.article-list__list {
  list-style-type: none;
  padding: 0px;
  margin: 32px 0px;
  width: 100%;
}

a.article-list__article-link {
  color: black;
}

a.article-list__article-link:hover {
  color: black;
  text-decoration: none;
}

.first-article .article-list__article-image-container {
  overflow: hidden;
  display: flex;
  align-items: center;
}

.first-article .article-list__article-image {
  width: 100%;
  min-height: 100%;
  min-width: 100%;
}

.first-article .article-list__article-details {
  display: grid;
  grid-template-columns: minmax(auto, 400px) minmax(auto, 560px);
  column-gap: 24px;
  margin-top: 24px;
  margin-bottom: 104px;
  padding-inline: 24px;
}

.first-article .article-list__article-information {
  height: fit-content;
  width: 100%;
  max-width: 360px;
}

.articles-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px;
}

.article-list__article-title {
  font-weight: 600;
  margin-bottom: 8px;
  letter-spacing: 0.6px;
}

.first-article .article-list__article-title {
  font-size: 24px;
  line-height: 36px;
}

.article-list__article-date-and-keywords {
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.6px;

  margin-bottom: 16px;
}

.article-list__article-date-and-keywords .article-list__article-keywords {
  font-style: italic;
}

.article-list__article-author {
  display: flex;
  align-items: center;
  margin-bottom: 28px;
}

.article-list__author-icon {
  background-color: #c4c4c4;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
}

.article-list__author-name {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.6px;
}

.article-list__article-summary {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
}

.article-list__article-summary-read-more {
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .articles-container {
    background-color: #f7f7f7;
  }

  .article-list__body {
    margin: 60px 0px 0px 0px;
  }

  .article-list__page-title {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 0.6px;
    margin: 0px;
    padding: 12px 16px 8px 16px;
  }

  .article-list__subtitle {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.6px;
    margin: 0px;
    padding: 0px 16px 32px 16px;
  }

  .article-list__filter-and-sort {
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 16px;
  }

  button.article-sort__button {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.15px;
    padding: 8px 24px;
  }

  .article-sort {
    gap: 16px;
  }
  .article-sort .filter-option,
  .article-sort .filter-option-active {
    padding: 8px 16px;
  }
  .article-filter {
    width: 100%;
    margin-top: 32px;
  }

  .article-filter div:not(.article-filter__search-icon-container) {
    width: 100%;
  }

  .article-filter div {
    font-family: Poppins;
  }

  .article-filter div input {
    width: 100%;
    flex-grow: 1;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.15px;
  }

  .first-article .article-list__article-details {
    display: block;
  }

  .first-article .article-list__article-image-container {
    max-height: 280px;
  }

  .first-article .article-list__article-title {
    font-size: 22px;
    line-height: 33px;
  }

  .first-article .article-list__article-details {
    padding: 0px 16px;
    margin-bottom: 32px;
  }

  .first-article .article-list__article-date-and-keywords {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.25px;
  }

  .article-list__author-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  .article-list__article-summary {
    font-size: 15px;
    line-height: 24px;
    padding-bottom: 24px;
    margin-top: 24px;
  }
  .articles-grid-container {
    grid-template-columns: 1fr;
  }
}
