.glossary {
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 992px;
  padding-inline: 16px;
  width: 100%;
  margin: 0 auto;
}

.glossary-description {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.6px;
}

.glossary-terms {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.6px;
}
.glossary-term__term {
  font-weight: 700;
  margin-bottom: 12px;
}

.glossary-term__def {
  margin-bottom: 24px;
}
.glossary-search-input.form-control {
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 27px;
  margin: auto;
  padding: 10px 15px;
  height: auto;
  border-bottom: none;
  box-shadow: none;
}
.glossary-search-container {
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #636363;
  margin-bottom: 32px;
}

.glossary-search-container img {
  top: 10px;
  position: relative;
}
.glossary-search-container .input-container div input {
  border-bottom: none;
}
.glossary-search-input.form-control:focus {
  box-shadow: none;
  border-bottom: 1px solid #636363;
}

h1.generic-heading {
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;
  letter-spacing: 0.6px;
  margin-bottom: 24px;
}

@media only screen and (max-width: 768px) {
  .glossary-body {
    display: block;
    padding-inline: 16px;
  }

  .glossary-information {
    margin-bottom: 40px;
  }

  .glossary-description {
    font-size: 16px;
    line-height: 24px;
  }

  .glossary {
    margin: 72px auto auto;
    padding-top: 0px;
    padding-inline: 0;
    padding-bottom: 100px;
  }

  h1.generic-heading {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 8px;
    padding-inline: 16px;
  }
}
