.service-page__insured-price-bars,
.service-page__cash-price-bars {
  position: relative;
  z-index: 0;
}

.service-page__cash-price-bars {
  margin-top: 54px;
  margin-bottom: 46px;
}

.service-page__bar {
  position: relative;
}

.service-page__bar.max-bar {
  position: absolute;
  top: 0;
  min-width: 150px;
}

.service-page__bar-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}

.service-page__cash-price-bars .service-page__bar-labels {
  -webkit-animation: show-bar-two 1.2s 0.2s forwards;
  -moz-animation: show-bar-two 1.2s 0.2s forwards;
  animation: show-bar-two 1.2s 0.2s forwards;
}

.service-page__insured-price-bars .service-page__bar-labels {
  -webkit-animation: show-bar-four 1.2s 0.4s forwards;
  -moz-animation: show-bar-four 1.2s 0.4s forwards;
  animation: show-bar-four 1.2s 0.4s forwards;
}

.service-page__bar-labels p {
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: white;
  margin: 0px;
}

.service-page__min-cash-price .service-page__antimated-bar:after {
  width: 24px;
  background: #A96355;
  position: absolute;
  height: 100%;
  content: '';
  right: -12px;
  top: 0;
  transform: skew(35deg);
  z-index: 1;
}

.service-page__min-insured-price .service-page__antimated-bar:after {
  width: 24px;
  background: #42738F;
  position: absolute;
  height: 100%;
  content: ' ';
  right: -12px;
  top: 0;
  transform: skew(35deg);
  z-index: 1;
}

.service-page__min-cash-price .service-page__antimated-bar {
  background-color: #A96355;
  z-index: 1;
  position: relative;
  -webkit-animation: show-bar-one 1.2s 0.1s forwards;
  -moz-animation: show-bar-one 1.2s 0.1s forwards;
  animation: show-bar-one 1.2s 0.1s forwards;
  padding: 6px 16px 6px 8px;
}

.service-page__max-cash-price .service-page__antimated-bar{
  background-color: #AA3D26;
  text-align: right;
  -webkit-animation: show-bar-two 1.2s 0.2s forwards;
  -moz-animation: show-bar-two 1.2s 0.2s forwards;
  animation: show-bar-two 1.2s 0.2s forwards;
  border-radius: 0px 36px 36px 0px;
  padding: 6px 16px 6px 8px;
}

.service-page__min-insured-price .service-page__antimated-bar{
  background-color: #42738F;
  z-index: 1;
  position: relative;
  -webkit-animation: show-bar-three 1.2s 0.3s forwards;
  -moz-animation: show-bar-three 1.2s 0.3s forwards;
  animation: show-bar-three 1.2s 0.3s forwards;
  padding: 6px 16px 6px 8px;
}

.service-page__max-insured-price .service-page__antimated-bar{
  background-color: #22536F;
  text-align: right;
  -webkit-animation: show-bar-four 1.2s 0.4s forwards;
  -moz-animation: show-bar-four 1.2s 0.4s forwards;
  animation: show-bar-four 1.2s 0.4s forwards;
  border-radius: 0px 36px 36px 0px;
  padding: 6px 16px 6px 8px;
}

/* Bar Graph Horizontal Animations */
@keyframes show-bar-one {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes show-bar-two {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes show-bar-three {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes show-bar-four {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
