.toggle-switch-container {
  display: flex;
  justify-content: space-between;
}
.toggle-switch {
  position: relative;
  width: 36px;
  display: inline-block;
  text-align: left;
  top: 8px;
}

.toggle-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
}
.checkbox {
  display: none;
}
.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
}
.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 20px;
  padding: 0;
  line-height: 20px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}
.inner:before {
  content: "";
  padding-left: 10px;
  background-color: #018077;
  color: #fff;
}
.inner:after {
  content: "";
  padding-right: 10px;
  background-color: #A1ADAD;;
  color: #fff;
  text-align: right;
}
.switch {
  display: block;
  width: 16px;
  height: 16px;
  margin: 2px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.checkbox:checked + .label .inner {
  margin-left: 0;
}
.checkbox:checked + .label .switch {
  right: 0px;
}
