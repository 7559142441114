.sign-in__design-blue {
  position: absolute;
  overflow: hidden;
  height: 105%;
}

.sign-in__design-blue svg {
  width: 32vw;
  height: 100%;
}

.sign-in__design-reset-image {
  position: absolute;
  left: 2vw;
}
.sign-in__design-default-image {
  position: absolute;
  bottom: 10px;
  left: 2vw;
  max-width: 200px;
}
