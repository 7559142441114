.service-page {
  font-family: Poppins;
}

.service-page .content-page__heading {
  background-color: #00403C;
  color: white;
}

.service-page .content-page__description {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
  margin-bottom: 0px;
  color: white;
}

.service-group__section-description {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
  margin-bottom: 16px;
}

.service-page__input.form-control {
  background: #EAEAEA;
  border-radius: 6px;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #7D7D7D;
  border-bottom: 1px solid #979797;
  width: 100%;
  padding: 0px 16px;
  height: 48px;
}

.service-page__estimate-form-label {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.132px;
  width: 100%;
  flex-shrink: 1;
}

.service-page__estimate-form-label.label-margin {
  margin-top: 40px;
}

.service-page__estimate-form-container  {
  margin-bottom: 40px;
  display: flex;
  align-items: baseline;
}

.service-page__estimate-form-container .service-page__estimate-form-label {
  width: 75%;
}

.service-group__section-details .error-message p {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.6px;
  margin-bottom: 4px;
}

.service__see-cash-estimate {
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 1.25px;
  text-align: center;
  margin: 16px auto 0px auto;
}

.service-page__price-text {
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.132px;
  margin: 0px;
  z-index: 2;
  position: relative;
}

button.button.service-heading-btn {
  margin: 72px 0px 0px auto;
  background: #D8D8D8;
  color: #080707;
}

.best-value-pill {
  background: rgba(13, 167, 137, 0.8);
  border-radius: 38px;
  width: fit-content;
  padding: 4px 18px;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 1px;
  margin-bottom: 16px;
}

.service-page__estimate-form-label.insurance-input {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.5px;
  padding: 0px;
  margin: 40px 0px 8px 0px;
}

.service-page__form-group.form-group {
  margin-bottom: 40px;
}

.service-form__input-container {
  position: relative;
  margin-bottom: 32px;
  width: 100%;
}

.service-page__estimate-form-container .service-form__input-container {
  margin-bottom: 0px;
}

.service-form__input-container input {
  position: relative;
}

.service-form__input-container span{
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.4px;
  margin-top: 4px;
}

@media only screen and (max-width: 768px) {

  .service-group__section-description {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 24px;
  }

  button.button.service-heading-btn {
    width: 100%;
  }

  .service-page__estimate-form-label,
  .service-page__estimate-form-label.insurance-input {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.132px;
    margin-bottom: 8px;
  }

  .service-page__estimate-form-container {
    display: block;
  }
}
