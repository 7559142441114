
.service-group-page .content-page__heading {
  background-color: rgba(0, 64, 60, 0.2);
}

.service-group__section-why {
  padding-bottom: 56px;
}

.service-group__section-varieties{
  padding-bottom: 64px;
}

.service-group__section-faq{
  padding-bottom: 164px;
}


.service-group__section-description {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
  margin-bottom: 16px;
}

ul.service-group__service-list {
  list-style-type:none;
  margin: -32px 0px 32px 0px;
  padding: 0px;
}

.service-group__service-list a:hover {
  text-decoration: none;
}

.service-group__see-all-services-text {
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 1.25px;
  font-weight: 500;
  color: #080707;
}

.service-group__see-all-services-text:hover {
  text-decoration: none;
  color: #080707;
}

ul.service-group__articles-list {
  list-style-type:none;
  padding: 0px;
  margin: 0px;
}

.service-group__articles-section {
  max-width: 896px;
  margin: 86px auto 86px auto; 
}

.service-group__articles-section-title {
  font-family: Crimson Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 67px;
}

.service-group__articles-section-description {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.6px;
  margin-bottom: 24px;
}

.service-group__contact-section {
  max-width: 896px;
  margin: 86px auto 86px auto; 
}

@media only screen and (max-width: 768px) {
  .service-group__section-description {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 24px;
  }

  .service-group__articles-section {
    padding: 102px 8px 0px 8px;
    margin: 0px;
  }

  .service-group__contact-section {
    margin: 0px;
  }

  .service-group__articles-section-title {
    font-size: 42px;
    line-height: 47px;
    letter-spacing: 0.6px;
    padding-right: 8px;
    padding-left: 8px;
  }

  .service-group__articles-section-description {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.6px;
    padding-right: 8px;
    padding-left: 8px;
  }

  .service-group .contact-section__contact-container {
    padding-top: 44px;
  }
}
