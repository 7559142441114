.question-section {
  background-color: white;
  padding: 32px 0px;
  margin-bottom: 24px;
}

.question-section__width {
  max-width: 896px;
  margin: auto;
}

.question-section__overline {
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.15px;
  margin-bottom: 16px;
}

.question-section__content {
  display: flex;
}

.question-section__title {
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: 0.132px;
  min-width: 350px;
  margin-top: -8px;
  width: 80%;
  padding-right: 50px;
}

.question-section__details {
  width: 100%;
}

.question-section__question-card {
  margin-bottom: 16px;
  color: #767575;
}

.question-section__question-card.active {
  color: #080707;
}

.question-section__question-card-body.card-body {
  padding: 8px 0px;
}

.question-section__toggle {
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.132px;
}

@media only screen and (max-width: 768px) {
  .question-section__content {
    display: block;
  }

  .question-section {
    padding: 32px 16px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  }

  .question-section__overline {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.15px;
    margin-bottom: 8px;
  }

  .question-section__title {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.132px;
    margin-bottom: 24px;
    padding: 0px;
    width: 100%;
  }

  .question-section__toggle {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.132px;
  }
}
