.sign-in__content h1 {
  font-weight: 900;
  font-size: 43px;
  line-height: 48px;
  letter-spacing: 0.25px;
  margin-bottom: 16px;
  color: #080707;
  white-space: nowrap;
}

.sign-in__subtitle {
  color: #d94411;
  font-weight: 400;
  font-size: 18px;
  min-height: 27px;
  letter-spacing: 0.5px;
  margin-bottom: 28px;
}

.sign-in__content {
  max-width: 650px;
  margin: auto;
}

.sign-in-form__input {
  margin-bottom: 32px;
}

.sign-in-form__remember-me {
  display: flex;
  align-items: center;
}

.sign-in-form__remember-me input {
  height: 18px;
  width: 18px;
  min-width: 18px;
}

.sign-in-form__remember-me p {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  display: inline-block;
  margin-left: 12px;
  margin-bottom: 0;
}

.sign-in-form__remember-me-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

.sign-in-btn {
  margin-top: 80px;
  margin-bottom: 32px;
}

.sign-in__outline-btn > button.button.outline-btn.active {
  background-color: transparent;
}

.sign-in__or-element {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}

.sign-in__or-element span {
  margin: 0px 16px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #080707;
}

.sign-in__or-bar {
  height: 1px;
  width: 40px;
  background-color: #000000;
}

.sign-in__privacy-link {
  color: #080707;
  display: flex;
  justify-content: center;
  font-family: Poppins;
  font-style: Regular;
  font-size: 11px;
  line-height: 17px;
  line-height: 100%;
  vertical-align: top;
  margin-bottom: 8px;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 32px;
}

.sign-in__privacy-link:hover,
.sign-in__privacy-link:visited {
  color: #080707;
}

/* ACCOUNT CREATION */
.account-creation-main-content {
  width: 100%;
  max-width: 704px;
  margin: 0 auto;
}

.account-creation-margin-top {
  margin-top: 56px;
}
.account-creation-header {
  max-width: 704px;
  margin: auto;
}
.account-creation-sign-up .input-container {
  width: 575px;
  max-width: 100%;
  margin: auto;
  margin-top: 32px;
}
.account-creation-sign-up .sign-in__subtitle {
  width: 704px;
  max-width: 100%;
}

.account-creation-sign-up .sign-in__subtitle {
  margin: 8px auto 0;
}

.account-creation__action-buttons {
  margin-top: 65px;
}

.account-creation__action-buttons button:last-of-type {
  margin-bottom: 80px;
}

.account-creation-helper-description {
  width: 704px;
  max-width: 100%;
  margin: 24px auto 40px;
}

@media only screen and (max-width: 768px) {
  .sign-in__design {
    display: none;
  }

  .account-creation-main-content {
    padding: 40px 16px 0;
  }
  .sign-in__content {
    width: 100%;
  }

  .sign-in__content h1 {
    margin-bottom: 8px;
    white-space: normal;
  }

  .sign-in__subtitle {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
  }

  .sign-in__sidebar {
    display: none;
  }
  .account-creation-margin-top {
    margin-top: 48px;
  }
  .sign-in__privacy-link {
    margin-bottom: 14px;
  }
}
