.forgot-password-form {
  max-width: 575px;
  margin: auto;
}

.forgot-password-form button.long-btn {
  margin-top: 104px;
  margin-bottom: 120px;
}
.forgot-password-form__input {
  margin-bottom: 56px;
}

.forgot-password__error-span {
  display: flex;
  justify-content: center;
}
