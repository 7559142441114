.service-index {
  max-width: 992px;
  margin: auto;
  padding: 58px 16px;
}

.service-index__heading-title {
  font-weight: bold;
  font-size: 60px;
  line-height: 67px;
  margin-bottom: 20px;
}

.service-index__heading-description {
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.132px;
  margin-bottom: 32px;
}

.service__list-item.index-page {
  padding: 12px 0px;
}

ul.service-index__list div {
  column-gap: 24px !important;
}

.service-index__list a:hover {
  text-decoration: none;
}

.service__list-item-overline {
  font-family: Poppins;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  margin: 0px;
}

.index-page .service__list-item-centerline {
  font-family: Poppins;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.132px;
  margin-top: 4px;
  margin-bottom: 0px;
}

.service-index__nav {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.132px;
  margin-top: 48px;
  margin-bottom: 32px;
}

.service-index__nav-item {
  cursor: pointer;
}

.service-index__nav-item.active {
  color: #0DA789;
  text-decoration: underline;
}

.service-index__filter-section {
  display: flex;
  align-items: center;
}

button.button.service-index__filter-btn {
  margin-left: 10px;
}

.service-index__index-group-heading {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.132px;
  text-align: center;
  border-bottom: 2px solid #080707;
  width: 30px;
  margin-top: 12px;
}



@media only screen and (max-width: 768px) {
  .service-index {
    padding: 98px 16px;
  }

  .service-index__heading-title {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 0.6px;
  }
  
  .service-index__heading-description {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
  }

  .service-index__list {
    display: block;
  }

  button.button.service-index__filter-btn {
    display: none;
  }

  .service-index__body {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .service-index__nav { 
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.132px;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 12px;
  }

  .service-index__services-count {
    text-align: center;
    margin-top: 48px;
  }

  .service-index__services-count-text {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .service-index__services-count-bar {
    content: '';
    width: 100%;
    height: 4px;
    background: #C4C4C4;
    border-radius: 4px;
  }

  .service-index__services-count-bar-filled {
    content: '';
    height: 4px;
    background: #080707;
    border-radius: 4px
  }
}
