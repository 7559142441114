.estimates-catalog {
  overflow-x: auto;
  overflow-y: hidden;
  gap: 24px;
  padding: 5px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.estimates-catalog::-webkit-scrollbar {
  display: none; /* for Chrome, Safari and Opera */
}

.estimates-catalog .estimate-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 136px;
  height: 120px;
  padding-inline: 2px;
  background: #ffffff;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}

.estimates-catalog p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.25px;
}

.estimates-catalog-heading {
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  /* identical to box height */

  letter-spacing: 1.5px;
  text-transform: uppercase;

  color: #070707;
}
@media screen and (max-width: 768px) {
  .estimates-catalog .estimate-container {
    width: 100px;
    height: 100px;
  }

  .estimates-catalog img {
    width: 56px;
    height: 56px;
  }
  .estimates-catalog p {
    font-size: 10px;
  }

  .estimates-catalog-heading {
    font-size: 15px;
    line-height: 22px;
  }
}
