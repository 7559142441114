.upload-section {
  display: flex;
  flex-direction: column;
  padding: 0 16px 24px;
  gap: 40px;
  margin-bottom: 40px;
}

.upload-section p {
  line-height: 1.5rem;
  margin-bottom: 0;
}
