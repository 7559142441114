button.button {
  border-radius: 100px;
  border: none;
  min-width: fit-content;
  max-width: 100%;
  white-space: nowrap;
  display: block;
  margin: auto;

  font-family: "Poppins";
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #ffffff;
}

button.button.submit-btn {
  font-weight: 700;
  width: 220px;
  padding: 16px 24px;
}

button.button.long-btn {
  font-weight: 500;
  width: 287px;
  padding: 8px 24px;
}

button.button.short-btn {
  font-weight: 500;
  width: 194px;
  padding: 8px 26px;
}

button.button.outline-btn-blue {
  border: 2px solid #4c6bbb;
  background: transparent;
  color: #080707;
  padding: 8px 24px;
}

button.button.outline-btn-black {
  padding: 8px 24px;
  color: #080707;
  border: 2px solid #080707;
  border-radius: 20px;
  background-color: transparent;
}

button.button.submit-btn.active {
  background: #333333;
}

button.button.submit-btn.disabled {
  background: #c6c6c6;
}

button.button.long-btn.filled.active,
button.button.short-btn.filled.active {
  background: #4c6bbb;
}

button.button.long-btn.outline.active {
  border: 1.5px solid #4c6bbb;
  background: #ffffff;
  color: #080707;
}

button.button.long-btn.disabled {
  background: #b0b6c6;
}

button.button.text-btn {
  background-color: transparent;
  color: black;
  font-weight: 500;
}

button.button.text-btn.disabled {
  background-color: transparent;
  color: #737272;
  font-weight: 500;
}

button.button.outline-btn.active {
  border: 1.5px solid #018077;
  background: #ffffff;
  color: #080707;
  padding: 8px 24px;
}

button.button.outline-btn.disabled {
  border: 1.5px solid #c6c6c6;
  background: #ffffff;
  color: #868686;
  padding: 8px 24px;
}

button.button.with-shadow {
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 33%);
}

button.button.open-search-btn {
  width: 100%;
  position: relative;
  padding: 12px 0px;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.6px;
  text-transform: none;
  background-color: white;
  color: black;
  font-weight: 500;
}

button.button.mobile-estimate-form-btn.active {
  background-color: #737272;
}

button.button.mobile-estimate-form-btn.disabled {
  background: #c6c6c6;
}

button.button.login-btn {
  background: #0f3544;
  width: 119px;
  height: 36px;
}

/* .button {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  margin: 0px auto;

  border: none;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.33);
  border-radius: 25px;
  background-color: #737272;
  
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.25px;
  line-height: 20px;
  color: white;
}

.buton.dark {
  background-color: #333333
}

.mobile-search {
  width: 100%;
  color: black;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.6px;
  background-color: white;
  position: relative;
  white-space: normal;
  padding: 12px 0px;
  flex-wrap: wrap;
}

.button.active {
  font-weight: 900;
  background-color: #333333;
}
 */
