.alphabet-filter {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.132px;
  margin-top: 48px;
  margin-bottom: 32px;
}

.alphabet-filter__item {
  cursor: pointer;
}

.alphabet-filter__item.active {
  color: #0DA789;
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .alphabet-filter { 
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.132px;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 12px;
  }
}
