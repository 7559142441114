.cost-estimator-tools {
  width: 100%;
  max-width: 896px;
  margin: 112px auto 134px;
}

.cost-estimator-tools img {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}
.cost-estimator-flex-section {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 17.5px;
}

.cost-estimator-flex-section > .flex-col {
  align-items: center;
  width: 287px;
  height: 196px;
}

.cost-estimator-tools p {
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .cost-estimator-tools {
    margin: 48px auto 180px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 16px;
    gap: 24px;
    width: 100%;
    max-width: 343px;
    background: rgba(219, 225, 241, 0.5);
    border-radius: 10px;
  }

  .cost-estimator-flex-section {
    flex-direction: column;
  }

  .cost-estimator-tools h5 {
    font-weight: 500;
    font-size: 24px !important;
    line-height: 27px !important;
  }

  .cost-estimator-flex-section > .flex-col {
    align-items: center;
    width: fit-content;
  }
}
