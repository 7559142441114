/* DESKTOP AND MOBILE STYLES */
input {
  font-size: 16px;
  color: black;
}

.landing-page-heading {
  margin-top: 40px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a9a9a9;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a9a9a9;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a9a9a9;
}

.service-offerings {
  position: relative;
  z-index: 1;
}

/* DESKTOP STYLES */
.landing-page-heading {
  margin-top: 40px;
}

.service-offerings-text {
  margin-bottom: 24px;
  margin-bottom: 40px;
}

/* MOBILE STYLE */
.mobile-service-search {
  position: relative;
  height: 90%;
}

.mobile-heading {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.17px;
  line-height: 39px;
  margin: 0px;
  font-weight: semi-bold;
  padding-bottom: 24px;
  padding-top: 24px;
}

.mobile-subheading {
  margin: 0px;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
}

.search-description {
  padding: 0px 40px 40px 40px;
}

.service-search-sticky {
  z-index: 997 !important;
  background-color: #080707;
  padding: 62px 30px 40px 30px;
  margin-bottom: 40px;
}

.mobile-landing-page-heading {
  font-weight: bold;
  background-color: #080707;
  color: white;
  padding: 70px 30px 0px 30px;
  margin-bottom: -1px;
  font-size: 48px;
  line-height: 53px;
}

/* ESTIMATES */
.estimates-main-container {
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.2),
      rgba(219, 225, 241, 0.2)
    ),
    #ffffff;
}

.estimates-content {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 32px;
}
.estimates-content p.pointer {
  margin-bottom: 32px;
}
.estimates-search-container {
  padding: 32px;
  max-width: 960px;
  width: 100%;
  background: #ffffff;
  border-radius: 20px;
  margin-bottom: 80px;
}
@media only screen and (max-width: 768px) {
  .landing-page-main {
    margin-top: 49px;
  }
  .service-offerings {
    padding: 0px;
  }

  .subheading {
    width: 100%;
    margin-top: 20px;
  }

  .service-search-sticky {
    z-index: 997 !important;
    background-color: #080707;
    padding: 62px 30px 40px 30px;
  }

  .mobile-landing-page-heading {
    font-weight: bold;
    background-color: #080707;
    color: white;
    padding: 70px 30px 0px 30px;
    margin-bottom: -1px;
  }

  /* ESTIMATES */
  .estimates-content {
    padding: 16px;
  }

  .estimates-search-container {
    background: transparent;
    padding: 0;
  }

  .estimates-search-container h5 {
    font-size: 24px !important;
    line-height: 27px !important;
  }
}
