span.icon {
  height: 19px;
  width: 19px;
  content: '';
  background-color: black;
  border-radius: 12px;
  margin-right: 10px;
}

.service-details {
  padding: 0px 40px 40px 40px;
}

.service-name {
  padding-top: 16px;
  padding-bottom: 40px;
}

.service-title {
  padding-top: 40px;
  padding-bottom: 6px;
}
