.contact-form {
  margin-top: 16px;
}

.contact-page {
  margin-top: 48px;
}

.contact-input-label {
  position: absolute;
  font-weight: bold;
  z-index: 66;
  left: 23px;
  top: 5px;
}

.contact-input.form-control {
  background-color: white;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 27px;
  min-height: 51px;
  padding: 15px 22px 5px 22px;
  border-radius: 24px;
  margin-top: 24px;
}

.contact-input.form-control:focus {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  border: none;
}

button.button.contact-btn {
  margin-bottom: 40px;
  margin-top: 12px;
}

.captcha-text {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

#thank-you-container {
  display: none;
  width: 100%;
  text-align: center;
  margin: 8px 0px 40px;
}

.thank-you-text {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.17px;
  line-height: 39px;
  margin: 0px;
  font-weight: semi-bold;
  padding-bottom: 24px;
}

.contact-heading {
  margin-top: 40px;
}

@media only screen and (max-width: 768px) {
  .contact-heading {
    font-size: 48px !important;
    line-height: 53px !important;
  }

  .contact-page {
    margin: 70px 0px 120px 0px;
  }

  .contact-section__contact-form {
    padding-inline: 16px;
  }
}
