.service-icon {
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
  content: "";
  background-color: black;
  margin-right: 20px;
}

li.list-item {
  display: flex;
  color: black;
}

.service-list {
  margin: 0px;
  padding: 0px;
}

.vertical-list-information {
  min-width: 100px;
  word-wrap: break-word;
  white-space: normal;
}

.square-icon {
  height: 100px;
  width: 100px;
  margin-right: 5px;
  border-radius: 5px;
  content: "";
  background-color: #0da789;
}

.wrap {
  word-wrap: break-word;
  white-space: normal;
}

.service-group-icon {
  color: #080707;
  font-size: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 22px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  gap: 10px;
  background: #dbe1f1;
  border-radius: 20px;
  margin-bottom: 0;
  width: max-content;
}

.placeholder {
  margin-left: 112px;
}

.facility-name {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #080707;
}

.facility-user-cost {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  text-align: start;
  letter-spacing: 0.132px;
  color: #080707;
}

.width-204 {
  width: 204px;
  max-width: 100%;
}

.your-estimate-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.25px;
  color: #080707;
}
@media only screen and (max-width: 768px) {
  .service-icon {
    display: none;
  }

  .service-information {
    margin-left: 0px;
  }
}
