.go-handl-health {
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.2),
      rgba(219, 225, 241, 0.2)
    ),
    #ffffff;
  padding: 80px 0;
}
.go-handl-health-card-container {
  max-width: 912px;
  width: 100%;
  margin: auto;
  position: relative;
}
.go-handl-health-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 32px;
  width: 288px;
  background: #ffffff;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin: auto;
}
/* SWIPER STYLES */
.swiper {
  padding-bottom: 55px !important;
}

.swiper-pagination-bullet {
  background: rgba(165, 160, 155, 0.5) !important;
  opacity: 1;
  width: 10.67px !important;
  height: 10.67px !important;
  margin: 0 6.5px 0 !important;
}

.swiper-pagination-bullet-active {
  background-color: #a5a09b !important;
}

.swiper-button-disabled {
  display: none !important;
}

.custom-ghh-swiper-button-prev {
  left: -32px;
  bottom: 50%;
  position: absolute;
  cursor: pointer;
}

.custom-ghh-swiper-button-next {
  right: -32px;
  bottom: 50%;
  position: absolute;
  cursor: pointer;
}

.go-handl-title {
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  letter-spacing: 0.132px;
  color: #080707;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .go-handl-health {
    padding: 52px 0 134px;
  }
  .swiper-button-disabled {
    display: block !important;
  }

  .custom-ghh-swiper-button-next,
  .custom-ghh-swiper-button-prev {
    bottom: 13px;
    position: absolute;
    z-index: 22;
    width: 10px;
  }

  .custom-ghh-swiper-button-prev {
    left: 33%;
  }

  .custom-ghh-swiper-button-next {
    right: 33%;
  }

  .go-handl-title {
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.5px;
    margin-bottom: 16px;
  }
}
