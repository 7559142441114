.sort-modal {
}
.sort-modal .modal-header {
  padding: 0 !important;
}
.mobile-sort-container {
}

.mobile-sort-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  padding-left: 16px;
  margin-bottom: 27px;
}

.mobile-sort-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.132px;
  color: #363636;
}

.mobile-sort-options {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-between;
  padding-inline: 16px;
  margin-bottom: 37px;
}

.mobile-sort-container .sort-btn {
  margin-bottom: 40px;
}

.mobile-sort-radio:checked {
  accent-color: #000000;
}
.mobile-sort-options input[type="radio"] {
  transform: scale(1.7);
}
@media screen and (max-width: 768px) {
  .sort-modal {
    max-width: 100% !important;
    position: absolute !important;
    margin-left: -24px !important;
    bottom: 0;
    width: 100vw !important;
    min-height: fit-content !important;
    margin-bottom: 0 !important;
  }

  .mobile-sort-container {
    display: flex;
    border: 0;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 20px 20px 0px 0px;

    position: absolute;
    bottom: 0;
    width: 100vw;
    max-width: 100%;
    margin-left: -16px;

    backdrop-filter: opacity(20%);
  }
  .mobile-sort-container > div {
    align-items: center;
  }
  .mobile-sort-title {
    padding-top: 22px;
  }
}
