.billing-onboarding .progress,
.lets-handl-this .progress {
  height: 8px;
}

.billing-onboarding .pointer {
  margin: 72px auto 40px;
}
.billing-onboarding__container
  h5:first-of-type:not(.hipaa-form__text-container-heading) {
  width: 670px;
  max-width: 100%;
  margin: auto;
}

.billing-onboarding__container .onboarding-steps__next-btn {
  margin-top: 80px;
  margin-bottom: 40px;
}

.billing-onboarding__container .onboarding-steps-skip-btn {
  margin-bottom: 24px;
}

.hipaa-form__section .onboarding-steps__next-btn {
  margin-top: 24px;
}

.hipaa-form__section .hipaa-form__submit-btn {
  margin-bottom: 228px;
}

.billing-onboarding__container .input-container,
.hipaa-form .input-container {
  width: 670px;
  max-width: 100%;
  margin: auto;
  margin-top: 24px;
}

.billing-onboarding .sign-up-form__consent-input {
  margin: 54px auto auto;
}

.billing-onboarding__container .dates-of-service-text {
  position: relative;
  text-decoration-line: underline;
  cursor: pointer;
  margin: 8px auto 24px;
  width: 670px !important;
  max-width: 100%;
}

/* ACCOUNT CREATION */
.billing-onboarding__account-creation {
  margin-top: 0px;
}
.billing-onboarding__account-creation .input-container {
  width: 575px;
  max-width: 100%;
  margin: auto;
  margin-top: 32px;
}
.billing-onboarding__container p:first-of-type,
.billing-onboarding__account-creation .sign-in__subtitle,
.account-creation__access-code-text-container,
.billing-onboarding__container
  .billing-onboarding__account-creation
  h5:first-of-type {
  width: 704px;
  max-width: 100%;
}

.billing-onboarding__account-creation .sign-in__subtitle {
  margin: 8px auto 0;
}

.account-creation__action-buttons {
  margin-top: 65px;
}

.account-creation__action-buttons button:last-of-type {
  margin-bottom: 80px;
}

.account-creation-helper-description {
  width: 704px;
  max-width: 100%;
  margin: 24px auto 40px;
}
/* HIPAA CONSENT */
.hipaa-form {
  counter-reset: step;
}

.hipaa-form__section {
  width: 575px;
  max-width: 100%;
  margin: auto;
}

.hipaa-form__heading {
  margin: 40px auto 45px;
}

.hipaa-form__heading:nth-last-of-type() {
  margin: 42.5px auto 42.5px;
}
.hipaa-form__heading::before {
  content: counter(step);
  counter-increment: step;
  display: inline-block;
  width: 32px;
  height: 32px;
  background-color: #dbe1e3;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #0f3544;
  padding-top: 3px;
  border-radius: 50%;
  margin-right: 1rem;
}

.view-pdf-link {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.5px;
  text-decoration-line: underline;
  color: #000000;
}

.hipaa-form__pdf-container {
  width: 575px;
  background: #dbe1e3;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  padding-top: 22px;
  padding-bottom: 22px;
  gap: 14px;
  margin-top: 42.5px;
  margin-bottom: 24px;
}

.hipaa-form__sign-form-helper-text {
  margin-bottom: 40px;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #080707;
}
.hipaa-form__canvas-container {
  width: 575px;
  height: 112px;
  max-width: 100%;
  margin: auto auto 48px;
}

.sigCanvas {
  border: 1px solid #000000;
  overflow: hidden !important;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  position: relative;
}
.hipaa-form__add-signature-text {
  position: absolute;
  width: 575px;
  max-width: 100%;
  top: 35%;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #080707;
}

.hipaa-form__text-container {
  width: 704px;
  max-width: 100%;
  margin: auto;
}

.hipaa-form__text-container ul * {
  margin-bottom: 8px;
}
.hipaa-form__text-container li:not(:first-child) {
  list-style-type: disc;
  margin-left: 26px;
}

.width-904 {
  width: 904px;
  max-width: 100%;
}

.hipaa-form .mobile-search__close {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.hipaa-form .hipaa-form__return-to-bill {
  margin-top: 40px;
  margin-bottom: 16px;
}

.hipaa-form .hipaa-form__back {
  margin-top: 72px;
  margin-bottom: 24px;
}

.bill-tracker-update__ul {
  margin-left: 2rem;
  margin-top: 2rem;
  margin-bottom: 58.5px;
}

/* BILL REVIEW ONBOARDING */
.lets-handl-this {
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.2),
      rgba(219, 225, 241, 0.2)
    ),
    #ffffff;
}
.lets-handl-this-main-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  gap: 32px;
  width: 100%;
  max-width: 960px;
  background: #ffffff;
  border-radius: 20px;
  margin: 40px auto 56px;
}

.lets-handl-this-heading {
  width: 100%;
  max-width: 896px;
  margin-right: auto;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 0.353796px;
}

.lets-handl-this ul {
  list-style-type: disc;
  margin-left: 1.5rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}

.lets-handl-this li {
  margin-bottom: 12px;
}
.billing-onboarding__container
  .lets-handl-this
  .lets-handl-this-additional-text {
  max-width: 750px;
  width: 100%;
}

.lets-handl-this__desktop-only-sxn {
  align-items: center;
  justify-content: center;
  gap: 42px;
  text-align: center;
}

.lets-handl-this__desktop-only-sxn > div {
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.lets-handl-this .lets-handl-this__desktop-only-sxn p {
  width: 100%;
  max-width: 233px;
}

@media screen and (max-width: 768px) {
  .billing-onboarding {
    background: linear-gradient(
        0deg,
        rgba(242, 241, 239, 0.8),
        rgba(242, 241, 239, 0.8)
      ),
      #ffffff;
  }
  .billing-onboarding .progress,
  .lets-handl-this .progress {
    height: 6px;
    margin-top: 50px;
    margin-bottom: 40px;
  }

  .billing-onboarding__container {
    position: relative;
  }
  .billing-onboarding__container
    h5:first-of-type:not(
      .hipaa-form__text-container-heading,
      .account-creation-header
    ) {
    width: 343px;
    max-width: 100%;
    letter-spacing: 0.353796px;
  }
  .billing-onboarding__container .onboarding-steps__next-btn {
    margin-bottom: 24px;
  }

  .billing-onboarding__container .input-container {
    margin-top: 48px;
  }
  .hipaa-form .input-container {
    margin-top: 40px;
  }
  .billing-onboarding .sign-in-form__remember-me {
    margin-top: 50px;
  }

  .billing-onboarding__container .pointer {
    margin: 40px auto 24px;
  }
  .billing-onboarding .sign-in__privacy-link {
    margin-top: 35%;
  }
  .billing-onboarding .account-creation__action-buttons .sign-in__privacy-link {
    margin-top: 40px;
  }
  .billing-onboarding .access-code-helper-container {
    margin-top: 40px;
  }
  .hipaa-form__pdf-container {
    width: 343px;
    max-width: 100%;
    margin: auto;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    padding-top: 16px;
    padding-bottom: 16px;
    grid-gap: 14px;
    gap: 14px;
    margin-top: 42.5px;
    margin-bottom: 24px;
  }
  .hipaa-form__canvas-container {
    width: 343px;
  }

  .hipaa-form__section .hipaa-form__submit-btn {
    margin-bottom: 40px;
  }

  .sigCanvas {
    background: #f6f6f6;
  }
  .hipaa-form__add-signature-text {
    position: absolute;
    top: 35%;
  }

  .hipaa-form__heading {
    margin: 24px auto 45px;
  }

  .hipaa-form__heading:nth-last-of-type() {
    margin: 26.5px auto 42.5px;
  }

  .billing-onboarding__container,
  .hipaa-form__section:not(
      .billing-onboarding__container .hipaa-form__section
    ) {
    padding-inline: 16px;
  }

  input[type="date"]::before {
    content: attr(placeholder);
    position: absolute;
    color: #080707;
  }

  input[type="date"] {
    color: #ffffff;
  }

  input[type="date"]:focus,
  input[type="date"]:valid {
    color: #080707;
  }

  input[type="date"]:focus::before,
  input[type="date"]:valid::before {
    content: "";
  }

  .billing-onboarding__account-creation {
    margin-top: -40px;
  }

  .lets-handl-this {
    background: #fff;
  }
  .lets-handl-this-main-section {
    padding: 0;
    margin: 0px auto 56px;
  }

  .lets-handl-this-heading {
    font-weight: 200;
  }
  .billing-onboarding__container .lets-handl-this .body-2 {
    max-width: 343px;
    width: 100%;
  }

  .lets-handl-this__mobile-only-img {
    position: absolute;
    bottom: 0px;
    width: 100%;
    margin-left: -16px;
  }
  .account-creation-signin-faq {
    margin: 0px -16px;
  }
  .billing-onboarding__container p:first-of-type {
    width: auto;
  }
}
