.dashboard-top-container {
  margin: 40px 0 80px;
}
.dashboard-top-container h1 {
  font-family: "Poppins";
  font-style: normal;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: 0.132px;
}

@media screen and (max-width: 768px) {
  .dashboard-top-container {
    margin: 40px 0 64px;
  }

  .dashboard-top-container h1 {
    font-size: 32px;
    line-height: 48px;
  }

  .dashboard-top-container h5 {
    font-size: 24px !important;
    line-height: 27px !important;
  }
}
