.facilities-details {
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.2),
      rgba(219, 225, 241, 0.2)
    ),
    #ffffff;
}

.facilities-details-container {
  width: 896px;
  max-width: 100%;
  margin: 0 auto;
}

.pagination__link {
  color: #838383;
}

.pagination__link--active,
.previous,
.next {
  color: black;
}

li::after {
  content: "·";
  color: black;
}

li:last-child::after,
li:nth-last-child(2)::after,
li:first-child::after {
  content: "";
  color: black;
}

.desktop-location-modal-body {
  max-width: 420px;
  margin: auto;
}

.facilities-list {
  margin-top: 52px;
}

.facilities-list a,
.facilities-list a:hover {
  text-decoration: none;
  color: #080707;
}
.facilities-list-title {
  padding: 4px 40px 17px 40px;
}

.facilities-list,
.show-more-facilities-btn {
  margin-bottom: 40px;
}

.facilities-list-facility {
  padding: 19.5px 40px 16px 32px;
  border-bottom: 0.5px solid #d8d8d8;
}

.location-zipcode-icon {
  top: 36px;
}

.location-arrow-icon {
  top: 40px;
}

button.button.update-location-btn {
  margin-bottom: 24px;
}

.facilities-details h2 {
  font-weight: 600;
  margin: 0px;
}

/* MODAL */
.modal-content {
  border-radius: 16px !important;
}
.update-zipcode-modal {
  max-width: 700px !important;
}
.update-zipcode-modal .modal-body {
  padding: 0 !important;
}
.update-zipcode-modal .modal-content {
  border-radius: 16px !important;
  width: 700px;
  max-width: 100%;
  margin: auto;
  padding-bottom: 40px;
  padding-top: 24px;
}

.update-zipcode-modal .close-modal {
  right: 38px;
  top: 30px;
}
.location-modal-text {
  margin-top: 40px;
  margin-bottom: 0px;
}

.location-modal-description {
  margin-top: 26px;
  margin-bottom: 30px;
}

.update-zipcode-modal__body {
  width: 652px;
  max-width: 100%;
  margin: auto;
}

.update-zipcode-modal__input {
  max-width: 512px;
  width: 100%;
  margin: auto auto 32px;
}

.facilities-details .filter-dropdown {
  width: 266px;
}

.facilities-disclaimer {
  text-align: center;
  padding: 0px 32px 80px 32px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #000000;
}

.facilities-details p.pointer {
  padding: 40px 0 32px;
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .facilities-details-container > :nth-child(-n + 2) {
    padding-inline: 16px;
  }
  .facilities-list-facility {
    padding: 16px;
  }
  .facilities-list {
    margin-top: 32px;
  }
  .facilities-list-mobile-title {
    font-family: "Crimson Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 127.13%;
    letter-spacing: 0.25px;
    color: #363636;
    padding-bottom: 24px;
  }
  .service-group-icon {
    font-size: 10px;
    line-height: 15px;
    padding: 4px 8px;
    width: max-content;
  }

  .update-zipcode-modal {
    width: 343px !important;
    max-width: 100%;
    margin: auto !important;
  }

  .update-zipcode-modal .modal-body {
    padding: 0 24px !important;
    margin: 0 !important;
  }

  .update-zipcode-modal__body h3 {
    font-size: 24px !important;
    line-height: 27px !important;
  }
  .facilities-details p.pointer {
    padding: 84px 0 32px;
  }
}
