.back {
  display: none;
  align-items: center;
  background-color: #f8f9fa;
}

@media only screen and (max-width: 768px) {

  .back {
    display: flex;
  }

}
