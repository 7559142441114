.what-to-expect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 40px;
  gap: 24px;
  width: 935px;
  max-width: 100%;
  margin: auto;
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.5),
      rgba(219, 225, 241, 0.5)
    ),
    #ffffff;
}
.what-to-expect__container {
  display: flex;
  gap: 40px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .what-to-expect {
    align-items: flex-start;
    padding: 24px 16px;
    background: #ffffff;
  }
  .what-to-expect__container {
    flex-direction: column;
    width: 100%;
    justify-content: center;
  }
}
