/* .about-us--1,
.about-us--2,
.about-us--3 {
  padding: 100px 0px;
} */

.about-us--1 {
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 1024px;
  gap: 86px;
  padding: 60px 32px 64px;
}

.about-us--2 {
  text-align: center;
  background-color: #4c6bbb;
  padding: 40.5px 0;
}

.about-us--2 p {
  color: #dbe1e3;
}

.about-us--2 section {
  max-width: 600px;
  margin: auto;
}

.about-us--2 section .about-us-heading,
.about-us--2 section .text-large {
  letter-spacing: 1px;
}

.about-us--3 section {
  max-width: 600px;
  padding: 60px 0;
  text-align: center;
  margin: auto;
}

.about-us-heading {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.132px;
  color: #4c6bbb;
}

.about-us--3 .about-us-heading {
  font-weight: 500;
  color: black;
  padding-bottom: 60px;
}

.about-us-text {
  max-width: 543px;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .about-us--1,
  .about-us--3 {
    padding: 32px 16px;
  }

  .about-us--1 {
    padding-top: 80px;
    flex-direction: column;
    max-width: 100%;
    gap: 0;
  }

  .about-us--2 .about-us-heading,
  .about-us--3 .about-us-heading {
    font-size: 24px;
    line-height: 36px;
  }

  .about-us--2 .about-us-heading {
    padding-inline: 11px;
  }
  .about-us-heading-container,
  .about-us-text {
    width: 100%;
  }

  .about-us--2 .body-1 {
    font-size: 15px;
    line-height: 24px;
    padding-inline: 32px;
  }
  .about-us--3 section {
    padding: 0px 0px 60px;
  }
}
