
.what-we-do-content {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 40px;
}

.what-we-do-section {
  padding: 0px 15px;
}

.what-we-do-heading {
  font-size: 48px;
  line-height: 53px;
  font-family: 'Crimson Pro', 'Times New Roman', Times, serif;
  margin: 0px 0px 40px 0px;
  letter-spacing: normal;
  font-weight: 700;
}

.what-we-do-section-heading {
  margin-bottom: 40px;
}

.what-we-do {
  padding: 100px 0px;
}

@media only screen and (max-width: 768px) {

  .what-we-do-heading {
    font-size: 28px;
    font-family: 'Poppins';
    font-weight: 500;
    letter-spacing: 0.17px;
    line-height: 39px;
  }

  .what-we-do-content {
    flex-direction: column;
    text-align: left;
  }

  .what-we-do {
    padding: 100px 40px 0px 40px;
  }

  .what-we-do-section-heading {
    margin-bottom: 24px;
  }

  .what-we-do-section {
    padding: 0px;
    margin-bottom: 8px;
  }
}
