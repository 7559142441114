.article-card {
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.2),
      rgba(219, 225, 241, 0.2)
    ),
    #ffffff;
  padding-bottom: 162px;
}

.article-card-content {
  max-width: 960px;
  width: 100%;
  margin: auto;
  position: relative;
}

.article-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 16px 19px;
  gap: 16px;
  background: #ffffff;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  width: 100%;
}

.article-card-container a,
.article-card-container a:hover {
  text-decoration: none;
  color: #080707;
}
.article-card-container__img {
  height: 174px;
  width: 555px;
  max-width: 100%;
  object-fit: cover;
  gap: 8px;
  border-radius: 10px;
  margin-bottom: 16px;
}

.article-card-container_author-section {
  display: flex;
  align-items: center;
  gap: 12px;
}

.article-card .slider-control-bottomcenter li {
  margin-inline: 6.67px;
}

.article-card .slider-control-bottomcenter ul {
  top: 0;
}

.article-card .article-card__see-all-btn {
  margin-top: 24px;
}

.custom-ac-swiper-button-prev {
  left: -32px;
  bottom: 50%;
  position: absolute;
  cursor: pointer;
}

.custom-ac-swiper-button-next {
  right: -32px;
  bottom: 50%;
  position: absolute;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .article-card {
    padding-inline: 16px;
    padding-bottom: 0;
  }

  .article-card__text-container {
    padding-inline: 16px;
  }
  .article-card .swiper {
    padding-bottom: 65px !important;
  }

  .custom-ac-swiper-button-next,
  .custom-ac-swiper-button-prev {
    width: 10px;
    z-index: 22;
    bottom: 9.75%;
  }
  .custom-ac-swiper-button-prev {
    left: 34%;
  }

  .custom-ac-swiper-button-next {
    right: 34%;
  }
}
