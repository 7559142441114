.submission-details {
  width: 575px;
  max-width: 100%;
  margin: 64px auto 72px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
}

.submission-details a {
  color: inherit;
}
@media screen and (max-width: 768px) {
  .submission-details {
    padding: 24px 16px;
    margin: 0 auto 32px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  }
}
