.estimate-form__desktop-container {
  margin-bottom: 200px;
}

.estimate-form__step-description {
  margin-bottom: 40px;
}
p.estimate-form__step-description {
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.132px;
  margin-bottom: 40px;
  font-weight: 500;
}

.estimate-form__desktop-container {
  margin-bottom: 0;
}

p.estimate-form__step-description {
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.132px;
  margin-bottom: 40px;
  font-weight: 500;
}

.service-quote-form {
  color: black;
  background-color: #f8f9fa;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  overflow: auto;
}

.cancel-icon {
  position: absolute;
  margin-top: -32px;
  right: 10px;
  z-index: 999;
}

.description-text {
  margin-top: 40px;
  margin-bottom: 40px;
}

.desktop-quote-form {
  margin-bottom: 200px;
}

/* DESKTOP AND MOBILE */
.form-control.is-invalid,
.was-validated .form-control:invalid {
  box-shadow: 0 0 6px 0 #dc3545;
  background-size: 22px 22px !important;
  z-index: 1;
  background-position: right 22px center !important;
}

.form-control.is-valid,
.form-control:valid:focus,
.form-control:valid:active,
.was-validated .form-control:valid {
  box-shadow: none !important;
  background-image: none !important;
  border: none !important;
  border-bottom: 1px solid #080707 !important;
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  box-shadow: none;
  border: none;
  border-bottom: 1px solid #080707;
}

.invalid-feedback {
  padding-left: 22px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a9a9a9;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a9a9a9;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a9a9a9;
}

#thank-you-container {
  display: none;
  width: 100%;
  text-align: center;
  margin: 40px 0px;
}

#thank-you-container > p.text-large {
  font-weight: 600;
}

#not-available-estimates-catalog {
  margin: 95px 0 200px;
  display: none;
}
.thank-you-text {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.17px;
  line-height: 39px;
  margin: 0px;
  font-weight: semi-bold;
  padding-bottom: 24px;
}

/* DESKTOP */
.quote-form-label {
  position: absolute;
  font-weight: bold;
  z-index: 999;
  top: -10px !important;
  font-family: "Poppins";
  font-style: normal;
}

.landing-page-input.form-control {
  background-color: white;
  height: 56px;
  padding: 15px 22px 0px 22px !important;
  margin: 0px 0px;
  width: 100%;
  border-bottom: 1px solid #080707;
  position: relative;
}

.form-input-icon {
  position: absolute;
  top: 32px;
  right: 18px;
}

.estimate-form__desktop-container .input-and-label {
  padding-top: 0;
}

.estimate-form__desktop-container .input-and-label label {
  top: -20px !important;
}
.landing-page-input.form-control:focus + label,
.landing-page-input.form-control:valid + label {
  display: block;
}
.landing-page-input.form-control:focus,
.not-available-input.form-control:focus {
  box-shadow: none;
  border-bottom: 1px solid #080707;
}

.quote-option-section {
  padding: 0px 40px 40px 40px;
}

.section-name {
  padding-top: 16px;
  padding-bottom: 40px;
}

/* MOBILE */
.quote-option-input.form-control {
  color: black;
  background-color: #d8d8d8;
  border-bottom: 1px solid black;

  width: 100%;
  padding: 0.75rem 0.75rem 0.75rem 3rem;

  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 27px;
}

.quote-option-input.form-control:focus {
  box-shadow: none !important;
  border-bottom: 1px solid black;
}

.zipcode-icon {
  position: absolute;
  top: 73px;
  left: 15px;
  z-index: 999;
}

.right-arrow-input {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  height: 3px;
  width: 3px;
  position: absolute;
  top: 77px;
  right: 15px;
  z-index: 999;
}

.revealed-input {
}

.next-btn.button {
  margin-left: 20px;
  font-size: 13px;
  padding: 16px 36px;
  letter-spacing: 1.25px;
  line-height: 20px;
}

.error-message {
  border: 1px solid #dc3545;
  border-radius: 26px;
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 27px;
  padding: 19px 22px;
}

.error-message p {
  color: #dc3545 !important;
  margin-bottom: 0px;
}

.error-message p.bold {
  margin-bottom: 12px;
}

.start-over {
  font-size: 13px;
  letter-spacing: 1.25px;
  line-height: 20px;
  margin-top: 24px;
}

.i-dont-know {
  font-family: "Poppins";
  margin-top: 12px;
  margin-left: 24px;
}

.idk-d {
  width: 65%;
}

.idk-o {
  width: 70%;
}

@media only screen and (max-width: 768px) {
  .not-available {
    margin: 110px 24px 0px 24px;
    width: auto;
  }

  .not-available-input.form-control {
    width: 100%;
  }

  .start-over {
    display: none;
  }

  .estimate-form__desktop-container > form,
  .estimate-form__desktop-container > form > .d-flex,
  .estimate-form__desktop-container form.d-flex,
  .estimate-form__desktop-container form.d-flex > .d-flex {
    flex-direction: column;
  }

  .landing-page-input.form-control,
  .landing-page-input.form-control:focus {
    background-color: transparent;
  }

  #thank-you-container > p.text-large {
    font-weight: 400;
  }
}
