.no-suggestions {
  color: white;
  padding: 0.5rem;
}

.suggestions {
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  position: absolute;
  top: unset;
  right: 0;
  left: 0;
  overflow: auto;
  max-height: 256px;
  z-index: 1;
  cursor: pointer;
  margin-top: -32px;
}

.suggestions::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.navigation-suggestions {
  position: absolute !important;
}

.mobile-suggestions {
  position: relative;
  left: 0;
  right: 0;
}

.suggestions li {
  padding: 15px;
  font-size: 12px;
}

.suggestion-active {
  background-color: #e9ecef !important;
}

.suggestions li:hover {
  background-color: rgb(0 0 0 / 2.5%);
}

.insurance-plan-suggestions {
  width: 116%;
}

.select-arrow-down {
  right: 22px;
  margin-top: -22px;
}

@media only screen and (max-width: 768px) {
  .suggestions {
    width: 100%;
  }

  .suggestions li {
    font-size: 15px;
    line-height: 23px;
  }

  .suggestion-active,
  .suggestions li:hover {
    background-color: white;
    cursor: pointer;
  }
}
