.service-offerings .tab-list {  
  margin-bottom: 0px;
  padding: 40px 0px 0px 0px;
  font-size: 25px;
  line-height: 38px;
  letter-spacing: 0.15px;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none; 
}

.service-offerings .tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 43px;
  border-bottom: 1px solid #979797;
}

.service-offerings .tab-list-active {
  border-bottom: solid black;
  border-width: 3px;
  font-weight: 900;
}

.service-offerings .tab-list::-webkit-scrollbar {
  display: none;
}

.service-offerings .tabs {
  overflow: visible;
}

@media only screen and (max-width: 768px) {

  .service-offerings .tab-list { 
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.11px;
    line-height: 27px;
    padding-left: 42px;
  }

  .service-offerings .tab-list-item {
    padding: 0.5rem 22px;
  }

}
