.submit-bill__text-area {
  width: 575px;
  height: 142px;
  padding: 16px 12px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  margin-bottom: 60px;
}
.submit-bill__text-area:focus {
  border: 1px solid #d9d9d9;
  outline: none;
}

/* MODAL */
.submit-bill-modal .modal-content {
  width: 800px !important;
  background: #ffffff;
  border-radius: 20px !important;
  padding: 48px 32px 32px;
}
.submit-bill-modal .modal-content .modal-header {
  padding: 0 !important;
}

.submit-bill-modal .modal-body {
  padding: 0 !important;
}

.submit-bill-modal__action-buttons {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
@media screen and (max-width: 768px) {
  .submit-bill__text-area {
    width: 343px;
    height: 142px;
    margin-bottom: 40px;
  }
  .submit-bill-modal {
    margin: 0 !important;
  }
  .submit-bill-modal .modal-content {
    width: 343px !important;
  }
  .submit-bill-modal .sign-up__modal-text {
    width: 279px;
    max-width: 100%;
  }
}
