.content-page {
  font-family: Poppins;
}

.content-page__width {
  max-width: 896px;
  margin: auto;
}

.content-page__heading {
  padding: 124px 64px 64px 64px;
  margin-bottom: 24px;
}

.content-page__name {
  font-weight: bold;
  font-size: 58px;
  line-height: 64px;
  margin-bottom: 24px;  
}

.content-page__description {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
  margin-bottom: 0px;
}

@media only screen and (max-width: 768px) {

  .content-page__heading {
    padding: 148px 16px 48px 16px;
    margin-bottom: 16px;
  }

  .content-page__name {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 16px;  
  }
  
  .content-page__description {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.5px;
  }
}
