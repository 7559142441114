.facility-page__section {
  padding: 96px 64px;
}

.facility-page__max-width {
  max-width: 960px;
  margin: auto;
}

.facility-page__verified-text img {
  margin-left: 16px;
  margin-bottom: 2px;
}

.facility-page__facility-information-section {
  width: 100%;
}

.facility-page__cost-comparison-section {
  background-color: #ECEAE8;
}

.facility-page__estimate-section {
  background: #E2D3CA;
}

.facility-page__verified-text {
  font-family: 'Crimson Pro';
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
}

.facility-page__verified-text.verified {
  color: #4C6BBB
}

.facility-page__facility-name {
  font-weight: 700;
  font-size: 64px;
  line-height: 72px;
  letter-spacing: 0.6px;
  margin-bottom: 32px;
}

.facility-page__facility-information {
  display: flex;
  width: 100%;
}

.facility-page__facility-details {
  width: 75%;
}

.facility-page__facility-map {
  width: 100%;
  padding-bottom: 32px;
}

.facility-page__facility-details-item {
  margin-bottom: 32px;
}

.facility-page__facility-detail-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.facility-page__facility-detail-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.132px;
  margin: 0px;
}

.facility-page__facility-page-title {
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;
  letter-spacing: 0.6px;
  margin-bottom: 8px;
}

.facility-page__cost-comparison-description {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.132px;
  margin-bottom: 32px;
}

.facility-page__cost-comparison-graph-section {
  background: linear-gradient(0deg, rgba(183, 196, 228, 0.3) -4.6%, rgba(183, 196, 228, 0) 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
}

.facility-page__cost-comparison-graph {
  height: 207px;
  width: fit-content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  align-items: flex-end;
  padding: 0px 32px;
  border-bottom: 1px solid #979797;
  position: relative;
}

.facility-page__cost-bar {
  content: '';
  width: 160px;
  border-radius: 8px 8px 0px 0px;
}

.facility-page__nearby-facility-bar {
  background: #4C6BBB;
}

.facility-page__current-facility-bar {
  z-index: 1;
}

.facility-page__nearby-facility-bar::before {
  content: '';
  background-image: linear-gradient(to right, black 0%, black 50%, transparent 50%);
  background-size: 12px 1px;
  background-repeat: repeat-x;
  height: 1px;
  width: 100%;
  display: block;
  position: absolute;
  left: 0;
  z-index: 0;
}

.facility-page__cost-comparison-graph-labels {
  display: flex;
  justify-content: space-evenly;
  margin-top: 8px;
}

.facility-page__cost-bar-label {
  width: 161px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.132px;
}

.facility-page__cost-comparison-text {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.132px;
  margin-bottom: 0px;
  max-width: 40%;
}

.facility-page__all-services-heading {
  display: flex;
  justify-content: space-between;
}

.facility-page__service-search {
  display: flex;
  width: 60%;
  height: fit-content;
}

.facility-page__search-icon-container {
  border-bottom: 1px solid #636363;
  padding-top: 30px;
}

.facility-page__service-search-input.form-control {
  background-color: transparent;
  border-bottom: 1px solid #636363;
  width: 100%;
  border-radius: 0;
}

.facility-page__service-search-input.form-control:focus {
  box-shadow: none;
  border-color: #636363;
  background-color: transparent;
}

.facility-page__service-search-form-group.form-group {
  margin: 0;
  height: fit-content;
  width: 100%;
}

.facility-page__service-search-form-group.form-group .form-text {
  display: none;
}

.facility-page__handl-category.card {
  background-color: transparent;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.132px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #B7C4E4 !important;
  padding: 32px 0px;
}

.facility-page__handl-category-toggle {
  display: flex;
  justify-content: space-between;
}

.facility-page__handl-category-alpha {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.132px;
  margin-top: 32px;
  margin-bottom: 32px;
}

.facility-page__handl-category-alpha-item {
  cursor: pointer;
}

.facility-page__handl-category-alpha-item.active {
  color: #0DA789;
  text-decoration: underline;
}

.facility-page__handl-category-alpha-item.empty {
  color: #A5A09B;
  cursor: auto;
}

.facility-page__handl-category-content.card-body {
  padding: 0;
}

.facility-page__services-count {
  text-align: center;
  margin-top: 48px;
}

.facility-page__services-count-text {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.facility-page__see-more-services {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #080707;
  cursor: pointer;
}

.facility-page__services-count-bar {
  content: '';
  max-width: 360px;
  width: 100%;
  height: 4px;
  background: #C4C4C4;
  border-radius: 4px;
  margin: 0px auto 48px auto;
}

.facility-page__services-count-bar-filled {
  content: '';
  height: 4px;
  background: #080707;
  border-radius: 4px;
  position: relative;
}

.facility-page__services-count-bar-filled::after {
  content: '';
  display: block;
  width: 2px;
  height: 8px;
  background: #080707;
  position: absolute;
  right: 0;
  bottom: 0;
}

@media only screen and (max-width: 768px) {

  .facility-page__section {
    padding: 0;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
    
  }

  .facility-page__section:not(.facility-page__estimate-section) {
    background: #FFFFFF;
    margin-bottom: 32px;
  }
  
  .facility-page__max-width {
    max-width: 100%;
  }

  .facility-page__facility-information-section {
    padding-top: 150px;
  }

  .facility-page__facility-information {
    display: block;
    padding-bottom: 16px;
  }

  .facility-page__verified-text {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.15px;
    padding: 0px 16px;
  }

  .facility-page__facility-name {
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    padding: 0px 16px;
  }

  .facility-page__facility-details {
    
    width: 100%;
  }

  .facility-page__facility-map {
    width: 100%;
    height: 240px;
  }

  .facility-page__facility-page-title {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.15px;
  }

  .facility-page__facility-details-item {
    margin: 0;
    padding: 16px 16px;
  }

  .facility-page__facility-details-item.contact,
  .facility-page__facility-details-item.address {
    display: grid;
    grid-template-columns: 50px 1fr;
  }

  .facility-page__facility-details-item .icon {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .facility-page__facility-details-item.contact {
    border-bottom: 1px solid rgba(151, 151, 151, 0.4);
  }

  .facility-page__cost-comparison-section {
    padding: 32px 0px;
  }

  .facility-page__cost-comparison-section .facility-page__facility-page-title {
    padding: 0px 16px;
  }

  .facility-page__cost-comparison-description {
    display: none;
  }

  .facility-page__cost-comparison-graph-section {
    flex-direction: column;
    padding: 0;
    background: white;
  }

  .facility-page__cost-comparison-text {
    order: 1;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.132px;
    margin-bottom: 24px;
    margin-left: 0px;
    padding: 0px 16px;
    max-width: 100%;
  }

  .facility-page__cost-comparison-graph-and-labels {
    order: 2;
    width: 100%;
  }

  .facility-page__cost-comparison-graph {
    margin: 0;
    width: 100%;
    grid-template-columns: 124px 124px;
    justify-content: space-evenly;
    justify-items: center;
    background: linear-gradient(0deg, rgba(183, 196, 228, 0.3) -4.6%, rgba(183, 196, 228, 0) 100%);
  }

  .facility-page__cost-bar {
    content: '';
    width: 80px;
    border-radius: 8px 8px 0px 0px;
  }

  .facility-page__cost-bar-label {
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.132px;
    width: 124px;
  }

  .facility-page__services-section {
    padding: 32px 16px;
  }

  .facility-page__all-services-heading {
    display: block;
  }

  .facility-page__service-search {
    width: 100%;
  }

  .facility-page__handl-category.card {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.132px;
  }

  .facility-page__handl-category-alpha {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.132px;
    margin-top: 12px;
  }

  .facility-service-item {
    margin-bottom: 24px
  }
  
  
  
}

