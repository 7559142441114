.sidebar-layout {
  height: fit-content;
  min-height: 100%;
  display: block;
}

.sidebar-layout__body-default {
  display: flex;
  min-height: calc(100vh - 80px);
}

.sidebar-layout__body-sign-in {
  display: flex;
  min-height: 80vh;
}

.sidebar-layout__sidebar {
  position: relative;
  z-index: -1;
}

.sidebar-layout__content-container {
  height: 100%;
  flex-grow: 1;
}

.sidebar-layout__content {
  height: fit-content;
  width: 100%;
  padding: 64px 0px;
}

@media only screen and (max-width: 768px) {
  .sidebar-layout__body-default {
    flex-direction: column;
    min-height: calc(100vh - 60px);
  }

  .sidebar-layout__sidebar {
    order: 2;
    overflow: hidden;
  }

  .sidebar-layout__content {
    order: 1;
    padding: 92px 16px;
    margin: 0px;
  }
}
