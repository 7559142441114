.serivce-category-details {
  padding: 0px 40px;
}

.service-groups {
  padding: 40px 40px 0px 40px;
}

.service-group-name {
  padding-top: 16px;
  padding-bottom: 40px;
}

.service-category-container {
  padding-bottom: 40px;
}

