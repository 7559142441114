@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@600);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@800);
@import url(https://fonts.googleapis.com/css2?family=Crimson+Pro);
@import url(https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@600);
@import url(https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@700);
@import url(https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@200);
@import url(https://fonts.googleapis.com/css2?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=Poppins);
body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  min-height: 100%;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* TEXT STYLES */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Crimson Pro", "Times New Roman", Times, serif;
  margin: 0;
  letter-spacing: normal;
}

h1.large-heading {
  font-size: 60pt;
  line-height: 67pt;
}

/* h2 {
  font-size: 48px !important;
  line-height: 53px !important;
} */

h3 {
  font-size: 43px !important;
  line-height: 48px !important;
}

h4 {
  font-size: 30px !important;
  line-height: 33px !important;
}

h5 {
  font-size: 2.25rem !important;
  line-height: 40px !important;
}
a {
  cursor: pointer;
}

p,
a {
  font-family: "Poppins";
  color: black;
}

.bold {
  font-weight: bold;
}

.text-large {
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.13px;
}

.secondary-bold {
  font-size: 20px;
  line-height: 153.5%;
}

.text-medium {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.6px;
}

.text-alt-heading {
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.text-subheading {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.132px;
}

.text-description {
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 23px;
}

.text-small {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
}

.text-xsmall {
  font-size: 10px;
  letter-spacing: 1.5px;
  line-height: 16px;
  margin: 0px !important;
}

.body-1 {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
}

.body-2 {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.body-3 {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.25px;
}

.label-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #080707;
}
.poppins {
  font-family: "Poppins";
}

.heading-poppins {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.132px;
}

.subheading-poppins {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.heading-crimson-pro {
  font-family: "Crimson Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0.353796px;
  color: #000000;
}

.subheading-crimson-pro {
  font-family: "Crimson Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 27px;
  letter-spacing: 0.353796px;
  color: #000000;
}

.center-text {
  text-align: center;
}

.visible {
  visibility: visible;
}

.uppercase {
  text-transform: uppercase;
}

/* FONT WEIGHT */
.fw-200 {
  font-weight: 200;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}
.flex-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-wrapper-space-between {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.flex-row {
  display: flex;
}

/* MARGIN */
.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-bottom-4 {
  margin-bottom: 4px !important;
}

.margin-bottom-8 {
  margin-bottom: 8px !important;
}

.margin-bottom-16 {
  margin-bottom: 16px !important;
}

.margin-bottom-24 {
  margin-bottom: 24px !important;
}

.margin-bottom-32 {
  margin-bottom: 32px !important;
}

/* WIDTH STYLES */
.width-343 {
  width: 100%;
  max-width: 343px;
}

.width-896 {
  width: 100%;
  max-width: 898px;
  margin: 0 auto;
}
/* MULTIUSE STYLES */
.progress {
  height: 0.5rem !important;
}
.pointer {
  cursor: pointer;
}

li::after {
  display: none;
}

.faq-link,
.faq-link:hover {
  color: inherit;
  text-decoration: underline;
}
.back-arrow {
  border: solid black;
  border-width: 0 2.5px 2.5px 0;
  display: inline-block;
  padding: 2.5px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  height: 2.5px;
  width: 2.5px;
  position: relative;
  z-index: 66;
  cursor: pointer;
}

.arrow-down {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid black;
  z-index: 0;
}

.arrow-right {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.generic-container {
  background-color: #f8f8f8;
}

/* COLORS */

.color-gray {
  color: #363636;
}

.color-default-blue {
  color: #4c6bbb !important;
}

.color-262525 {
  color: #262525;
}

.color-dark-blue {
  color: #2a4b9f;
}
/* miscellaneous background colors */
.bg-light-blue {
  background-color: #dbe1f1;
}

@media screen and (max-width: 768px) {
  .generic-container {
    background-color: #f7f7f7;
  }
}

/* DESKTOP AND MOBILE STYLES */
input {
  font-size: 16px;
  color: black;
}

.landing-page-heading {
  margin-top: 40px;
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a9a9a9;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a9a9a9;
  opacity: 1; /* Firefox */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a9a9a9;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a9a9a9;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a9a9a9;
}

.service-offerings {
  position: relative;
  z-index: 1;
}

/* DESKTOP STYLES */
.landing-page-heading {
  margin-top: 40px;
}

.service-offerings-text {
  margin-bottom: 24px;
  margin-bottom: 40px;
}

/* MOBILE STYLE */
.mobile-service-search {
  position: relative;
  height: 90%;
}

.mobile-heading {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.17px;
  line-height: 39px;
  margin: 0px;
  font-weight: semi-bold;
  padding-bottom: 24px;
  padding-top: 24px;
}

.mobile-subheading {
  margin: 0px;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
}

.search-description {
  padding: 0px 40px 40px 40px;
}

.service-search-sticky {
  z-index: 997 !important;
  background-color: #080707;
  padding: 62px 30px 40px 30px;
  margin-bottom: 40px;
}

.mobile-landing-page-heading {
  font-weight: bold;
  background-color: #080707;
  color: white;
  padding: 70px 30px 0px 30px;
  margin-bottom: -1px;
  font-size: 48px;
  line-height: 53px;
}

/* ESTIMATES */
.estimates-main-container {
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.2),
      rgba(219, 225, 241, 0.2)
    ),
    #ffffff;
}

.estimates-content {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 32px;
}
.estimates-content p.pointer {
  margin-bottom: 32px;
}
.estimates-search-container {
  padding: 32px;
  max-width: 960px;
  width: 100%;
  background: #ffffff;
  border-radius: 20px;
  margin-bottom: 80px;
}
@media only screen and (max-width: 768px) {
  .landing-page-main {
    margin-top: 49px;
  }
  .service-offerings {
    padding: 0px;
  }

  .subheading {
    width: 100%;
    margin-top: 20px;
  }

  .service-search-sticky {
    z-index: 997 !important;
    background-color: #080707;
    padding: 62px 30px 40px 30px;
  }

  .mobile-landing-page-heading {
    font-weight: bold;
    background-color: #080707;
    color: white;
    padding: 70px 30px 0px 30px;
    margin-bottom: -1px;
  }

  /* ESTIMATES */
  .estimates-content {
    padding: 16px;
  }

  .estimates-search-container {
    background: transparent;
    padding: 0;
  }

  .estimates-search-container h5 {
    font-size: 24px !important;
    line-height: 27px !important;
  }
}

button.button {
  border-radius: 100px;
  border: none;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: 100%;
  white-space: nowrap;
  display: block;
  margin: auto;

  font-family: "Poppins";
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #ffffff;
}

button.button.submit-btn {
  font-weight: 700;
  width: 220px;
  padding: 16px 24px;
}

button.button.long-btn {
  font-weight: 500;
  width: 287px;
  padding: 8px 24px;
}

button.button.short-btn {
  font-weight: 500;
  width: 194px;
  padding: 8px 26px;
}

button.button.outline-btn-blue {
  border: 2px solid #4c6bbb;
  background: transparent;
  color: #080707;
  padding: 8px 24px;
}

button.button.outline-btn-black {
  padding: 8px 24px;
  color: #080707;
  border: 2px solid #080707;
  border-radius: 20px;
  background-color: transparent;
}

button.button.submit-btn.active {
  background: #333333;
}

button.button.submit-btn.disabled {
  background: #c6c6c6;
}

button.button.long-btn.filled.active,
button.button.short-btn.filled.active {
  background: #4c6bbb;
}

button.button.long-btn.outline.active {
  border: 1.5px solid #4c6bbb;
  background: #ffffff;
  color: #080707;
}

button.button.long-btn.disabled {
  background: #b0b6c6;
}

button.button.text-btn {
  background-color: transparent;
  color: black;
  font-weight: 500;
}

button.button.text-btn.disabled {
  background-color: transparent;
  color: #737272;
  font-weight: 500;
}

button.button.outline-btn.active {
  border: 1.5px solid #018077;
  background: #ffffff;
  color: #080707;
  padding: 8px 24px;
}

button.button.outline-btn.disabled {
  border: 1.5px solid #c6c6c6;
  background: #ffffff;
  color: #868686;
  padding: 8px 24px;
}

button.button.with-shadow {
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 33%);
}

button.button.open-search-btn {
  width: 100%;
  position: relative;
  padding: 12px 0px;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.6px;
  text-transform: none;
  background-color: white;
  color: black;
  font-weight: 500;
}

button.button.mobile-estimate-form-btn.active {
  background-color: #737272;
}

button.button.mobile-estimate-form-btn.disabled {
  background: #c6c6c6;
}

button.button.login-btn {
  background: #0f3544;
  width: 119px;
  height: 36px;
}

/* .button {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  margin: 0px auto;

  border: none;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.33);
  border-radius: 25px;
  background-color: #737272;
  
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.25px;
  line-height: 20px;
  color: white;
}

.buton.dark {
  background-color: #333333
}

.mobile-search {
  width: 100%;
  color: black;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.6px;
  background-color: white;
  position: relative;
  white-space: normal;
  padding: 12px 0px;
  flex-wrap: wrap;
}

.button.active {
  font-weight: 900;
  background-color: #333333;
}
 */

.loading {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.handl-logo{
  margin: 0px;
  font-weight: 900;
}

/* p {
  font-family: 'Poppins';
  margin: 0;
  color: black;
}

.bold {
  font-weight: bold !important; 
}

.extra-bold {
  font-weight: 800;
}

.cost-estimate-text {
  margin-bottom: 0.5rem;
}

.x-small {
  font-size: 10px;
  letter-spacing: 1.5px;
  line-height: 16px;
}

.small-text {
  font-size: 13px;
  letter-spacing: 1.25px;
  line-height: 20px;
}

.medium {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.6px;
}

.large {
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.13px;
}

.x-large {
  font-size: 28px;
  line-height: 42px;
  letter-spacing: 0.17px;
}

.keyword {
  background-color: #333333;
  width: fit-content;
  color: white;
  border-radius: 17.5px;
  padding: 5px 15px;
  height: 25px;
  margin-bottom: 10px;
  margin-right: 10px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.dark {
  color: #080707;
  margin-bottom: 0px;
}

.input-hint {
  font-size: 14px;
  font-weight: 500;
  color: silver;
}
 */


.all-services-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.service-container {
  padding: 1rem;
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  box-sizing: border-box;
  background-color: white;
  cursor: pointer;
}

.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.tab-background {
  background-color: #EFEFEF;
}

.handl-category-description {
  margin-top: 24px;
  margin-bottom: 40px;
}

.handl-category {
  background-color: #EFEFEF !important;
  padding-bottom: 50px;
}

.all-services-heading {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 21px;
}



@media only screen and (max-width: 768px) {

  .all-services-list {
    grid-template-columns: 1fr;
    margin-left: -15px;
    margin-right: -15px;
  }

  .service-container {
    padding: 1rem 1rem 1rem 3rem;
  }

  .arrow {
    display: none;
  }

  .all-services-label {
    width: 175px;
  }

}

.service-icon {
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
  content: "";
  background-color: black;
  margin-right: 20px;
}

li.list-item {
  display: flex;
  color: black;
}

.service-list {
  margin: 0px;
  padding: 0px;
}

.vertical-list-information {
  min-width: 100px;
  word-wrap: break-word;
  white-space: normal;
}

.square-icon {
  height: 100px;
  width: 100px;
  margin-right: 5px;
  border-radius: 5px;
  content: "";
  background-color: #0da789;
}

.wrap {
  word-wrap: break-word;
  white-space: normal;
}

.service-group-icon {
  color: #080707;
  font-size: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 22px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  grid-gap: 10px;
  gap: 10px;
  background: #dbe1f1;
  border-radius: 20px;
  margin-bottom: 0;
  width: -webkit-max-content;
  width: max-content;
}

.placeholder {
  margin-left: 112px;
}

.facility-name {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #080707;
}

.facility-user-cost {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  text-align: start;
  letter-spacing: 0.132px;
  color: #080707;
}

.width-204 {
  width: 204px;
  max-width: 100%;
}

.your-estimate-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.25px;
  color: #080707;
}
@media only screen and (max-width: 768px) {
  .service-icon {
    display: none;
  }

  .service-information {
    margin-left: 0px;
  }
}

.currently-selected {
  background-color: rgba(103, 179, 173, 0.2) !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  letter-spacing: 0.132px !important;
  color: #018077 !important;
}

.fancy-search-sort {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
}

.dropdown > button.filter-option {
  padding: 4px 12px;
  height: -webkit-max-content;
  height: max-content;
  border: 0.8px solid #c6c6c6;
  border-radius: 20px;
  white-space: nowrap;
  cursor: pointer;
  color: #000000;
  box-shadow: none;
}

.dropdown .dropdown-toggle::after {
  display: none;
}

.dropdown-item.filter-item:hover {
  background: rgba(103, 179, 173, 0.1) !important;
}

.dropdown-item.filter-item {
  padding: 19px 0px 19px 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.dropdown .btn-primary:not(.filter-option-active):focus {
  background-color: transparent !important;
  border: 0.8px solid #c6c6c6 !important;
  box-shadow: none !important;
  color: #000000 !important;
}

.dropdown > button.filter-option-active {
  padding: 4px 12px;
  height: -webkit-max-content;
  height: max-content;
  border-radius: 20px;
  white-space: nowrap;
  cursor: pointer !important;
  background: rgba(103, 179, 173, 0.2) !important;
  color: #00403c !important;
  border: 1.8px solid #018077 !important;
  box-shadow: none;
}
.dropdown .btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.dropdown .btn-primary:not(:disabled):not(.disabled):active {
  background-color: transparent;
  border: 0.8px solid #c6c6c6;
  box-shadow: none;
  color: #000000;
}

@media screen and (max-width: 768px) {
  .fancy-search-sort {
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.25px;
  }
}


.what-we-do-content {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 40px;
}

.what-we-do-section {
  padding: 0px 15px;
}

.what-we-do-heading {
  font-size: 48px;
  line-height: 53px;
  font-family: 'Crimson Pro', 'Times New Roman', Times, serif;
  margin: 0px 0px 40px 0px;
  letter-spacing: normal;
  font-weight: 700;
}

.what-we-do-section-heading {
  margin-bottom: 40px;
}

.what-we-do {
  padding: 100px 0px;
}

@media only screen and (max-width: 768px) {

  .what-we-do-heading {
    font-size: 28px;
    font-family: 'Poppins';
    font-weight: 500;
    letter-spacing: 0.17px;
    line-height: 39px;
  }

  .what-we-do-content {
    flex-direction: column;
    text-align: left;
  }

  .what-we-do {
    padding: 100px 40px 0px 40px;
  }

  .what-we-do-section-heading {
    margin-bottom: 24px;
  }

  .what-we-do-section {
    padding: 0px;
    margin-bottom: 8px;
  }
}

.first-visit-heading {
  font-family: 'Crimson Pro', 'Times New Roman', Times, serif;
  font-weight: 900;
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 12px;
}

.first-visit-text {
  margin: 0px;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 23px;
}

.first-visit-btn {
  margin-bottom: 24px !important;
}

.first-visit-modal.modal .modal-dialog {
  position:fixed;
  bottom: 10px;
  left: 10px;
  margin:0px;
}

@media only screen and (max-width: 768px) {

  .first-visit-heading {
    font-size: 25px;
    line-height: 33px; 
  }
  
  .first-visit-text {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
  }

  .first-visit-modal.modal .modal-dialog {
    width: 80vw;
  }

  .first-visit-popup .contact-form .contact-input {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
  }

}

input,
input.form-control {
  border: none;
  font-family: Poppins;
  position: relative;
  z-index: 0;
  border: none;
}

.form-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  margin-top: 0 !important;
  margin-bottom: 8px;
  max-width: 424px;
}

.input-arrow-down {
  top: 25px;
  right: 25px;
}

.form-group.select-input {
  margin-bottom: 0px;
}

.select-input-options {
  margin-top: 8px !important;
  padding: 15px 0px;
}

.select-input-options li:hover {
  background-color: #efefef;
}

.handl-logo {
  margin: 0px;
  font-weight: 900;
}

.icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nav-link {
  margin-left: 10px;
  margin-bottom: 0px;
  cursor: pointer;
  text-decoration: none;
}

.dropdown-item:active {
  background-color: transparent !important;
}

a {
  text-decoration: none;
}

a::hover {
  text-decoration: none;
}

.nav-link::hover {
  text-decoration: none;
}

.nav-link-text {
  margin-bottom: 0px;
}

.navbar {
  max-width: 100vw;
}

.mobile-header.bg-white {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
}

.header {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
}

.white-arrow {
  border-color: white !important;
}

.back {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
}

.quote-navigation {
  padding: 80px 40px 40px 40px;
  width: 100%;
}

.back-text {
  padding-left: 24px;
  margin-bottom: 0px;
  margin-top: -4px;
}

#resources-dropdown-toggle.btn-primary {
  color: black;
  font-family: "Poppins";
}

#mobile-dropdown-toggle.dropdown-toggle::after,
#resources-dropdown-toggle.dropdown-toggle::after {
  display: none !important;
}

#mobile-dropdown-toggle.blue,
#mobile-dropdown-toggle.blue:focus,
#mobile-dropdown-toggle.blue:active,
#resources-dropdown-toggle.blue,
#resources-dropdown-toggle.blue:focus,
#resources-dropdown-toggle.blue:active {
  background-color: #b7c4e4;
  border-color: #b7c4e4;
  box-shadow: none;
}

#mobile-dropdown-toggle.white,
#mobile-dropdown-toggle.white:focus,
#mobile-dropdown-toggle.white:active,
#resources-dropdown-toggle.white,
#resources-dropdown-toggle.white:focus,
#resources-dropdown-toggle.white:active {
  background-color: white;
  border-color: white;
  box-shadow: none;
}

#mobile-dropdown-toggle.dark,
#mobile-dropdown-toggle.dark:focus,
#mobile-dropdown-toggle.dark:active {
  background-color: #333333;
  border-color: #333333;
  box-shadow: none;
}

#mobile-dropdown-toggle.black,
#mobile-dropdown-toggle.black:focus,
#mobile-dropdown-toggle.black:active {
  background-color: #080707;
  border-color: #080707;
  box-shadow: none;
}

.mobile-header .dropdown .dropdown-menu {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  right: 0px;
  top: 50px;
  left: unset;
}

.mobile-header.bg-black {
  background-color: #080707;
}

a.dropdown-item a {
  color: black;
}

.handl-logo.dark,
.handl-logo.black {
  color: white;
}

.back-arrow.quote-navigation-arrow {
  position: absolute;
  left: 40px;
  top: 82px;
}

.dark-background {
  background-color: #333333 !important;
}

/* USER NAVIGATION */

.navigation-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #dbe1f1;
  display: flex;
  align-items: center;
  height: 80px;
  width: 100%;
  padding: 24px 32px;
  z-index: 9999;
  justify-content: space-between;
}

.navigation-bar .nav-left-container {
  align-items: center;
  grid-gap: min(3vw, 36px);
  gap: min(3vw, 36px);
}

.navigation-bar__page-links {
  display: flex;
  grid-gap: max(3vw, 28px);
  gap: max(3vw, 28px);
}

.navigation-bar__user-actions {
  display: flex;
  grid-gap: min(1.25vw, 1.5rem);
  gap: min(1.25vw, 1.5rem);
}

.navigation-bar__link {
  color: #080707;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.132px;
}

.navigation-bar__page-links button.navigation-bar__dropdown {
  background-color: transparent;
  color: #080707;
  border: none;
  box-shadow: none;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.132px;
  padding: 0px;
  display: block;
}

button.navigation-bar__dropdown::after {
  display: none !important;
}

button.navigation-bar__dropdown:focus,
button.navigation-bar__dropdown:active,
button.navigation-bar__dropdown:hover,
.navigation-bar__link:hover {
  background-color: transparent;
  color: #080707;
  text-decoration: none;
}

/* UPDATED MOBILE NAV */

/* hamburger */
.nav-toggle {
  display: flex;
  flex-direction: column;
  grid-gap: 3px;
  gap: 3px;
  width: 22px;
}

.nav-toggle .bar {
  height: 3px;
  width: 100%;
  background-color: black;
  transition: all 100ms ease-in-out;
}
.x:nth-of-type(1) {
  transition: all 100ms ease-in-out;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transform-origin: top left;
          transform-origin: top left;
  width: 25px;
}

.x:nth-of-type(2) {
  transition: all 100ms ease-in-out;
  -webkit-transform-origin: center;
          transform-origin: center;
  width: 0;
}

.x:nth-of-type(3) {
  transition: all 100ms ease-in-out;
  -webkit-transform: rotate(-45deg) translate(-3px, 3px);
          transform: rotate(-45deg) translate(-3px, 3px);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  width: 25px;
}

.updated-mobile-nav-container {
  background-color: #dbe1f1;
  height: 100vh;
  min-height: -webkit-fill-available;
  width: 100vw;
  padding-top: 64px;
  padding-inline: 24px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  transition: -webkit-transform 250ms ease-out;
  transition: transform 250ms ease-out;
  transition: transform 250ms ease-out, -webkit-transform 250ms ease-out;
  overflow-y: auto;
}

.updated-mobile-nav-container__active {
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}

.updated-mobile-nav-container__menu-options {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #0a2530;
  margin-bottom: 32px;
}

.updated-mobile-nav-container ul {
  padding-top: 10px;
  padding-left: 12px;
  visibility: hidden;
}
.updated-mobile-nav__li-options {
  margin-bottom: 16px;
}
.updated-mobile-nav__li-options,
.navigation-bar__user-actions .navigation-bar__user-actions-btn {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #080707;
}
.updated-mobile-nav-container__menu-options:hover {
  color: #4c6bbb;
}

.updated-mobile-nav-container__help-section {
  padding-top: 40px;
}
.updated-mobile-nav-container__help-section {
  margin-bottom: 24px;
}
.updated-mobile-nav-container__handl-logo {
  position: absolute;
  bottom: 0;
  right: -24px;
}

.find-an-estimate-container {
  padding-top: 42px;
  padding-bottom: 42px;
  grid-gap: 22px;
  gap: 22px;
}

.line {
  width: 327px;
  height: 0px;

  /* Blue/Dark */

  border: 1px solid #4c6bbb;
}

.updated-mobile-nav-container__home-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  letter-spacing: 0.132px;
  margin-bottom: 0px;
}
/* DESKTOP NAVIGATION */
.mobile-header {
  padding-top: 0.8125rem !important;
  padding-bottom: 0.8125rem !important;
  padding-inline: 24px !important;
}
.navigation-bar .dropdown .btn:focus {
  box-shadow: none !important;
}
p.userActions-navigation__dropdown-open,
div.userActions-navigation-dropdown-open,
div.userActions-navigation__dropdown-open > p {
  position: relative;
  color: #4c6bbb;
}
p.userActions-navigation__dropdown-open::after,
div.userActions-navigation__dropdown-open::after {
  content: "";
  height: 4px;
  background-color: #4c6bbb;
  width: 100%;
  position: absolute;
  border-radius: 99px;
  bottom: -8px;
  left: 0;
}

.navigation-bar .userActions-navigation__dropdown-menu a.dropdown-item:hover {
  text-decoration: none;
  color: #4c6bbb;
}

.navigation-bar .userActions-navigation__dropdown-menu {
  background: #dbe1f1;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 0px;
  top: 24px !important;
  left: -10px !important;
  border: none;
  min-width: 5em;
}

.navigation-bar .userActions-navigation__dropdown-menu .dropdown-item,
.userActions-navigation-bar__items {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #080707;
}

.navigation-bar .userActions-navigation__dropdown-menu .dropdown-item:active,
.navigation-bar .userActions-navigation__dropdown-menu .dropdown-item:hover {
  color: #080707;
  background-color: transparent;
}

.navigation-bar .userActions-navigation__dropdown-user-information-menu {
  background-color: #ffffff;
  padding: 45px 33px 45px;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.15);
  border: none;
  top: 10px !important;

  width: 195px;
}

.userActions-navigation__dropdown-user-information .dropdown-item {
  display: contents;
  width: 0;
  padding: 0;
  white-space: inherit;
}

.userActions-navigation__dropdown-user-information-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.25px;
  color: #080707;
  margin-bottom: 8px;
}

.userActions-navigation__dropdown-user-information a:hover {
  -webkit-text-decoration-color: rgba(54, 54, 54, 0.9);
          text-decoration-color: rgba(54, 54, 54, 0.9);
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.userActions-navigation__dropdown-user-information li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: rgba(54, 54, 54, 0.9);
  margin-bottom: 12px;
}

.userActions-navigation__dropdown-user-information button {
  margin: auto;
  font-weight: 500;
}
.userActions-navigation__dropdown-user-information button:nth-of-type(1) {
  margin-top: 24px;
}
.userActions-navigation__dropdown-user-information button:nth-of-type(2) {
  margin-top: 24px;
}

/* SEARCH ICON STYLES */
.navigation-bar__user-actions-search {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigation-bar__user-actions-search-icon {
  cursor: pointer;
}
.navigation-bar__user-actions-search-active {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.5),
      rgba(219, 225, 241, 0.5)
    ),
    #ffffff;
  position: absolute;
  z-index: -1;
}

.navigation-bar__user-actions-search-inactive {
  display: none;
}

@media screen and (max-width: 820px) {
  .navigation-bar {
    padding: 8px;
  }

  .userActions-navigation__dropdown-open {
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
  }
  p.userActions-navigation__dropdown-open::after,
  div.userActions-navigation__dropdown-open::after {
    width: 0%;
  }
}

.user-profile-icon {
  background: #67b3ad;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.navigation-bar__user-actions .large {
  width: 60px;
  height: 60px;
}

.navigation-bar__user-actions .small {
  width: 40px;
  height: 40px;
  padding-top: 1px;
  position: relative;
  margin-bottom: 0;
}

.navigation-bar__user-actions .active-open {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.5),
      rgba(219, 225, 241, 0.5)
    ),
    #ffffff;
  position: absolute;
  z-index: -1;
}

.hidden-selected {
  display: none;
}

footer {
  overflow: hidden;
  background-color: #0a2530;
}

.footer-links > .flex-col > p {
  color: #fff;
  margin-bottom: 8px;
}

.footer-container {
  margin: auto;
  padding: 24px 80px 40px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-row {
  display: flex;
}

.footer-icons img {
  margin-left: 32px;
}

.footer-links {
  width: 100%;
  justify-content: space-between;
}

span.footer-title {
  color: white;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 23px;
}

a.footer-link {
  color: white;
  text-decoration: none !important;
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 27px;
  font-family: "Poppins";
  font-style: normal;
}

a.footer-link:hover {
  color: white;
  text-decoration: none !important;
}

.about-us-link {
  margin-left: auto;
}
@media only screen and (max-width: 768px) {
  .footer-container {
    padding: 30px 30px 40px 30px;
  }

  .footer-links {
    flex-direction: column;
  }

  .about-us-link {
    margin: 40px 0px 24px;
  }

  .footer-icons img {
    margin-left: 0;
    margin-right: 32px;
  }
}

.service-offerings .tab-list {  
  margin-bottom: 0px;
  padding: 40px 0px 0px 0px;
  font-size: 25px;
  line-height: 38px;
  letter-spacing: 0.15px;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none; 
}

.service-offerings .tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 43px;
  border-bottom: 1px solid #979797;
}

.service-offerings .tab-list-active {
  border-bottom: solid black;
  border-width: 3px;
  font-weight: 900;
}

.service-offerings .tab-list::-webkit-scrollbar {
  display: none;
}

.service-offerings .tabs {
  overflow: visible;
}

@media only screen and (max-width: 768px) {

  .service-offerings .tab-list { 
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.11px;
    line-height: 27px;
    padding-left: 42px;
  }

  .service-offerings .tab-list-item {
    padding: 0.5rem 22px;
  }

}


.close-modal {
  border-radius: 16px !important;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.estimate-form__desktop-container {
  margin-bottom: 200px;
}

.estimate-form__step-description {
  margin-bottom: 40px;
}
p.estimate-form__step-description {
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.132px;
  margin-bottom: 40px;
  font-weight: 500;
}

.estimate-form__desktop-container {
  margin-bottom: 0;
}

p.estimate-form__step-description {
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.132px;
  margin-bottom: 40px;
  font-weight: 500;
}

.service-quote-form {
  color: black;
  background-color: #f8f9fa;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  overflow: auto;
}

.cancel-icon {
  position: absolute;
  margin-top: -32px;
  right: 10px;
  z-index: 999;
}

.description-text {
  margin-top: 40px;
  margin-bottom: 40px;
}

.desktop-quote-form {
  margin-bottom: 200px;
}

/* DESKTOP AND MOBILE */
.form-control.is-invalid,
.was-validated .form-control:invalid {
  box-shadow: 0 0 6px 0 #dc3545;
  background-size: 22px 22px !important;
  z-index: 1;
  background-position: right 22px center !important;
}

.form-control.is-valid,
.form-control:valid:focus,
.form-control:valid:active,
.was-validated .form-control:valid {
  box-shadow: none !important;
  background-image: none !important;
  border: none !important;
  border-bottom: 1px solid #080707 !important;
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  box-shadow: none;
  border: none;
  border-bottom: 1px solid #080707;
}

.invalid-feedback {
  padding-left: 22px;
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a9a9a9;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a9a9a9;
  opacity: 1; /* Firefox */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a9a9a9;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a9a9a9;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a9a9a9;
}

#thank-you-container {
  display: none;
  width: 100%;
  text-align: center;
  margin: 40px 0px;
}

#thank-you-container > p.text-large {
  font-weight: 600;
}

#not-available-estimates-catalog {
  margin: 95px 0 200px;
  display: none;
}
.thank-you-text {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.17px;
  line-height: 39px;
  margin: 0px;
  font-weight: semi-bold;
  padding-bottom: 24px;
}

/* DESKTOP */
.quote-form-label {
  position: absolute;
  font-weight: bold;
  z-index: 999;
  top: -10px !important;
  font-family: "Poppins";
  font-style: normal;
}

.landing-page-input.form-control {
  background-color: white;
  height: 56px;
  padding: 15px 22px 0px 22px !important;
  margin: 0px 0px;
  width: 100%;
  border-bottom: 1px solid #080707;
  position: relative;
}

.form-input-icon {
  position: absolute;
  top: 32px;
  right: 18px;
}

.estimate-form__desktop-container .input-and-label {
  padding-top: 0;
}

.estimate-form__desktop-container .input-and-label label {
  top: -20px !important;
}
.landing-page-input.form-control:focus + label,
.landing-page-input.form-control:valid + label {
  display: block;
}
.landing-page-input.form-control:focus,
.not-available-input.form-control:focus {
  box-shadow: none;
  border-bottom: 1px solid #080707;
}

.quote-option-section {
  padding: 0px 40px 40px 40px;
}

.section-name {
  padding-top: 16px;
  padding-bottom: 40px;
}

/* MOBILE */
.quote-option-input.form-control {
  color: black;
  background-color: #d8d8d8;
  border-bottom: 1px solid black;

  width: 100%;
  padding: 0.75rem 0.75rem 0.75rem 3rem;

  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 27px;
}

.quote-option-input.form-control:focus {
  box-shadow: none !important;
  border-bottom: 1px solid black;
}

.zipcode-icon {
  position: absolute;
  top: 73px;
  left: 15px;
  z-index: 999;
}

.right-arrow-input {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  height: 3px;
  width: 3px;
  position: absolute;
  top: 77px;
  right: 15px;
  z-index: 999;
}

.revealed-input {
}

.next-btn.button {
  margin-left: 20px;
  font-size: 13px;
  padding: 16px 36px;
  letter-spacing: 1.25px;
  line-height: 20px;
}

.error-message {
  border: 1px solid #dc3545;
  border-radius: 26px;
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 27px;
  padding: 19px 22px;
}

.error-message p {
  color: #dc3545 !important;
  margin-bottom: 0px;
}

.error-message p.bold {
  margin-bottom: 12px;
}

.start-over {
  font-size: 13px;
  letter-spacing: 1.25px;
  line-height: 20px;
  margin-top: 24px;
}

.i-dont-know {
  font-family: "Poppins";
  margin-top: 12px;
  margin-left: 24px;
}

.idk-d {
  width: 65%;
}

.idk-o {
  width: 70%;
}

@media only screen and (max-width: 768px) {
  .not-available {
    margin: 110px 24px 0px 24px;
    width: auto;
  }

  .not-available-input.form-control {
    width: 100%;
  }

  .start-over {
    display: none;
  }

  .estimate-form__desktop-container > form,
  .estimate-form__desktop-container > form > .d-flex,
  .estimate-form__desktop-container form.d-flex,
  .estimate-form__desktop-container form.d-flex > .d-flex {
    flex-direction: column;
  }

  .landing-page-input.form-control,
  .landing-page-input.form-control:focus {
    background-color: transparent;
  }

  #thank-you-container > p.text-large {
    font-weight: 400;
  }
}

.no-suggestions {
  color: white;
  padding: 0.5rem;
}

.suggestions {
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  position: absolute;
  top: unset;
  right: 0;
  left: 0;
  overflow: auto;
  max-height: 256px;
  z-index: 1;
  cursor: pointer;
  margin-top: -32px;
}

.suggestions::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.navigation-suggestions {
  position: absolute !important;
}

.mobile-suggestions {
  position: relative;
  left: 0;
  right: 0;
}

.suggestions li {
  padding: 15px;
  font-size: 12px;
}

.suggestion-active {
  background-color: #e9ecef !important;
}

.suggestions li:hover {
  background-color: rgb(0 0 0 / 2.5%);
}

.insurance-plan-suggestions {
  width: 116%;
}

.select-arrow-down {
  right: 22px;
  margin-top: -22px;
}

@media only screen and (max-width: 768px) {
  .suggestions {
    width: 100%;
  }

  .suggestions li {
    font-size: 15px;
    line-height: 23px;
  }

  .suggestion-active,
  .suggestions li:hover {
    background-color: white;
    cursor: pointer;
  }
}

.suggestions li.disabled {
  background-color: #C4C4C4;
}

.service__list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 24px;
  border-bottom: 0.5px solid #D8D8D8;
  background-color: white;
}

.service__list-item.transparent-bg {
  background-color: transparent;
}

.service__list-item.highlighted {
  background-color: #EFEFEF;
}

.service__list-item-overline {
  font-family: Poppins;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  margin: 0px;
}

.service__list-item-centerline {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0.132px;
  margin-top: 4px;
  margin-bottom: 0px;
}

.service__list-item-underline {
  font-family: Poppins;
  font-size: 15px;
  line-height: 22px; 
  letter-spacing: 0.4px;
  margin-top: 4px;
  margin-bottom: 0px;
}

.service__arrow {
  width: 6px;
  height: 100%;
  border: solid #080707;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-left: 32px;
}

@media only screen and (max-width: 768px) {
  .service__list-item {
    padding: 12px 0px;
  }

  .padding-adjustment {
    padding: 16px;
  }

  .service__list-item-overline { 
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
  }

  .service__list-item-centerline {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.132px;
  }

  .service__list-item-underline {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.4px;
  }
}

.input-container {
  font-family: Poppins;
  position: relative;
}

.input-container div,
.input-container label {
  width: 100%;
}

.input-container div input,
.landing-page-input.form-control,
.input-container select {
  background-color: transparent !important;
  color: #080707;
  font-family: Poppins;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.4px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #080707;
  padding: 8px 24px 8px 16px;
  outline: none;
  border-radius: 0px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
}

.input-container select::-ms-expand {
  display: none;
}

.input-container div input.writeable::-webkit-input-placeholder {
  color: #363636;
}

.input-container div input.writeable:-ms-input-placeholder {
  color: #363636;
}

.input-container div input.writeable::placeholder {
  color: #363636;
}

.input-container div input.read-only::-webkit-input-placeholder {
  color: #848484;
}

.input-container div input.read-only:-ms-input-placeholder {
  color: #848484;
}

.input-container div input.read-only::placeholder {
  color: #848484;
}

.input-container div input.read-only {
  border-color: #848484;
  cursor: default;
}

.input-and-label {
  position: relative;
  padding-top: 20px;
}

.input-container .input-and-label label,
.form-label {
  display: none;
  position: absolute;
  top: 0px;
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.input-container .show-label label {
  display: block !important;
}
.input-container
  .input-and-label
  input.read-only:not(:-ms-input-placeholder)
  + label {
  display: block;
}
.input-container .input-and-label input.writeable:focus + label,
.input-container .input-and-label input.writeable:valid + label,
.input-container
  .input-and-label
  input.read-only:not(:placeholder-shown)
  + label {
  display: block;
}

.input-container .input-and-label input.writeable:focus::-webkit-input-placeholder {
  color: transparent;
}

.input-container .input-and-label input.writeable:focus:-ms-input-placeholder {
  color: transparent;
}

.input-container .input-and-label input.writeable:focus::placeholder {
  color: transparent;
}

.input-container.placeholder-gray-bg div input,
.input-container.placeholder-gray-bg select {
  background: #eaeaea;
}

.input-container.placeholder-gray-bg div input,
.input-container.placeholder-gray-bg select {
  padding: 8px 24px;
}

.input-container .input-icon {
  position: absolute;
  cursor: pointer;
  z-index: 0;
  right: 12px;
  top: 30px;
}

.input-container .input-icon .input-arrow {
  content: "";
  display: block;
  width: 0;
  height: 0;
  top: 36px;
  right: 24px;

  border: solid #080707;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.input-container .input-arrow.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.input-container .input-arrow.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.input-message {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  padding-top: 8px;
  padding-left: 16px;
  margin-top: -1px;
  position: absolute;
  z-index: 1;
}

.input-message.error {
  color: #d94411;
  border-top: 1px solid #d94411;
}

.input-message.error a {
  color: #d94411;
}

.input-message.valid {
  color: #018077;
  border-top: 1px solid #018077;
}

.input-message.valid a {
  color: #018077;
}

.input-action {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.15px;
  text-transform: uppercase;
}

.currency-symbol {
  color: #080707;
  font-family: Poppins;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.4px;
  border-top: none;
  display: inline-flex;
  position: absolute;
  bottom: 9px;
  left: 4px;
}

.select-only {
  caret-color: transparent;
  cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.form-group {
  margin-bottom: 32px !important;
}

@media only screen and (max-width: 768px) {
  .input-message {
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.25px;
  }
}

.estimates-catalog {
  overflow-x: auto;
  overflow-y: hidden;
  grid-gap: 24px;
  gap: 24px;
  padding: 5px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.estimates-catalog::-webkit-scrollbar {
  display: none; /* for Chrome, Safari and Opera */
}

.estimates-catalog .estimate-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 136px;
  height: 120px;
  padding-inline: 2px;
  background: #ffffff;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}

.estimates-catalog p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.25px;
}

.estimates-catalog-heading {
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  /* identical to box height */

  letter-spacing: 1.5px;
  text-transform: uppercase;

  color: #070707;
}
@media screen and (max-width: 768px) {
  .estimates-catalog .estimate-container {
    width: 100px;
    height: 100px;
  }

  .estimates-catalog img {
    width: 56px;
    height: 56px;
  }
  .estimates-catalog p {
    font-size: 10px;
  }

  .estimates-catalog-heading {
    font-size: 15px;
    line-height: 22px;
  }
}

.cost-estimator-tools {
  width: 100%;
  max-width: 896px;
  margin: 112px auto 134px;
}

.cost-estimator-tools img {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}
.cost-estimator-flex-section {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  grid-gap: 17.5px;
  gap: 17.5px;
}

.cost-estimator-flex-section > .flex-col {
  align-items: center;
  width: 287px;
  height: 196px;
}

.cost-estimator-tools p {
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .cost-estimator-tools {
    margin: 48px auto 180px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 16px;
    grid-gap: 24px;
    gap: 24px;
    width: 100%;
    max-width: 343px;
    background: rgba(219, 225, 241, 0.5);
    border-radius: 10px;
  }

  .cost-estimator-flex-section {
    flex-direction: column;
  }

  .cost-estimator-tools h5 {
    font-weight: 500;
    font-size: 24px !important;
    line-height: 27px !important;
  }

  .cost-estimator-flex-section > .flex-col {
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

.not-available-input.form-control {
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 27px;
  text-align: center;
  border-radius: 24px;
  margin: auto;
  width: 350px;
  padding: 10px 15px;
  height: auto;
}

.not-available {
  width: 700px;
  max-width: 100%;
  margin: 12% auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 24px;
  background: #ffffff;
  border-radius: 20px;
}

.not-available section {
  margin-top: 20px;
  margin-bottom: 30px;
}

.not-available-heading {
  font-family: "Crimson Pro";
  font-size: 37px;
  font-weight: 900;
  letter-spacing: 0.25px;
  line-height: 41px;
  text-align: center;
}

.not-available-btn.button {
  margin: auto;
  font-size: 13px;
  padding: 10px 40px;
  letter-spacing: 1.25px;
  line-height: 20px;
}

.search-another-estimate-btn-container {
  margin-top: 56px;
}
@media screen and (max-width: 768px) {
  .not-available {
    background-color: transparent;
    margin-top: 56px;
    padding: 0;
  }
  #not-available {
    padding: 0px 16px 135px;
  }
  .not-available .body-1 {
    font-size: 15px;
    line-height: 24px;
  }

  .not-available-main > p {
    padding-inline: 16px;
  }
}

/* Estimate Callout */

.estimate__estimate-callout {
  padding: 40px 64px;
  background-color: #f7f7f7;
  color: #363636;
}

.estimate__estimate-width {
  max-width: 896px;
  margin: auto;
}

.estimate__estimate-width > p.pointer {
  margin-bottom: 24px;
}

.estimate__estimate-callout-overline-text {
  letter-spacing: 0.4px;
  margin-bottom: 24px;
}

.estimate__estimate-callout-facility-detail {
  text-align: center;
  letter-spacing: 1px;
  color: #080707;
  background: #dbe1f1;
  border-radius: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 4px 16px;
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.estimate__callout-main-text {
  margin: 0px;
  max-width: 642px;

  font-family: "Crimson Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 43px;
  line-height: 127.13%;
  letter-spacing: 0.25px;
  color: #363636;
}

button.button.estimate__save-estimate-button {
  margin: 40px 0px 0px 0px;
  background: linear-gradient(
      0deg,
      rgba(103, 179, 173, 0.7),
      rgba(103, 179, 173, 0.7)
    ),
    #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.328612);
  border-radius: 18px;
  color: #363636;
}

/* Estimate Sections */
.estimate__estimate-section {
  background-color: white;
  padding: 24px 32px 0px 16px;
  padding: 32px 64px;
  margin-bottom: 32px;
}

.estimate__estimate-section-title,
.estimate__more-facilities-description {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.15px;
  color: #080707;
  margin-bottom: 24px;
}

.estimate__more-facilities-description {
  font-weight: 500;
  color: #4c6bbb;
}

.estimate__estimate-section-container {
  display: flex;
  justify-content: space-between;
}

.estimate__estimate-section-emphasized {
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: 0.132px;
  width: 40%;
  margin-bottom: 0px;
  margin-top: -8px;
  color: #4c6bbb;
}

.cost-and-coverage .estimate__estimate-section-emphasized {
  width: 246px;
}

.estimate__estimate-section-description {
  width: 100%;
  max-width: 504px;
}

.container-with-top-padding {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #c4c4c4;
}

/* Payment Breakdown */
.estimate__payment-breakdown {
  margin-top: 40px;
}
.estimate__payment-accordion-toggle,
.estimate__edit-details {
  font-weight: 500;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.estimate__payment-accordion-toggle img {
  margin-left: 1rem;
}

.estimate__payment-breakdown .card-header {
  border: none;
}

.estimate__payment-accordion-toggle-open {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  content: "";
  margin-left: 10px;
  margin-bottom: 3px;
}

.estimate__payment-accordion-toggle-close {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  content: "";
  margin-left: 10px;
}

.estimate__payment-breakdown-details.card-body {
  background: #f7f7f7;
  border-radius: 20px;
  margin-top: 37px;
  padding: 0px;
}

.estimate__payment-breakdown-overline {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
  margin-bottom: 0px;
}

.estimate__payment-breakdown-overline p {
  margin-bottom: 0px;
}

.estimate__payment-breakdown-description {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.4px;
  width: 70%;
  margin: 0px;
}

.estimate__payment-breakdown-you-pay {
  background: rgba(13, 167, 137, 0.25);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top: 0.8px solid rgba(13, 167, 137, 0.6);
}

.estimate__payment-breakdown-section {
  padding: 13px 23px;
}

/* Coverage Details */

.estimate__coverage-detail-line {
  display: flex;
  justify-content: space-between;
}

.estimate__edit-details {
  margin-top: 16px;
  margin-bottom: 0;
}

/* Update Coverage */

.estimate__update-coverage-details .cost-estimate-label {
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
  margin-top: 30px;
}

.estimate__update-coverage-details .form-group {
  margin: 0px;
}

.estimate__update-coverage-details.card-body {
  padding-top: 0px;
}

.estimate__update-coverage-details button {
  background-color: transparent;
  color: black;
  border: none;
  box-shadow: none;
}

.estimate__coverage-updated-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.15px;
}

/* Coverage Form */
.estimate__coverage-form-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
  display: block;
  position: relative;
}

.estimate__coverage-form .form-group:not(:last-of-type) {
  margin-bottom: 30px;
}

.estimate__coverage-form .form-group:last-of-type {
  margin-bottom: 0px;
}

.estimate__coverage-form-container {
  padding: 32px 40px;
  background: #f8f8f8;
  border-radius: 20px;
}

button.button.estimate__update-coverage-btn {
  margin: 40px 0px 0px 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

/* Where You'll Be */

.estimate__facility-details {
  width: 50%;
}

.estimate__facility-details-text p {
  margin-bottom: 8px;
}
.estimate__facility-details iframe {
  border-radius: 10px;
}

.estimate__facility-distance {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 12px;
  color: #080707;
}

.estimate__facility-name-and-cost {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
  font-weight: 600;
}

.estimate__facility-address {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  margin-bottom: 0px;
}

/* More Facilities */

.estimate__more-facilities {
  display: flex;
  margin-bottom: 80px;
}

.estimate__more-facilities > div {
  display: flex;
}
.estimate__more-facilities-text {
  width: 40%;
}

.estimate__facilities-list-container {
  max-width: 504px;
  width: 100%;
}
ul.estimate__facilities-list {
  list-style-type: none;
  padding: 0px;
}

.estimate__facilities-list li {
  margin-bottom: 8px;
  cursor: pointer;

  padding: 16px 24px 16px 32px;

  /* Background for Apps/Sections */

  background: #f8f8f8;
  border-radius: 10px;
}

.estimate__facility-list-item-name {
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #080707;
  max-width: 305px;
}

.estimate__facility-list-item-address {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  margin-bottom: 0px;
}

#estimate__estimate-pdf {
  display: none;
}

@media only screen and (max-width: 768px) {
  /* Estimate Callout */
  #estimate-page .estimate__estimate-callout {
    padding: 84px 16px 32px;
  }

  #estimate-page .estimate__callout-main-text {
    font-weight: 700;
    font-size: 24px;
    line-height: 127.13%;
    letter-spacing: 0.25px;
    color: #363636;
  }

  #estimate-page .estimate__estimate-callout-overline {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 15px;
    grid-gap: 16px;
    gap: 16px;
  }

  #estimate-page .estimate__estimate-callout-overline-text,
  #estimate-page .estimate__estimate-callout-facility-detail {
    margin-bottom: 0px;
  }

  #estimate-page .estimate__save-estimate-button {
    width: 100%;
    margin-top: 32px;
  }

  .estimate__estimate-callout-facility-detail {
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 1px;
    padding: 4px 8px;
  }

  .estimate__estimate-width > p.pointer {
    margin-bottom: 32px;
  }
  /* Estimate Sections */
  #estimate-page .estimate__estimate-section-text {
    width: 100%;
    margin-top: 12px;
  }

  #estimate-page .estimate__estimate-section-container {
    flex-direction: column;
  }

  #estimate-page .estimate__estimate-section-description {
    width: 100%;
  }

  #estimate-page .estimate__estimate-section-emphasized {
    width: 100%;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.13px;
    margin-bottom: 24px;
  }

  #estimate-page .estimate__payment-accordion-toggle,
  .estimate__edit-details {
    text-align: center;
  }

  #estimate-page .estimate__payment-accordion-toggle::after {
    display: none;
  }

  /* Coverage Form */

  #estimate-page .estimate__coverage-updated-text {
    text-align: center;
  }

  button.button.estimate__update-coverage-btn {
    margin: 40px auto 0px auto;
    font-size: 15px;
    line-height: 22px;
  }

  /* Where You'll Be */

  #estimate-page .estimate__facility-details {
    width: 100%;
  }

  #estimate-page .where-youll-be .estimate__estimate-section-emphasized {
    /* display: none; */
  }

  #estimate-page .estimate__facility-name-and-cost {
    flex-direction: column;
  }

  #estimate-page .estimate__facility-name-and-cost p {
    margin-bottom: 8px;
  }

  /* More Facilities */
  #estimate-page .estimate__more-facilities {
    flex-direction: column;
    margin-top: 35px;
  }

  .estimate__more-facilities > div {
    flex-direction: column;
  }

  #estimate-page .estimate__more-facilities-text {
    width: 100%;
  }

  #estimate-page ul.estimate__facilities-list {
    width: 100%;
  }

  .estimate__estimate-section {
    padding: 32px 16px;
  }

  .estimate__estimate-section-title {
    font-size: 18px;
    line-height: 27px;
  }
  .estimate__facilities-list li {
    padding: 8px 24px 8px 16px;
  }

  .estimate__facility-list-item-name {
    font-size: 15px;
    line-height: 24px;
  }
  .estimate__coverage-form-container {
    padding: 32px 24px;
  }
}

.likertLegend {
  font-size: 48px;
  line-height: 53px;
  font-family: 'Crimson Pro', 'Times New Roman', Times, serif;
  font-weight: 900;
  padding-bottom: 24px;
}

.likertText {
  font-family: 'Poppins';
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  margin-top: 20px;
}

.likertResponse:nth-child(1) .likertIndicator {
  background-image: url("https://img.icons8.com/emoji/48/000000/angry-face-emoji--v2.png");
  width: 48px;
  height: 48px;
  border: none;
  background-color: transparent;
  bottom: -10px;
}

.likertResponse:nth-child(2) .likertIndicator {
  background-image: url("https://img.icons8.com/emoji/48/000000/neutral-face.png");
  width: 48px;
  height: 48px;
  border: none;
  background-color: transparent;
}

.likertResponse:nth-child(3) .likertIndicator {
  background-image: url("https://img.icons8.com/emoji/48/000000/cowboy-hat-face.png");
  width: 48px;
  height: 48px;
  border: none;
  background-color: transparent;
}

.likertResponse > input:checked + .likertIndicator {
  background-color: black;
}

.share-feedback button.button {
  margin-top: 24px;
}

@media only screen and (max-width: 768px) {
  .likertLegend {
    font-size: 30px;
    line-height: 33px;
  }

  .likertResponse {
    min-width: unset;
  }

  .likertBand {
    width: 200px;
  }
}

@media only screen and (max-width: 350px) {
  .likertText {
    font-size: 10px;
    line-height: 16px;
  }
}

.save-estimate-text {
  font-size: 16px;
}

.save-estimate-heading {
  font-size: 60px;
  line-height: 67px;
  font-family: "Crimson Pro", "Times New Roman", Times, serif;
  font-weight: 900;
}

.save-your-estimate-modal .send-estimate-form section {
  display: flex;
  justify-content: center;
}

#share-feedback-container {
  display: none;
}

.send-estimate-form button.button {
  margin-top: 24px;
}

.send-estimate-form section {
  display: flex;
  width: 100%;
  justify-content: center;
}

.send-estimate-form .rc-anchor-light.rc-anchor-normal {
  border-radius: 20px !important;
}

@media only screen and (max-width: 768px) {
  .save-estimate-heading {
    font-size: 36px;
  }

  .save-your-estimate-modal p {
    padding-right: 0px;
  }
}

#estimate-pdf-container {
  display: none;
  width: 816px;
}

.estimate-pdf .estimate__estimate-callout {
  background-color: white;
  margin-bottom: 0px;
  padding-top: 40px;
  padding-left: 36px;
  padding-right: 36px;
  padding-bottom: 32px;
}

.estimate-pdf .estimate__callout-main-text,
.estimate-pdf .estimate__estimate-callout-overline-text {
  color: #080707;
  max-width: 100%;
}

.estimate-pdf .estimate__estimate-width.estimate__extra-pdf-padding {
  padding-bottom: 32px;
}

.estimate-pdf .cost-and-coverage .estimate__estimate-section-emphasized,
.estimate-pdf .estimate__estimate-section-emphasized {
  width: 100%;
}

.estimate-pdf .estimate__estimate-section {
  margin-bottom: 0px;
  padding-top: 0px;
  padding-left: 36px;
  padding-right: 36px;
}

.estimate-pdf .estimate__more-facilities {
  background-color: white;
  margin-top: 0px;
  padding-left: 36px;
  padding-right: 36px;
  padding-bottom: 52px;
}

.estimate-pdf .container-with-top-padding {
  border: none;
}

.estimate-pdf .estimate__estimate-section .estimate__estimate-section-container {
  padding-bottom: 24px;
}

.estimate-pdf .estimate__estimate-section .estimate__estimate-section-container div {
  width: 100%;
}

.estimate-pdf .estimate__estimate-section .estimate__estimate-section-container .estimate__estimate-section-emphasized-container {
  padding-right: 8px;
}

.estimate-pdf .estimate__facility-details {
  width: 60%;
}

.estimate-pdf .estimate__more-facilities-text {
  padding: 0px 16px 0px 0px;
  width: 100%;
  margin-bottom: 0px;
}

.estimate-pdf ul.estimate__facilities-list {
  width: 100%;
  margin-bottom: 0px;
}

.estimate-pdf .estimate__facilities-list li {
  padding: 0px;
}

.estimate-pdf .estimate__facility-name {
  padding-right: 8px;
}

#estimate-pdf-2 {
  padding-top: 36px;
}

/* .estimate-pdf .where-youll-be {
  padding-top: 40px;
} */

select.form-control {
  border: none;
  font-size: 16px;
  color: black;
  padding: 0px;
  font-family: Poppins;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: white;
  /* Hide default select arrow for Firefox */
  -moz-appearance: none;
  /* Hide default select arrow for Chrome */
  -webkit-appearance: none;
}

/* Hide default select arrow for IE10 */
select::-ms-expand {
  display: none;
}

.service-page-select {
  background-color: silver;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  letter-spacing: 0.5px;
  font-weight: bold;
  padding: 10px;
  border-bottom: 1px solid black;
  color: black;
}

.select-input-icon {
  right: 12px;
  position: absolute;
  bottom: 18px;
}

.quote-options-select.form-control {
  background-color: transparent;
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 27px;
  padding-left: 8px;
  padding-right: 33px;
  border-bottom: 1px solid #080707;
  border-radius: 0;
  color: black;
}

.custom-form-label {
  font-family: "Poppins";
  font-style: normal;
  letter-spacing: 0.4px;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 0;
}
.quote-options-select.form-control:valid,
.quote-options-select.form-control:focus {
  background-color: transparent;
  background-image: none !important;
  box-shadow: none !important;
}

.quote-options-select.form-control:invalid {
  background-image: none !important;
}

select:required:invalid {
  color: #979797;
}

.quote-options-select::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #979797;
  opacity: 1; /* Firefox */
}

.quote-options-select:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #979797;
  opacity: 1; /* Firefox */
}

.quote-options-select::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #979797;
  opacity: 1; /* Firefox */
}

.quote-options-select:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #979797;
}

.quote-options-select::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #979797;
}

span.icon {
  height: 19px;
  width: 19px;
  content: '';
  background-color: black;
  border-radius: 12px;
  margin-right: 10px;
}

.service-details {
  padding: 0px 40px 40px 40px;
}

.service-name {
  padding-top: 16px;
  padding-bottom: 40px;
}

.service-title {
  padding-top: 40px;
  padding-bottom: 6px;
}

.back {
  display: none;
  align-items: center;
  background-color: #f8f9fa;
}

@media only screen and (max-width: 768px) {

  .back {
    display: flex;
  }

}

.serivce-category-details {
  padding: 0px 40px;
}

.service-groups {
  padding: 40px 40px 0px 40px;
}

.service-group-name {
  padding-top: 16px;
  padding-bottom: 40px;
}

.service-category-container {
  padding-bottom: 40px;
}



.facilities-details {
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.2),
      rgba(219, 225, 241, 0.2)
    ),
    #ffffff;
}

.facilities-details-container {
  width: 896px;
  max-width: 100%;
  margin: 0 auto;
}

.pagination__link {
  color: #838383;
}

.pagination__link--active,
.previous,
.next {
  color: black;
}

li::after {
  content: "·";
  color: black;
}

li:last-child::after,
li:nth-last-child(2)::after,
li:first-child::after {
  content: "";
  color: black;
}

.desktop-location-modal-body {
  max-width: 420px;
  margin: auto;
}

.facilities-list {
  margin-top: 52px;
}

.facilities-list a,
.facilities-list a:hover {
  text-decoration: none;
  color: #080707;
}
.facilities-list-title {
  padding: 4px 40px 17px 40px;
}

.facilities-list,
.show-more-facilities-btn {
  margin-bottom: 40px;
}

.facilities-list-facility {
  padding: 19.5px 40px 16px 32px;
  border-bottom: 0.5px solid #d8d8d8;
}

.location-zipcode-icon {
  top: 36px;
}

.location-arrow-icon {
  top: 40px;
}

button.button.update-location-btn {
  margin-bottom: 24px;
}

.facilities-details h2 {
  font-weight: 600;
  margin: 0px;
}

/* MODAL */
.modal-content {
  border-radius: 16px !important;
}
.update-zipcode-modal {
  max-width: 700px !important;
}
.update-zipcode-modal .modal-body {
  padding: 0 !important;
}
.update-zipcode-modal .modal-content {
  border-radius: 16px !important;
  width: 700px;
  max-width: 100%;
  margin: auto;
  padding-bottom: 40px;
  padding-top: 24px;
}

.update-zipcode-modal .close-modal {
  right: 38px;
  top: 30px;
}
.location-modal-text {
  margin-top: 40px;
  margin-bottom: 0px;
}

.location-modal-description {
  margin-top: 26px;
  margin-bottom: 30px;
}

.update-zipcode-modal__body {
  width: 652px;
  max-width: 100%;
  margin: auto;
}

.update-zipcode-modal__input {
  max-width: 512px;
  width: 100%;
  margin: auto auto 32px;
}

.facilities-details .filter-dropdown {
  width: 266px;
}

.facilities-disclaimer {
  text-align: center;
  padding: 0px 32px 80px 32px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #000000;
}

.facilities-details p.pointer {
  padding: 40px 0 32px;
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .facilities-details-container > :nth-child(-n + 2) {
    padding-inline: 16px;
  }
  .facilities-list-facility {
    padding: 16px;
  }
  .facilities-list {
    margin-top: 32px;
  }
  .facilities-list-mobile-title {
    font-family: "Crimson Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 127.13%;
    letter-spacing: 0.25px;
    color: #363636;
    padding-bottom: 24px;
  }
  .service-group-icon {
    font-size: 10px;
    line-height: 15px;
    padding: 4px 8px;
    width: -webkit-max-content;
    width: max-content;
  }

  .update-zipcode-modal {
    width: 343px !important;
    max-width: 100%;
    margin: auto !important;
  }

  .update-zipcode-modal .modal-body {
    padding: 0 24px !important;
    margin: 0 !important;
  }

  .update-zipcode-modal__body h3 {
    font-size: 24px !important;
    line-height: 27px !important;
  }
  .facilities-details p.pointer {
    padding: 84px 0 32px;
  }
}

.see-more-estimates {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #080707;
  border: none;
  margin: auto;
  display: block;
  background-color: transparent;
}

.all-appts-visible {
  color: #737272;
  cursor: default !important;
}

.dashboard-top-container {
  margin: 40px 0 80px;
}
.dashboard-top-container h1 {
  font-family: "Poppins";
  font-style: normal;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: 0.132px;
}

@media screen and (max-width: 768px) {
  .dashboard-top-container {
    margin: 40px 0 64px;
  }

  .dashboard-top-container h1 {
    font-size: 32px;
    line-height: 48px;
  }

  .dashboard-top-container h5 {
    font-size: 24px !important;
    line-height: 27px !important;
  }
}

.facility-page__section {
  padding: 96px 64px;
}

.facility-page__max-width {
  max-width: 960px;
  margin: auto;
}

.facility-page__verified-text img {
  margin-left: 16px;
  margin-bottom: 2px;
}

.facility-page__facility-information-section {
  width: 100%;
}

.facility-page__cost-comparison-section {
  background-color: #ECEAE8;
}

.facility-page__estimate-section {
  background: #E2D3CA;
}

.facility-page__verified-text {
  font-family: 'Crimson Pro';
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
}

.facility-page__verified-text.verified {
  color: #4C6BBB
}

.facility-page__facility-name {
  font-weight: 700;
  font-size: 64px;
  line-height: 72px;
  letter-spacing: 0.6px;
  margin-bottom: 32px;
}

.facility-page__facility-information {
  display: flex;
  width: 100%;
}

.facility-page__facility-details {
  width: 75%;
}

.facility-page__facility-map {
  width: 100%;
  padding-bottom: 32px;
}

.facility-page__facility-details-item {
  margin-bottom: 32px;
}

.facility-page__facility-detail-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.facility-page__facility-detail-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.132px;
  margin: 0px;
}

.facility-page__facility-page-title {
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;
  letter-spacing: 0.6px;
  margin-bottom: 8px;
}

.facility-page__cost-comparison-description {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.132px;
  margin-bottom: 32px;
}

.facility-page__cost-comparison-graph-section {
  background: linear-gradient(0deg, rgba(183, 196, 228, 0.3) -4.6%, rgba(183, 196, 228, 0) 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
}

.facility-page__cost-comparison-graph {
  height: 207px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 32px;
  -webkit-column-gap: 32px;
          column-gap: 32px;
  align-items: flex-end;
  padding: 0px 32px;
  border-bottom: 1px solid #979797;
  position: relative;
}

.facility-page__cost-bar {
  content: '';
  width: 160px;
  border-radius: 8px 8px 0px 0px;
}

.facility-page__nearby-facility-bar {
  background: #4C6BBB;
}

.facility-page__current-facility-bar {
  z-index: 1;
}

.facility-page__nearby-facility-bar::before {
  content: '';
  background-image: linear-gradient(to right, black 0%, black 50%, transparent 50%);
  background-size: 12px 1px;
  background-repeat: repeat-x;
  height: 1px;
  width: 100%;
  display: block;
  position: absolute;
  left: 0;
  z-index: 0;
}

.facility-page__cost-comparison-graph-labels {
  display: flex;
  justify-content: space-evenly;
  margin-top: 8px;
}

.facility-page__cost-bar-label {
  width: 161px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.132px;
}

.facility-page__cost-comparison-text {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.132px;
  margin-bottom: 0px;
  max-width: 40%;
}

.facility-page__all-services-heading {
  display: flex;
  justify-content: space-between;
}

.facility-page__service-search {
  display: flex;
  width: 60%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.facility-page__search-icon-container {
  border-bottom: 1px solid #636363;
  padding-top: 30px;
}

.facility-page__service-search-input.form-control {
  background-color: transparent;
  border-bottom: 1px solid #636363;
  width: 100%;
  border-radius: 0;
}

.facility-page__service-search-input.form-control:focus {
  box-shadow: none;
  border-color: #636363;
  background-color: transparent;
}

.facility-page__service-search-form-group.form-group {
  margin: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
}

.facility-page__service-search-form-group.form-group .form-text {
  display: none;
}

.facility-page__handl-category.card {
  background-color: transparent;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.132px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #B7C4E4 !important;
  padding: 32px 0px;
}

.facility-page__handl-category-toggle {
  display: flex;
  justify-content: space-between;
}

.facility-page__handl-category-alpha {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.132px;
  margin-top: 32px;
  margin-bottom: 32px;
}

.facility-page__handl-category-alpha-item {
  cursor: pointer;
}

.facility-page__handl-category-alpha-item.active {
  color: #0DA789;
  text-decoration: underline;
}

.facility-page__handl-category-alpha-item.empty {
  color: #A5A09B;
  cursor: auto;
}

.facility-page__handl-category-content.card-body {
  padding: 0;
}

.facility-page__services-count {
  text-align: center;
  margin-top: 48px;
}

.facility-page__services-count-text {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.facility-page__see-more-services {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #080707;
  cursor: pointer;
}

.facility-page__services-count-bar {
  content: '';
  max-width: 360px;
  width: 100%;
  height: 4px;
  background: #C4C4C4;
  border-radius: 4px;
  margin: 0px auto 48px auto;
}

.facility-page__services-count-bar-filled {
  content: '';
  height: 4px;
  background: #080707;
  border-radius: 4px;
  position: relative;
}

.facility-page__services-count-bar-filled::after {
  content: '';
  display: block;
  width: 2px;
  height: 8px;
  background: #080707;
  position: absolute;
  right: 0;
  bottom: 0;
}

@media only screen and (max-width: 768px) {

  .facility-page__section {
    padding: 0;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
    
  }

  .facility-page__section:not(.facility-page__estimate-section) {
    background: #FFFFFF;
    margin-bottom: 32px;
  }
  
  .facility-page__max-width {
    max-width: 100%;
  }

  .facility-page__facility-information-section {
    padding-top: 150px;
  }

  .facility-page__facility-information {
    display: block;
    padding-bottom: 16px;
  }

  .facility-page__verified-text {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.15px;
    padding: 0px 16px;
  }

  .facility-page__facility-name {
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    padding: 0px 16px;
  }

  .facility-page__facility-details {
    
    width: 100%;
  }

  .facility-page__facility-map {
    width: 100%;
    height: 240px;
  }

  .facility-page__facility-page-title {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.15px;
  }

  .facility-page__facility-details-item {
    margin: 0;
    padding: 16px 16px;
  }

  .facility-page__facility-details-item.contact,
  .facility-page__facility-details-item.address {
    display: grid;
    grid-template-columns: 50px 1fr;
  }

  .facility-page__facility-details-item .icon {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .facility-page__facility-details-item.contact {
    border-bottom: 1px solid rgba(151, 151, 151, 0.4);
  }

  .facility-page__cost-comparison-section {
    padding: 32px 0px;
  }

  .facility-page__cost-comparison-section .facility-page__facility-page-title {
    padding: 0px 16px;
  }

  .facility-page__cost-comparison-description {
    display: none;
  }

  .facility-page__cost-comparison-graph-section {
    flex-direction: column;
    padding: 0;
    background: white;
  }

  .facility-page__cost-comparison-text {
    order: 1;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.132px;
    margin-bottom: 24px;
    margin-left: 0px;
    padding: 0px 16px;
    max-width: 100%;
  }

  .facility-page__cost-comparison-graph-and-labels {
    order: 2;
    width: 100%;
  }

  .facility-page__cost-comparison-graph {
    margin: 0;
    width: 100%;
    grid-template-columns: 124px 124px;
    justify-content: space-evenly;
    justify-items: center;
    background: linear-gradient(0deg, rgba(183, 196, 228, 0.3) -4.6%, rgba(183, 196, 228, 0) 100%);
  }

  .facility-page__cost-bar {
    content: '';
    width: 80px;
    border-radius: 8px 8px 0px 0px;
  }

  .facility-page__cost-bar-label {
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.132px;
    width: 124px;
  }

  .facility-page__services-section {
    padding: 32px 16px;
  }

  .facility-page__all-services-heading {
    display: block;
  }

  .facility-page__service-search {
    width: 100%;
  }

  .facility-page__handl-category.card {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.132px;
  }

  .facility-page__handl-category-alpha {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.132px;
    margin-top: 12px;
  }

  .facility-service-item {
    margin-bottom: 24px
  }
  
  
  
}


.content-page__navigation {
  display: flex;
  justify-content: space-evenly;
  background: #EFEFEF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  padding: 16px;
  position: fixed;
  width: 100%;
  z-index: 999;
}

.content-page__navigation-option {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 0px;
  cursor: pointer;
}

.content-page__navigation-option.active {
  text-decoration: underline;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .content-page__navigation {
    margin-top: 58px;
    padding: 0px;
    display: block;
    background-color: transparent;
  }

  .content-page__navigation.dropdown {
    position: fixed;
  }

  .content-page__navigation-toggle {
    border: none;
    font-family: Poppins;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #080707;
    margin: 0px;
    width: 100%;
    background: #EFEFEF;
    padding: 16px 20px 16px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navigation__arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    content:"";
    height: 6px;
  }

  .navigation__arrow.down{
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    
  }

  .navigation__arrow.up{
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }


  .content-page__navigation-options {
    list-style-type:none;
    padding: 0px;
    margin: 0px; 
    width: 100%;
    background-color: white;
  }

  .content-page__navigation-options li{
    padding: 16px 16px;
  }
}

.estimate-form-section__title {
  font-weight: 700;
  font-size: 64px;
  line-height: 72px;
  letter-spacing: 0.6px;
  padding-right: 400px;
  margin-bottom: 24px;
}

.estimate-form-section__description {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.132px;
  margin-bottom: 60px;
}

button.estimate-form-section__button {
  color: white;
  background: #4C6BBB;
  border-radius: 100px;
  padding: 12px 104px;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  margin: 0;
}

button.button.estimate-form__next-btn {
  margin: 0px 0px 0px 20px;
}


/* MOBILE */
.quote-option-input.form-control {
  color: black;
  background-color: #D8D8D8;
  border-bottom: 1px solid black;

  width: 100%;
  padding: 0.75rem 0.75rem 0.75rem 3rem;


}

.estimate-form-section__button.submit-button {
  margin: auto;
  background: #B0B6C6;
}

.estimate-form-section__button.submit-button.active {
  background: #4C6BBB;
}

.estimate-form__input-container {
  position: relative;
  margin-bottom: 64px;
}

@media only screen and (max-width: 768px) {
  .estimate-form-section {
    padding: 80px 16px;
  }

  .estimate-form-section__title {
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    align-items: center;
    padding-right: 100px;
    margin-bottom: 8px;
  }

  .estimate-form-section__description {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.132px;
    margin-bottom: 48px;
  }

  .estimate-form-section__button {
    padding: 8px 64px;
    margin: auto;
  }

  .estimate-form__input-container {
    margin-bottom: 56px;
  }
}

.contact-form {
  margin-top: 16px;
}

.contact-page {
  margin-top: 48px;
}

.contact-input-label {
  position: absolute;
  font-weight: bold;
  z-index: 66;
  left: 23px;
  top: 5px;
}

.contact-input.form-control {
  background-color: white;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 27px;
  min-height: 51px;
  padding: 15px 22px 5px 22px;
  border-radius: 24px;
  margin-top: 24px;
}

.contact-input.form-control:focus {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  border: none;
}

button.button.contact-btn {
  margin-bottom: 40px;
  margin-top: 12px;
}

.captcha-text {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

#thank-you-container {
  display: none;
  width: 100%;
  text-align: center;
  margin: 8px 0px 40px;
}

.thank-you-text {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.17px;
  line-height: 39px;
  margin: 0px;
  font-weight: semi-bold;
  padding-bottom: 24px;
}

.contact-heading {
  margin-top: 40px;
}

@media only screen and (max-width: 768px) {
  .contact-heading {
    font-size: 48px !important;
    line-height: 53px !important;
  }

  .contact-page {
    margin: 70px 0px 120px 0px;
  }

  .contact-section__contact-form {
    padding-inline: 16px;
  }
}

input.form-control.contact-form__input,
textarea.form-control.contact-form__input {
  border-bottom: 1px solid #000000;
  background-color: transparent;
  font-family: Poppins;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.132px;
  padding: 12px;
  border-radius: 0;
}

textarea.form-control.contact-form__input {
  min-height: 173px;
  border: 0.5px solid #000000;
  border-radius: 8px;
}

.contact-form__btn {
  background: #737272;
  padding: 8px 24px;
  margin-top: 40px;
  width: 287px;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 1.25px;
  max-width: 390px;
}

.contact-form__disclaimer {
  font-style: italic;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.132px;
  margin-top: -12px;
}

.contact-form__container .form-group label {
  display: none;
}

.contact-form__container .form-group {
  margin-bottom: 20px;
  margin-top: 46px;
}

.contact-form__container button.button {
  margin-top: 40px;
  margin-bottom: 60px;
}

.contact-form__thank-you-text {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.132px;
  margin-top: 120px;
  margin-bottom: 40px !important;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .contact-form__thank-you-text {
    margin-top: 0px !important;
    margin-bottom: 40px;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.132px;
  }
}

/* .about-us--1,
.about-us--2,
.about-us--3 {
  padding: 100px 0px;
} */

.about-us--1 {
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 1024px;
  grid-gap: 86px;
  gap: 86px;
  padding: 60px 32px 64px;
}

.about-us--2 {
  text-align: center;
  background-color: #4c6bbb;
  padding: 40.5px 0;
}

.about-us--2 p {
  color: #dbe1e3;
}

.about-us--2 section {
  max-width: 600px;
  margin: auto;
}

.about-us--2 section .about-us-heading,
.about-us--2 section .text-large {
  letter-spacing: 1px;
}

.about-us--3 section {
  max-width: 600px;
  padding: 60px 0;
  text-align: center;
  margin: auto;
}

.about-us-heading {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.132px;
  color: #4c6bbb;
}

.about-us--3 .about-us-heading {
  font-weight: 500;
  color: black;
  padding-bottom: 60px;
}

.about-us-text {
  max-width: 543px;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .about-us--1,
  .about-us--3 {
    padding: 32px 16px;
  }

  .about-us--1 {
    padding-top: 80px;
    flex-direction: column;
    max-width: 100%;
    grid-gap: 0;
    gap: 0;
  }

  .about-us--2 .about-us-heading,
  .about-us--3 .about-us-heading {
    font-size: 24px;
    line-height: 36px;
  }

  .about-us--2 .about-us-heading {
    padding-inline: 11px;
  }
  .about-us-heading-container,
  .about-us-text {
    width: 100%;
  }

  .about-us--2 .body-1 {
    font-size: 15px;
    line-height: 24px;
    padding-inline: 32px;
  }
  .about-us--3 section {
    padding: 0px 0px 60px;
  }
}

.glossary {
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 992px;
  padding-inline: 16px;
  width: 100%;
  margin: 0 auto;
}

.glossary-description {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.6px;
}

.glossary-terms {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.6px;
}
.glossary-term__term {
  font-weight: 700;
  margin-bottom: 12px;
}

.glossary-term__def {
  margin-bottom: 24px;
}
.glossary-search-input.form-control {
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 27px;
  margin: auto;
  padding: 10px 15px;
  height: auto;
  border-bottom: none;
  box-shadow: none;
}
.glossary-search-container {
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #636363;
  margin-bottom: 32px;
}

.glossary-search-container img {
  top: 10px;
  position: relative;
}
.glossary-search-container .input-container div input {
  border-bottom: none;
}
.glossary-search-input.form-control:focus {
  box-shadow: none;
  border-bottom: 1px solid #636363;
}

h1.generic-heading {
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;
  letter-spacing: 0.6px;
  margin-bottom: 24px;
}

@media only screen and (max-width: 768px) {
  .glossary-body {
    display: block;
    padding-inline: 16px;
  }

  .glossary-information {
    margin-bottom: 40px;
  }

  .glossary-description {
    font-size: 16px;
    line-height: 24px;
  }

  .glossary {
    margin: 72px auto auto;
    padding-top: 0px;
    padding-inline: 0;
    padding-bottom: 100px;
  }

  h1.generic-heading {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 8px;
    padding-inline: 16px;
  }
}

.search-page {
  background-color: #f8f9fa;
  height: 100%;
}

.search-page__service-groups-list,
.search-page__most-searched-list {
  margin: 0;
  padding: 0;
}

.search-page__most-searched-title {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.6px;
  font-weight: 900;
  padding: 12px;
  margin: 0;
}

.search-page__service-input div {
  width: 100%;
}

.search-page__service-input input {
  width: 100%;
  border: none;
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.5);
  padding: 40px 0px 20px 80px;
  background-color: white;
  

  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 27px;
}

.search-page__service-input input::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #979797;
  opacity: 1; /* Firefox */
}

.search-page__service-input input:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #979797;
  opacity: 1; /* Firefox */
}

.search-page__service-input input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #979797;
  opacity: 1; /* Firefox */
}

.search-page__back-arrow {
  border: solid black;
  border-width: 0 2.5px 2.5px 0;
  display: inline-block;
  padding: 2.5px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  height: 2.5px;
  width: 2.5px;
  margin-right: 40px;

  position: absolute;
  z-index: 999;
  top: 50px;
  left: 40px;
}

.search-icon {
  position: absolute;
  right: 40px;
  top: 45px;
}

.service-list-item.service-serch-list-item {
  padding: 16px;
}

.individual-article-page {
  background: #f8f8f8;
}
.article__article-title {
  letter-spacing: 0.6px;
  padding-top: 48px;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;
}

.article__article-subtitle {
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.6px;
  margin-bottom: 64px;
}

.article__article-container {
  max-width: 960px;
  width: 100%;
  margin: auto auto 40px;
  padding-bottom: 32px;
  border-radius: 0px 0px 20px 0px;
  background-color: white;
}

.article__article-width {
  max-width: 640px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.article__article-author {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.article__author-name {
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.6px;
  margin-bottom: 32px;
}

.article__article-author .article__author-name {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 0px;
}

.article__article-author .article__author-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 0px;
}

.article__author-icon {
  background-color: #c4c4c4;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  margin-right: 16px;
}

.article__article-date {
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.25px;
  margin-top: 56px;
  margin-bottom: 8px;
}

.article__article-content {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.6px;
  margin-bottom: 40px;
}

.article__article-image-container {
  position: relative;
  overflow: hidden;
  /* height: 432px; */
}

.article__article-image {
  width: 100%;
  height: 432px;
  object-fit: cover;
}

.article__article-sources {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.6px;

  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.article__article-sources a {
  font-style: italic;
  text-decoration: none;
  color: black;
}

.article__article-sources p {
  font-weight: 600;
  margin-bottom: 0px;
}

.article__author-bio {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.6px;
  margin-bottom: 0px;
}

.article__reccomended-reading {
  margin: 0px 64px 152px 64px;
}

.article__reccomended-reading .article__article-title {
  font-family: Crimson Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 71px;
  letter-spacing: 0.6px;
  padding-top: 72px;
  margin-bottom: 24px;
  border-top: solid 1px #979797;
}

ul.article-list {
  padding-left: 0px;
}

.article__article-content a {
  color: #4c6bbb;
}

@media only screen and (max-width: 768px) {
  .article__article-image-container {
    max-height: 216px;
  }

  .article__article-title {
    font-size: 28px;
    line-height: 42px;
    letter-spacing: 0.6px;
    margin-top: 32px;
    margin-bottom: 8px;
  }

  .article__article-width {
    padding: 0px 24px;
  }

  .article__reccomended-reading .article__article-title {
    padding-top: 72px;
    margin-right: 24px;
    margin-left: 24px;
  }

  .article__article-subtitle {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.5px;
    margin-bottom: 32px;
  }

  .article__article-content {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.6px;
  }

  .article__author-name {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 24px;
  }

  .article__author-icon {
    width: 48px;
    height: 48px;
  }

  .article__article-author .article__author-name {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
  }

  .article__article-author .article__author-title {
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
  }

  .article__article-date {
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
  }

  .article__reccomended-reading {
    margin: 0px 8px 152px 8px;
  }

  .article__reccomended-reading .article__article-title {
    font-size: 42px;
    line-height: 47px;
    letter-spacing: 0.6px;
    padding-top: 40px;
    margin-top: 40px;
    margin-bottom: 24px;
  }
}

a.article-card__link {
  color: black;
}

a.article-card__link:hover {
  color: black;
  text-decoration: none;
}

.article-card__article-image-container {
  position: relative;
  min-width: 240px;
  height: 160px;
  overflow: hidden;
}

.article-card__article-image {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  min-height:100%;
  min-width:100%; 
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}

.article-card__article-title {
  font-size: 24px;
  line-height: 32px;
}

.article-card__body {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 24px;
  margin-bottom: 32px;
  border-radius: 8px;
}

.article-card__article-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 8px;
  letter-spacing: 0.6px;
}

.article-card__article-date-and-keywords {
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.6px;

  margin-bottom: 16px;
}

.article-card__article-date-and-keywords .article-card__article-keywords {
  font-style: italic;
}

.article-card__article-author {
  display: flex;
  align-items: center;
}

.article-card__author-icon {
  background-color: #C4C4C4;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
}

.article-card__author-name {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.6px;
}

@media only screen and (max-width: 768px) {
  .article-card__body {
    flex-direction: column-reverse;
    padding: 0px;
    margin: 0px 0px 24px 0px;
    border-radius: 4px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  }

  .article-card__article-information {
    padding: 16px 24px 24px 24px;
  }

  .article-card__article-image-container {
    position: relative;
    min-width: 100%;
    height: 160px;
    overflow: hidden;
    border-radius: 4px 4px 0px 0px;
  }

  .article-card__article-image {
    position: absolute;
    left: 50%;
    top: 50%;
    height: auto;
    width: 100%;
    min-height:100%;
    min-width:100%; 
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
  }

  .article-card__article-title {
    font-size: 18px;
    line-height: 27px;
  }
}

.article-card {
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.2),
      rgba(219, 225, 241, 0.2)
    ),
    #ffffff;
  padding-bottom: 162px;
}

.article-card-content {
  max-width: 960px;
  width: 100%;
  margin: auto;
  position: relative;
}

.article-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 16px 19px;
  grid-gap: 16px;
  gap: 16px;
  background: #ffffff;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  width: 100%;
}

.article-card-container a,
.article-card-container a:hover {
  text-decoration: none;
  color: #080707;
}
.article-card-container__img {
  height: 174px;
  width: 555px;
  max-width: 100%;
  object-fit: cover;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 10px;
  margin-bottom: 16px;
}

.article-card-container_author-section {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
}

.article-card .slider-control-bottomcenter li {
  margin-inline: 6.67px;
}

.article-card .slider-control-bottomcenter ul {
  top: 0;
}

.article-card .article-card__see-all-btn {
  margin-top: 24px;
}

.custom-ac-swiper-button-prev {
  left: -32px;
  bottom: 50%;
  position: absolute;
  cursor: pointer;
}

.custom-ac-swiper-button-next {
  right: -32px;
  bottom: 50%;
  position: absolute;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .article-card {
    padding-inline: 16px;
    padding-bottom: 0;
  }

  .article-card__text-container {
    padding-inline: 16px;
  }
  .article-card .swiper {
    padding-bottom: 65px !important;
  }

  .custom-ac-swiper-button-next,
  .custom-ac-swiper-button-prev {
    width: 10px;
    z-index: 22;
    bottom: 9.75%;
  }
  .custom-ac-swiper-button-prev {
    left: 34%;
  }

  .custom-ac-swiper-button-next {
    right: 34%;
  }
}

.articles-container {
  background-color: #f8f8f8;
}

.article-content-container {
  background-color: white;
}
.article-list__body {
  max-width: 960px;
  width: 100%;
  margin: auto;
}

.article-list__page-title {
  font-family: Crimson Pro;
  font-weight: bold;
  font-size: 64px;
  line-height: 72px;
  letter-spacing: 0.6px;

  margin: 48px 0px 24px;
}

.article-list__subtitle {
  font-family: Poppins;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.6px;

  margin-bottom: 40px;
}

.article-list__see-more-button {
  padding: 8px 24px;
  width: 287px;
}

.article-list__filter-and-sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.article-sort {
  display: flex;
  grid-gap: 32px;
  gap: 32px;
}

.article-sort .filter-option,
.article-sort .filter-option-active {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 1.25px;
  margin-right: 0;
  padding: 8px 32px;
}

.article-sort .filter-option-active {
  font-weight: 600;
}
button.article-sort__button {
  background-color: transparent;
  color: #080707;
  box-shadow: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 0px;
  padding: 12px 32px;
}

button.article-sort__button.active-tab {
  border: 2px solid #080707;
  border-radius: 130px;
  font-weight: 600;
}

.article-filter {
  display: flex;
  position: relative;
  border-bottom: 1px solid #080707;
  width: 100%;
  max-width: 412px;
}

.article-filter .input-container div input,
.article-filter .input-container select {
  border-bottom: none;
}

.article-filter .input-and-label {
  padding-top: 0px;
}
.article-filter__search-icon-container {
  border-bottom: 1px solid #636363;
  padding-right: 12px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

ul.article-list__list {
  list-style-type: none;
  padding: 0px;
  margin: 32px 0px;
  width: 100%;
}

a.article-list__article-link {
  color: black;
}

a.article-list__article-link:hover {
  color: black;
  text-decoration: none;
}

.first-article .article-list__article-image-container {
  overflow: hidden;
  display: flex;
  align-items: center;
}

.first-article .article-list__article-image {
  width: 100%;
  min-height: 100%;
  min-width: 100%;
}

.first-article .article-list__article-details {
  display: grid;
  grid-template-columns: minmax(auto, 400px) minmax(auto, 560px);
  grid-column-gap: 24px;
  -webkit-column-gap: 24px;
          column-gap: 24px;
  margin-top: 24px;
  margin-bottom: 104px;
  padding-inline: 24px;
}

.first-article .article-list__article-information {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  max-width: 360px;
}

.articles-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px;
}

.article-list__article-title {
  font-weight: 600;
  margin-bottom: 8px;
  letter-spacing: 0.6px;
}

.first-article .article-list__article-title {
  font-size: 24px;
  line-height: 36px;
}

.article-list__article-date-and-keywords {
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.6px;

  margin-bottom: 16px;
}

.article-list__article-date-and-keywords .article-list__article-keywords {
  font-style: italic;
}

.article-list__article-author {
  display: flex;
  align-items: center;
  margin-bottom: 28px;
}

.article-list__author-icon {
  background-color: #c4c4c4;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
}

.article-list__author-name {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.6px;
}

.article-list__article-summary {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
}

.article-list__article-summary-read-more {
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .articles-container {
    background-color: #f7f7f7;
  }

  .article-list__body {
    margin: 60px 0px 0px 0px;
  }

  .article-list__page-title {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 0.6px;
    margin: 0px;
    padding: 12px 16px 8px 16px;
  }

  .article-list__subtitle {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.6px;
    margin: 0px;
    padding: 0px 16px 32px 16px;
  }

  .article-list__filter-and-sort {
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 16px;
  }

  button.article-sort__button {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.15px;
    padding: 8px 24px;
  }

  .article-sort {
    grid-gap: 16px;
    gap: 16px;
  }
  .article-sort .filter-option,
  .article-sort .filter-option-active {
    padding: 8px 16px;
  }
  .article-filter {
    width: 100%;
    margin-top: 32px;
  }

  .article-filter div:not(.article-filter__search-icon-container) {
    width: 100%;
  }

  .article-filter div {
    font-family: Poppins;
  }

  .article-filter div input {
    width: 100%;
    flex-grow: 1;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.15px;
  }

  .first-article .article-list__article-details {
    display: block;
  }

  .first-article .article-list__article-image-container {
    max-height: 280px;
  }

  .first-article .article-list__article-title {
    font-size: 22px;
    line-height: 33px;
  }

  .first-article .article-list__article-details {
    padding: 0px 16px;
    margin-bottom: 32px;
  }

  .first-article .article-list__article-date-and-keywords {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.25px;
  }

  .article-list__author-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  .article-list__article-summary {
    font-size: 15px;
    line-height: 24px;
    padding-bottom: 24px;
    margin-top: 24px;
  }
  .articles-grid-container {
    grid-template-columns: 1fr;
  }
}

.contact-section__contact-container {
  padding-top: 88px;
  padding-bottom: 69px;
}

.contact-section__contact-body {
  display: flex;
  flex-wrap: wrap;
}

.contact-section__contact-description,
.contact-section__contact-form {
  flex: 1 1;
}

.contact-section__contact-description {
  padding-right: 64px;
}

.contact-section__body-heading {
  font-family: Crimson Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 43px;
  line-height: 48px;
  margin-bottom: 0px;
  padding-bottom: 24px;
}

.contact-section__body-text {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.132px;
}

.contact-section__body-text.mobile-hide {
  display: block;
}

.contact-section__body-text.desktop-hide {
  display: none;
}

@media only screen and (max-width: 768px) {
  .contact-section__contact-container {
    padding: 40px 0px 20px;
  }

  .contact-section__body-heading {
    font-size: 32px;
    line-height: 36px;
    padding: 0 16px 16px;
  }

  .contact-section__body-text {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.132px;
  }

  .contact-section__body-text.desktop-hide {
    display: block;
  }

  .contact-section__body-text.mobile-hide {
    display: none;
  }

  .contact-section__contact-body input.form-control.contact-form__input,
  .contact-section__contact-body textarea.form-control.contact-form__input {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.132px;
  }
}

.press-release__body {
  max-width: 896px;
  width: 100%;
  margin: 48px auto auto;
}

ul.press-release-list {
  list-style-type: none;
  padding: 0px;
  margin-bottom: 60px;
  margin-top: 64px;
}

.press-release__press-release-image {
  height: 270px;
  width: 178px;
  object-fit: cover;
}

.press-release {
  display: flex;
  margin-bottom: 40px;
  grid-gap: 24px;
  gap: 24px;
}

.press-release__press-release-information {
  display: flex;
  flex-direction: column;
}

.press-release__press-release-information .body-1 {
  max-width: 678px;
}
.press-release__press-release-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.6px;
}

.press-release__press-release-summary {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.6px;
  margin: 12px 0px;
}

.press-release__press-release-link {
  color: black;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.6px;
  align-self: center;
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  .press-release {
    flex-direction: column;
  }
  .press-release__body {
    margin: 72px auto auto;
  }
  ul.press-release-list {
    margin-top: 32px;
  }
  .press-release__press-release-information {
    padding-inline: 16px;
  }
  .press-release__press-release-image-wrap {
    margin: auto;
  }
  .press-release__press-release-image {
    width: 343px;
  }
}


.service-group-page .content-page__heading {
  background-color: rgba(0, 64, 60, 0.2);
}

.service-group__section-why {
  padding-bottom: 56px;
}

.service-group__section-varieties{
  padding-bottom: 64px;
}

.service-group__section-faq{
  padding-bottom: 164px;
}


.service-group__section-description {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
  margin-bottom: 16px;
}

ul.service-group__service-list {
  list-style-type:none;
  margin: -32px 0px 32px 0px;
  padding: 0px;
}

.service-group__service-list a:hover {
  text-decoration: none;
}

.service-group__see-all-services-text {
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 1.25px;
  font-weight: 500;
  color: #080707;
}

.service-group__see-all-services-text:hover {
  text-decoration: none;
  color: #080707;
}

ul.service-group__articles-list {
  list-style-type:none;
  padding: 0px;
  margin: 0px;
}

.service-group__articles-section {
  max-width: 896px;
  margin: 86px auto 86px auto; 
}

.service-group__articles-section-title {
  font-family: Crimson Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 67px;
}

.service-group__articles-section-description {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.6px;
  margin-bottom: 24px;
}

.service-group__contact-section {
  max-width: 896px;
  margin: 86px auto 86px auto; 
}

@media only screen and (max-width: 768px) {
  .service-group__section-description {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 24px;
  }

  .service-group__articles-section {
    padding: 102px 8px 0px 8px;
    margin: 0px;
  }

  .service-group__contact-section {
    margin: 0px;
  }

  .service-group__articles-section-title {
    font-size: 42px;
    line-height: 47px;
    letter-spacing: 0.6px;
    padding-right: 8px;
    padding-left: 8px;
  }

  .service-group__articles-section-description {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.6px;
    padding-right: 8px;
    padding-left: 8px;
  }

  .service-group .contact-section__contact-container {
    padding-top: 44px;
  }
}

.content-page {
  font-family: Poppins;
}

.content-page__width {
  max-width: 896px;
  margin: auto;
}

.content-page__heading {
  padding: 124px 64px 64px 64px;
  margin-bottom: 24px;
}

.content-page__name {
  font-weight: bold;
  font-size: 58px;
  line-height: 64px;
  margin-bottom: 24px;  
}

.content-page__description {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
  margin-bottom: 0px;
}

@media only screen and (max-width: 768px) {

  .content-page__heading {
    padding: 148px 16px 48px 16px;
    margin-bottom: 16px;
  }

  .content-page__name {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 16px;  
  }
  
  .content-page__description {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.5px;
  }
}

.question-section {
  background-color: white;
  padding: 32px 0px;
  margin-bottom: 24px;
}

.question-section__width {
  max-width: 896px;
  margin: auto;
}

.question-section__overline {
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.15px;
  margin-bottom: 16px;
}

.question-section__content {
  display: flex;
}

.question-section__title {
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: 0.132px;
  min-width: 350px;
  margin-top: -8px;
  width: 80%;
  padding-right: 50px;
}

.question-section__details {
  width: 100%;
}

.question-section__question-card {
  margin-bottom: 16px;
  color: #767575;
}

.question-section__question-card.active {
  color: #080707;
}

.question-section__question-card-body.card-body {
  padding: 8px 0px;
}

.question-section__toggle {
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.132px;
}

@media only screen and (max-width: 768px) {
  .question-section__content {
    display: block;
  }

  .question-section {
    padding: 32px 16px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  }

  .question-section__overline {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.15px;
    margin-bottom: 8px;
  }

  .question-section__title {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.132px;
    margin-bottom: 24px;
    padding: 0px;
    width: 100%;
  }

  .question-section__toggle {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.132px;
  }
}

.service-page {
  font-family: Poppins;
}

.service-page .content-page__heading {
  background-color: #00403C;
  color: white;
}

.service-page .content-page__description {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
  margin-bottom: 0px;
  color: white;
}

.service-group__section-description {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
  margin-bottom: 16px;
}

.service-page__input.form-control {
  background: #EAEAEA;
  border-radius: 6px;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #7D7D7D;
  border-bottom: 1px solid #979797;
  width: 100%;
  padding: 0px 16px;
  height: 48px;
}

.service-page__estimate-form-label {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.132px;
  width: 100%;
  flex-shrink: 1;
}

.service-page__estimate-form-label.label-margin {
  margin-top: 40px;
}

.service-page__estimate-form-container  {
  margin-bottom: 40px;
  display: flex;
  align-items: baseline;
}

.service-page__estimate-form-container .service-page__estimate-form-label {
  width: 75%;
}

.service-group__section-details .error-message p {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.6px;
  margin-bottom: 4px;
}

.service__see-cash-estimate {
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 1.25px;
  text-align: center;
  margin: 16px auto 0px auto;
}

.service-page__price-text {
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.132px;
  margin: 0px;
  z-index: 2;
  position: relative;
}

button.button.service-heading-btn {
  margin: 72px 0px 0px auto;
  background: #D8D8D8;
  color: #080707;
}

.best-value-pill {
  background: rgba(13, 167, 137, 0.8);
  border-radius: 38px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 4px 18px;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 1px;
  margin-bottom: 16px;
}

.service-page__estimate-form-label.insurance-input {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.5px;
  padding: 0px;
  margin: 40px 0px 8px 0px;
}

.service-page__form-group.form-group {
  margin-bottom: 40px;
}

.service-form__input-container {
  position: relative;
  margin-bottom: 32px;
  width: 100%;
}

.service-page__estimate-form-container .service-form__input-container {
  margin-bottom: 0px;
}

.service-form__input-container input {
  position: relative;
}

.service-form__input-container span{
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.4px;
  margin-top: 4px;
}

@media only screen and (max-width: 768px) {

  .service-group__section-description {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 24px;
  }

  button.button.service-heading-btn {
    width: 100%;
  }

  .service-page__estimate-form-label,
  .service-page__estimate-form-label.insurance-input {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.132px;
    margin-bottom: 8px;
  }

  .service-page__estimate-form-container {
    display: block;
  }
}

.service-page__insured-price-bars,
.service-page__cash-price-bars {
  position: relative;
  z-index: 0;
}

.service-page__cash-price-bars {
  margin-top: 54px;
  margin-bottom: 46px;
}

.service-page__bar {
  position: relative;
}

.service-page__bar.max-bar {
  position: absolute;
  top: 0;
  min-width: 150px;
}

.service-page__bar-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}

.service-page__cash-price-bars .service-page__bar-labels {
  -webkit-animation: show-bar-two 1.2s 0.2s forwards;
  animation: show-bar-two 1.2s 0.2s forwards;
}

.service-page__insured-price-bars .service-page__bar-labels {
  -webkit-animation: show-bar-four 1.2s 0.4s forwards;
  animation: show-bar-four 1.2s 0.4s forwards;
}

.service-page__bar-labels p {
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: white;
  margin: 0px;
}

.service-page__min-cash-price .service-page__antimated-bar:after {
  width: 24px;
  background: #A96355;
  position: absolute;
  height: 100%;
  content: '';
  right: -12px;
  top: 0;
  -webkit-transform: skew(35deg);
          transform: skew(35deg);
  z-index: 1;
}

.service-page__min-insured-price .service-page__antimated-bar:after {
  width: 24px;
  background: #42738F;
  position: absolute;
  height: 100%;
  content: ' ';
  right: -12px;
  top: 0;
  -webkit-transform: skew(35deg);
          transform: skew(35deg);
  z-index: 1;
}

.service-page__min-cash-price .service-page__antimated-bar {
  background-color: #A96355;
  z-index: 1;
  position: relative;
  -webkit-animation: show-bar-one 1.2s 0.1s forwards;
  animation: show-bar-one 1.2s 0.1s forwards;
  padding: 6px 16px 6px 8px;
}

.service-page__max-cash-price .service-page__antimated-bar{
  background-color: #AA3D26;
  text-align: right;
  -webkit-animation: show-bar-two 1.2s 0.2s forwards;
  animation: show-bar-two 1.2s 0.2s forwards;
  border-radius: 0px 36px 36px 0px;
  padding: 6px 16px 6px 8px;
}

.service-page__min-insured-price .service-page__antimated-bar{
  background-color: #42738F;
  z-index: 1;
  position: relative;
  -webkit-animation: show-bar-three 1.2s 0.3s forwards;
  animation: show-bar-three 1.2s 0.3s forwards;
  padding: 6px 16px 6px 8px;
}

.service-page__max-insured-price .service-page__antimated-bar{
  background-color: #22536F;
  text-align: right;
  -webkit-animation: show-bar-four 1.2s 0.4s forwards;
  animation: show-bar-four 1.2s 0.4s forwards;
  border-radius: 0px 36px 36px 0px;
  padding: 6px 16px 6px 8px;
}

/* Bar Graph Horizontal Animations */
@-webkit-keyframes show-bar-one {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes show-bar-one {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes show-bar-two {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes show-bar-two {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes show-bar-three {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes show-bar-three {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes show-bar-four {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes show-bar-four {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.service-index {
  max-width: 992px;
  margin: auto;
  padding: 58px 16px;
}

.service-index__heading-title {
  font-weight: bold;
  font-size: 60px;
  line-height: 67px;
  margin-bottom: 20px;
}

.service-index__heading-description {
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.132px;
  margin-bottom: 32px;
}

.service__list-item.index-page {
  padding: 12px 0px;
}

ul.service-index__list div {
  grid-column-gap: 24px !important;
  -webkit-column-gap: 24px !important;
          column-gap: 24px !important;
}

.service-index__list a:hover {
  text-decoration: none;
}

.service__list-item-overline {
  font-family: Poppins;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  margin: 0px;
}

.index-page .service__list-item-centerline {
  font-family: Poppins;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.132px;
  margin-top: 4px;
  margin-bottom: 0px;
}

.service-index__nav {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.132px;
  margin-top: 48px;
  margin-bottom: 32px;
}

.service-index__nav-item {
  cursor: pointer;
}

.service-index__nav-item.active {
  color: #0DA789;
  text-decoration: underline;
}

.service-index__filter-section {
  display: flex;
  align-items: center;
}

button.button.service-index__filter-btn {
  margin-left: 10px;
}

.service-index__index-group-heading {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.132px;
  text-align: center;
  border-bottom: 2px solid #080707;
  width: 30px;
  margin-top: 12px;
}



@media only screen and (max-width: 768px) {
  .service-index {
    padding: 98px 16px;
  }

  .service-index__heading-title {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 0.6px;
  }
  
  .service-index__heading-description {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
  }

  .service-index__list {
    display: block;
  }

  button.button.service-index__filter-btn {
    display: none;
  }

  .service-index__body {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .service-index__nav { 
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.132px;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 12px;
  }

  .service-index__services-count {
    text-align: center;
    margin-top: 48px;
  }

  .service-index__services-count-text {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .service-index__services-count-bar {
    content: '';
    width: 100%;
    height: 4px;
    background: #C4C4C4;
    border-radius: 4px;
  }

  .service-index__services-count-bar-filled {
    content: '';
    height: 4px;
    background: #080707;
    border-radius: 4px
  }
}

.alphabet-filter {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.132px;
  margin-top: 48px;
  margin-bottom: 32px;
}

.alphabet-filter__item {
  cursor: pointer;
}

.alphabet-filter__item.active {
  color: #0DA789;
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .alphabet-filter { 
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.132px;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 12px;
  }
}

.sidebar-layout {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100%;
  display: block;
}

.sidebar-layout__body-default {
  display: flex;
  min-height: calc(100vh - 80px);
}

.sidebar-layout__body-sign-in {
  display: flex;
  min-height: 80vh;
}

.sidebar-layout__sidebar {
  position: relative;
  z-index: -1;
}

.sidebar-layout__content-container {
  height: 100%;
  flex-grow: 1;
}

.sidebar-layout__content {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  padding: 64px 0px;
}

@media only screen and (max-width: 768px) {
  .sidebar-layout__body-default {
    flex-direction: column;
    min-height: calc(100vh - 60px);
  }

  .sidebar-layout__sidebar {
    order: 2;
    overflow: hidden;
  }

  .sidebar-layout__content {
    order: 1;
    padding: 92px 16px;
    margin: 0px;
  }
}

.sign-in__design-blue {
  position: absolute;
  overflow: hidden;
  height: 105%;
}

.sign-in__design-blue svg {
  width: 32vw;
  height: 100%;
}

.sign-in__design-reset-image {
  position: absolute;
  left: 2vw;
}
.sign-in__design-default-image {
  position: absolute;
  bottom: 10px;
  left: 2vw;
  max-width: 200px;
}

.forgot-password-form {
  max-width: 575px;
  margin: auto;
}

.forgot-password-form button.long-btn {
  margin-top: 104px;
  margin-bottom: 120px;
}
.forgot-password-form__input {
  margin-bottom: 56px;
}

.forgot-password__error-span {
  display: flex;
  justify-content: center;
}

.FAQ__accordian-icon {
  font-size: 25px;
  font-weight: 500;
}
.FAQ__accordian-footer {
  background-color: #eeeff2;
}
.FAQ__accordian-footer p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin-inline: 32px;
  padding-top: 32px;
  margin-bottom: 12px;
}

.FAQ__accordian-card-title.card {
  font-family: "Poppins";
  background-color: #eeeff2;
  padding: 20px 32px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1) !important;
}

.FAQ__accordian-card-title.card .card-body {
  padding-inline: 0px;
}
.FAQ__accordian-card-title .FAQ__accordian-toggle {
  background-color: #eeeff2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sign-up__side-bar {
  background-color: #dbe1f1;
  padding: 56px 24px;
  width: 270px;
  min-width: 270px;
}

.sign-up__side-bar-text {
  position: fixed;
  width: 270px;
  padding: 0px 24px;
  left: 0;
}

.sign-up__side-bar img {
  position: fixed;
  bottom: 0;
  left: -3px;
}

.sign-up__side-bar h2 {
  font-weight: 200;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 0.353796px;
  margin-bottom: 40px;
}

.sign-up__content {
  max-width: 714px;
  margin: 0px auto;
}

.sign-up__content h1 {
  font-weight: 900;
  font-size: 43px;
  line-height: 48px;
  letter-spacing: 0.25px;
  margin-bottom: 16px;
}

.sign-up__subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
}

.consent-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: inline-block;
  margin-left: 12px;
  margin-bottom: 0;
}

.sign-up-form__input {
  margin-bottom: 48px;
}

.sign-up-form__input.with-help-text {
  margin-bottom: 56px;
}

.sign-up-form__consent-input {
  margin-top: 54px;
  margin-bottom: 48px;
  display: flex;
  justify-content: center;
}

.sign-up-form__consent-input input {
  height: 18px;
  width: 18px;
  min-width: 18px;
}

.access-code__input {
  margin-bottom: 16px;
  margin-top: 50px;
}

button.build-profile-btn {
  margin-top: 80px;
}

.sign-up-form__link {
  color: #080707;
  text-decoration: underline;
}

.access-code-helper-container {
  width: 100%;
  text-align: center;
  margin-top: 40px;
}

.access-code-helper {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

.access-code-helper.sent {
  color: #018077;
}

.my-modal .modal-content {
  width: 500px !important;
  height: 319px;
  max-width: none !important;
  border-radius: 20px !important;
}
.my-modal .modal-body {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 0px !important;
}
.sign-up__modal-body {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 32px;
}
.sign-up__modal-body button:first-of-type {
  margin-bottom: 24px;
}
.sign-up__modal-header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  letter-spacing: 0.5px;
}

.sign-up__modal-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 56px;
  /* or 160% */

  letter-spacing: 0.5px;

  /* Color/4. Text/Grey */

  color: #363636;
}

/* updated */

/* ACCOUNT CREATION */
.account-creation-header {
  max-width: 704px;
  margin: auto;
}

.account-creation-sign-up .sign-up-form__consent-input {
  margin: 56px auto 80px auto;
}

.account-creation-sign-up .input-container {
  width: 575px;
  max-width: 100%;
  margin: auto;
  margin-top: 32px;
}
.account-creation-sign-up .sign-in__subtitle {
  width: 704px;
  max-width: 100%;
}

.account-creation-sign-up .sign-in__subtitle {
  margin: 8px auto 0;
}

.account-creation__action-buttons {
  margin-top: 65px;
}

.account-creation__action-buttons button:last-of-type {
  margin-bottom: 80px;
}

.account-creation-helper-description {
  width: 704px;
  max-width: 100%;
  margin: 24px auto 40px;
}

@media only screen and (max-width: 768px) {
  .sign-up__content h1 {
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.25px;
  }

  .sign-up__side-bar h2 {
    font-weight: 200;
    font-size: 24px;
    line-height: 27px;
    letter-spacing: 0.353796px;
  }

  .sign-up__side-bar-text {
    position: absolute;
    width: 160px;
    padding: 0px;
    left: 16px;
  }

  .sign-up__side-bar img {
    position: absolute;
    height: 250px;
    width: 250px;
    bottom: -24px;
    right: -20px;
    left: unset;
  }

  .sign-up-form__consent-input {
    margin-top: 50px;
    margin-bottom: 44px;
  }

  .sign-up__subtitle {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .sign-up__modal-body {
    padding: 8px 8px 16px;
  }
}

.sign-in__content h1 {
  font-weight: 900;
  font-size: 43px;
  line-height: 48px;
  letter-spacing: 0.25px;
  margin-bottom: 16px;
  color: #080707;
  white-space: nowrap;
}

.sign-in__subtitle {
  color: #d94411;
  font-weight: 400;
  font-size: 18px;
  min-height: 27px;
  letter-spacing: 0.5px;
  margin-bottom: 28px;
}

.sign-in__content {
  max-width: 650px;
  margin: auto;
}

.sign-in-form__input {
  margin-bottom: 32px;
}

.sign-in-form__remember-me {
  display: flex;
  align-items: center;
}

.sign-in-form__remember-me input {
  height: 18px;
  width: 18px;
  min-width: 18px;
}

.sign-in-form__remember-me p {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  display: inline-block;
  margin-left: 12px;
  margin-bottom: 0;
}

.sign-in-form__remember-me-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

.sign-in-btn {
  margin-top: 80px;
  margin-bottom: 32px;
}

.sign-in__outline-btn > button.button.outline-btn.active {
  background-color: transparent;
}

.sign-in__or-element {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}

.sign-in__or-element span {
  margin: 0px 16px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #080707;
}

.sign-in__or-bar {
  height: 1px;
  width: 40px;
  background-color: #000000;
}

.sign-in__privacy-link {
  color: #080707;
  display: flex;
  justify-content: center;
  font-family: Poppins;
  font-style: Regular;
  font-size: 11px;
  line-height: 17px;
  line-height: 100%;
  vertical-align: top;
  margin-bottom: 8px;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 32px;
}

.sign-in__privacy-link:hover,
.sign-in__privacy-link:visited {
  color: #080707;
}

/* ACCOUNT CREATION */
.account-creation-main-content {
  width: 100%;
  max-width: 704px;
  margin: 0 auto;
}

.account-creation-margin-top {
  margin-top: 56px;
}
.account-creation-header {
  max-width: 704px;
  margin: auto;
}
.account-creation-sign-up .input-container {
  width: 575px;
  max-width: 100%;
  margin: auto;
  margin-top: 32px;
}
.account-creation-sign-up .sign-in__subtitle {
  width: 704px;
  max-width: 100%;
}

.account-creation-sign-up .sign-in__subtitle {
  margin: 8px auto 0;
}

.account-creation__action-buttons {
  margin-top: 65px;
}

.account-creation__action-buttons button:last-of-type {
  margin-bottom: 80px;
}

.account-creation-helper-description {
  width: 704px;
  max-width: 100%;
  margin: 24px auto 40px;
}

@media only screen and (max-width: 768px) {
  .sign-in__design {
    display: none;
  }

  .account-creation-main-content {
    padding: 40px 16px 0;
  }
  .sign-in__content {
    width: 100%;
  }

  .sign-in__content h1 {
    margin-bottom: 8px;
    white-space: normal;
  }

  .sign-in__subtitle {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
  }

  .sign-in__sidebar {
    display: none;
  }
  .account-creation-margin-top {
    margin-top: 48px;
  }
  .sign-in__privacy-link {
    margin-bottom: 14px;
  }
}

.settings__side-bar {
  width: 328px;
  background: #e5e5e5;
  padding: 64px 32px;
  z-index: 0;
}

.settings__content {
  padding-left: 32px;
  padding-right: 32px;
  background-color: #fff;
  margin: auto;
}

.settings__page-title {
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.15px;
  margin-bottom: 24px;
}

.settings__home.settings-page h1 {
  font-weight: 200;
  font-size: 45.94px;
  line-height: 51px;
  letter-spacing: 0.353796px;
  margin-bottom: 24px;
}

.settings__side-bar a,
.settings__page-content a,
.plan-details-useful-info a {
  color: black;
  text-decoration: underline;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}

.settings-page .body-1,
.settings-page .body-2,
.settings-page .body-3 {
  margin-bottom: 8px;
}

.settings__subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.5px;
  margin-bottom: 24px;
  color: #080707;
}

.settings__page-section button {
  margin: 0;
}

/* COMPLETION PROGRESS */

.settings__profile-completion-descripton {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.settings__profile-completion-text {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.settings__profile-completion-percentage {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
}

.settings__profile-completion-bar {
  background-color: #c4c4c4;
  width: 100%;
  height: 10px;
  display: block;
  border-radius: 8px;
}

.settings__profile-completion-bar-filled {
  height: 100%;
  background-color: #4c6bbb;
  display: block;
  border-radius: 8px;
}

/* WHAT'S NEXT */

.settings__whats-next {
  margin-top: 24px;
}

.settings__whats-next p {
  margin-bottom: 8px;
}

.settings__whats-next li {
  margin-bottom: 12px;
}

/* SIDEBAR PRIVACY NOTICE */

.settings__privacy-notice {
  margin-top: 80px;
}

.settings__privacy-notice p.settings__privacy-notice-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 16px;
}

.settings__privacy-notice p {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.15px;
  margin-bottom: 8px;
}

/* BULLET LIST */

ul.settings__bullet-list {
  list-style-type: disc;
  padding-left: 1.5em;
}

ul.settings__bullet-list p {
  display: inline-block;
  position: relative;
  left: -4px;
}

/* HOME */
.settings__home {
  max-width: 655px;
  margin: auto;
}

.settings__home-container {
  padding: 40px 32px;
}
.settings___home-insurance-info {
  margin-bottom: 32px;
}

.settings__home-card-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.132px;
  margin-bottom: 24px;
}

.settings__home-card {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
  padding: 24px;
  cursor: pointer;
  margin-bottom: 40px;
}

.settings__help-center-card-body {
  padding-inline: 0 !important;
  padding-top: 8px !important;
  font-family: "Poppins";
  font-style: normal;
}

.settings__home.settings-page .settings__profile-completion {
  margin-bottom: 32px;
  margin-top: 32px;
}

/* MY PROFILE */

.settings__my-profile .settings__page-title {
  margin-bottom: 30px;
}

.settings__my-profile .settings__whats-next {
  margin-bottom: 80px;
}

.settings__my-profile .settings__page-section:not(:last-child) {
  margin-bottom: 88px;
}

.settings__noneditable-data {
  padding-bottom: 16px;
}

.settings__my-profile .settings__description {
  margin-bottom: 32px;
}

.settings__my-profile .settings__input {
  margin-bottom: 60px;
}

.settings__my-profile .settings__input .read-only {
  color: #363636;
}

.settings__my-profile .width-576 {
  margin-top: 64px;
}
.settings__service-preference-section {
  margin-bottom: 20px;
}

.settings__service-preference-section p {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 12px;
}

.settings__service-preference-section b {
  font-weight: 600;
}

/* DELETION ACTIONS POPUP*/
.settings__deletion-modal {
  width: 576px !important;
  max-width: 100% !important;
  margin: 4.75rem auto !important;
}

.settings__deletion-modal .sign-up__modal-body {
  padding-top: 0px;
}

.settings__deletion-modal .sign-up__modal-body button:first-of-type {
  margin-top: 16px;
}

.settings__deletion-modal .sign-up__modal-header {
  width: 496px;
  max-width: 100%;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.15px;
  color: #000000;
}
.deletion-actions__popup-input {
  width: 343px;
  margin: 0 auto 32px;
  max-width: 100%;
}

/* NOTIFICATIONS */

.settings__notifications .settings__page-section {
  padding-top: 36px;
  padding-bottom: 32px;
}

/* PRIVACY */

.settings__deletions {
  margin-bottom: 40px;
}

.settings__deletion-section {
  margin-top: 40px;
}

.settings__privacy-card-title.card {
  padding: 20px 24px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1) !important;
}

.settings__privacy-card-title .settings__accordian-toggle {
  display: flex;
  justify-content: space-between;
}

.settings__notifications .settings__page-section:not(:last-child) {
  border-bottom: 1px solid #c6c6c6;
}

.settings__edit-password .settings__input label {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
}

.settings__edit-password .settings__input input {
  padding-top: 30px;
}

.settings__edit-password .settings__input .input-icon.input-action {
  top: unset;
  bottom: 8px;
}

.settings__edit-password .settings__input .read-only {
  color: #080707;
}

.settings__edit-password .settings__input.password-confirmation .read-only {
  color: #dbdbdb;
}

.mobile-only {
  display: none;
}

/* HELP CENTER */
.width-704 {
  max-width: 704px;
  margin: auto;
}

.width-576 {
  max-width: 576px;
  margin: auto;
}

.help-subtitle {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.132px;
  color: #080707;
  padding: 40px 24px 8px;
  margin-bottom: 0;
}

.help-center-card-title .settings__accordian-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.help-center-card-title.card {
  padding: 24px 24px 0;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border: none;
}

.help-accordian-span-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.132px;
  color: #363636;
}

.help-accordian-span-icon {
  font-weight: 600;
  font-size: 22px;
}

.help-center-view-all-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 88px;
  margin-top: 24px;
}
.help-center-view-all {
  text-transform: uppercase;
  color: #080707;
  margin-bottom: 0;
  margin-right: 1em;
  cursor: pointer;
  letter-spacing: 0.5px;
}

.help-contact-card {
  max-width: 512px;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 24px 0px;
  background: #ffffff;
  border-bottom: 1px solid rgb(0 0 0 / 25%);
}
.help-contact-card .plan-details-main {
  margin-bottom: 24px;
}
.external-links__container {
  width: 576px;
  max-width: 100%;
  margin: 16px auto;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.external-links__container div {
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 21px 32px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.03);
}
.external-links__container .help-contact-card {
  border-bottom: 0;
}
.external-links__container p {
  margin-bottom: 0;
}

.external-links__container img {
  width: 24px;
}
/* INSURANCE PLAN */
.settings__page-subtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
  color: #000000;
}

.plan-details-main {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;
}

.plan-details-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #080707;
}

.plan-details-items {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #080707;
  margin-bottom: 0;
  padding-bottom: 24px;
}

.plan-details-useful-info {
  margin-top: 56px;
}
.plan-details-useful-info li {
  margin-bottom: 1em;
}
@media only screen and (max-width: 768px) {
  .settings__side-bar,
  .mobile-hide {
    display: none;
  }

  .mobile-only {
    display: block;
  }

  .settings__page-title {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.132px;
    color: #000000;
  }

  .settings__content {
    background-color: #f8f8f8;
    padding: 0px;
  }

  .settings__home.settings-page h1 {
    margin-bottom: 16px;
  }

  .settings-page {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 49px;
  }

  .settings___home-insurance-info .body-3 {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .settings__home-info {
    padding-inline: 16px;
  }

  .settings__home-card {
    background-color: #ffffff;
    padding: 24px 16px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  }

  .settings__home-card-title {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.132px;
  }

  .settings__profile-completion-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.6px;
  }

  .settings__profile-completion-percentage {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .settings__home-card .settings__my-profile-data {
    display: flex;
    flex-direction: column;
  }

  .settings__home-card .settings__profile-completion {
    order: 1;
  }

  .settings__home-card .settings__bullet-list-container {
    order: 2;
  }

  .settings__my-profile.settings-page .settings__page-section {
    padding: 26px 16px;
    margin-bottom: 0px;
  }

  .settings__noneditable-data {
    padding-bottom: 36px;
  }

  .settings__my-profile .settings__input {
    margin-bottom: 48px;
  }

  .settings__my-profile .width-576 {
    margin-top: 0px;
  }

  .settings__page-section button {
    margin: auto;
  }

  .settings__my-profile.settings-page
    .settings__page-section.settings__service-preferences {
    padding: 44px 16px;
  }

  .mobile-only .settings__profile-completion-section {
    padding: 24px 16px;
  }

  .settings__my-profile .settings__whats-next {
    margin-top: 36px;
    margin-bottom: 0px;
  }

  .settings__my-profile .settings__page-title {
    margin-bottom: 0px;
    margin-left: 16px;
  }

  .settings__privacy-and-security .settings__back-button p,
  .settings__my-profile .settings__back-button p {
    margin-left: 16px;
  }

  .settings__back-button p.body-2 {
    margin-bottom: 24px;
  }

  .settings__my-profile .settings__whats-next p.body-2 {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.5px;
  }

  .settings__notifications.settings-page {
    background-color: #ffffff;
    padding: 98px 16px 80px 16px;
  }

  .settings__notifications.settings-page .settings__page-section {
    border-bottom: none;
  }

  .settings__notifications.settings-page .settings__page-title {
    margin-bottom: 0px;
  }

  .settings__privacy-and-security .settings__page-title,
  .settings__edit-password,
  .settings__deletions,
  .settings__page-subtitle,
  .plan-details-title,
  .plan-details-useful-info {
    padding: 0px 16px;
  }

  /* HELP CENTER */
  .help-subtitle {
    padding: 16px;
  }
  .help-center-card-title.card {
    padding: 8px 40px 0px 16px;
    background-color: #f8f8f8;
  }

  .help-accordian-span-title {
    font-size: 16px;
    line-height: 40px;
  }

  .help-accordian-span-title {
    max-width: 295px;
  }

  .settings__deletion-modal {
    width: 343px !important;
    padding: 0;
    margin: 0 !important;
  }

  .settings__deletion-modal .modal-content .modal-header {
    padding: 0;
  }

  .settings__deletion-modal .sign-up__modal-header {
    font-size: 18px;
    line-height: 27px;
    width: 267px;
  }

  .settings__deletion-modal .sign-up__modal-body {
    padding: 0;
  }

  .help-center-view-all-container {
    margin-bottom: 52px;
  }
  .help-contact-card {
    padding: 24px 16px 0;
  }

  .external-links__container {
    background-color: #fff;
    margin-top: 32px;
  }

  .external-links__container div {
    padding: 21px 27px 21px 20px;
  }
  .settings__privacy-information .help-subtitle {
    padding: 26px 16px 20px 16px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.132px;
  }
}

.toggle-switch-container {
  display: flex;
  justify-content: space-between;
}
.toggle-switch {
  position: relative;
  width: 36px;
  display: inline-block;
  text-align: left;
  top: 8px;
}

.toggle-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
}
.checkbox {
  display: none;
}
.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
}
.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 20px;
  padding: 0;
  line-height: 20px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}
.inner:before {
  content: "";
  padding-left: 10px;
  background-color: #018077;
  color: #fff;
}
.inner:after {
  content: "";
  padding-right: 10px;
  background-color: #A1ADAD;;
  color: #fff;
  text-align: right;
}
.switch {
  display: block;
  width: 16px;
  height: 16px;
  margin: 2px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.checkbox:checked + .label .inner {
  margin-left: 0;
}
.checkbox:checked + .label .switch {
  right: 0px;
}

.billing-onboarding .progress,
.lets-handl-this .progress {
  height: 8px;
}

.billing-onboarding .pointer {
  margin: 72px auto 40px;
}
.billing-onboarding__container
  h5:first-of-type:not(.hipaa-form__text-container-heading) {
  width: 670px;
  max-width: 100%;
  margin: auto;
}

.billing-onboarding__container .onboarding-steps__next-btn {
  margin-top: 80px;
  margin-bottom: 40px;
}

.billing-onboarding__container .onboarding-steps-skip-btn {
  margin-bottom: 24px;
}

.hipaa-form__section .onboarding-steps__next-btn {
  margin-top: 24px;
}

.hipaa-form__section .hipaa-form__submit-btn {
  margin-bottom: 228px;
}

.billing-onboarding__container .input-container,
.hipaa-form .input-container {
  width: 670px;
  max-width: 100%;
  margin: auto;
  margin-top: 24px;
}

.billing-onboarding .sign-up-form__consent-input {
  margin: 54px auto auto;
}

.billing-onboarding__container .dates-of-service-text {
  position: relative;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  cursor: pointer;
  margin: 8px auto 24px;
  width: 670px !important;
  max-width: 100%;
}

/* ACCOUNT CREATION */
.billing-onboarding__account-creation {
  margin-top: 0px;
}
.billing-onboarding__account-creation .input-container {
  width: 575px;
  max-width: 100%;
  margin: auto;
  margin-top: 32px;
}
.billing-onboarding__container p:first-of-type,
.billing-onboarding__account-creation .sign-in__subtitle,
.account-creation__access-code-text-container,
.billing-onboarding__container
  .billing-onboarding__account-creation
  h5:first-of-type {
  width: 704px;
  max-width: 100%;
}

.billing-onboarding__account-creation .sign-in__subtitle {
  margin: 8px auto 0;
}

.account-creation__action-buttons {
  margin-top: 65px;
}

.account-creation__action-buttons button:last-of-type {
  margin-bottom: 80px;
}

.account-creation-helper-description {
  width: 704px;
  max-width: 100%;
  margin: 24px auto 40px;
}
/* HIPAA CONSENT */
.hipaa-form {
  counter-reset: step;
}

.hipaa-form__section {
  width: 575px;
  max-width: 100%;
  margin: auto;
}

.hipaa-form__heading {
  margin: 40px auto 45px;
}

.hipaa-form__heading:nth-last-of-type() {
  margin: 42.5px auto 42.5px;
}
.hipaa-form__heading::before {
  content: counter(step);
  counter-increment: step;
  display: inline-block;
  width: 32px;
  height: 32px;
  background-color: #dbe1e3;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #0f3544;
  padding-top: 3px;
  border-radius: 50%;
  margin-right: 1rem;
}

.view-pdf-link {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.5px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #000000;
}

.hipaa-form__pdf-container {
  width: 575px;
  background: #dbe1e3;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  padding-top: 22px;
  padding-bottom: 22px;
  grid-gap: 14px;
  gap: 14px;
  margin-top: 42.5px;
  margin-bottom: 24px;
}

.hipaa-form__sign-form-helper-text {
  margin-bottom: 40px;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #080707;
}
.hipaa-form__canvas-container {
  width: 575px;
  height: 112px;
  max-width: 100%;
  margin: auto auto 48px;
}

.sigCanvas {
  border: 1px solid #000000;
  overflow: hidden !important;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  position: relative;
}
.hipaa-form__add-signature-text {
  position: absolute;
  width: 575px;
  max-width: 100%;
  top: 35%;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #080707;
}

.hipaa-form__text-container {
  width: 704px;
  max-width: 100%;
  margin: auto;
}

.hipaa-form__text-container ul * {
  margin-bottom: 8px;
}
.hipaa-form__text-container li:not(:first-child) {
  list-style-type: disc;
  margin-left: 26px;
}

.width-904 {
  width: 904px;
  max-width: 100%;
}

.hipaa-form .mobile-search__close {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.hipaa-form .hipaa-form__return-to-bill {
  margin-top: 40px;
  margin-bottom: 16px;
}

.hipaa-form .hipaa-form__back {
  margin-top: 72px;
  margin-bottom: 24px;
}

.bill-tracker-update__ul {
  margin-left: 2rem;
  margin-top: 2rem;
  margin-bottom: 58.5px;
}

/* BILL REVIEW ONBOARDING */
.lets-handl-this {
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.2),
      rgba(219, 225, 241, 0.2)
    ),
    #ffffff;
}
.lets-handl-this-main-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  grid-gap: 32px;
  gap: 32px;
  width: 100%;
  max-width: 960px;
  background: #ffffff;
  border-radius: 20px;
  margin: 40px auto 56px;
}

.lets-handl-this-heading {
  width: 100%;
  max-width: 896px;
  margin-right: auto;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 0.353796px;
}

.lets-handl-this ul {
  list-style-type: disc;
  margin-left: 1.5rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}

.lets-handl-this li {
  margin-bottom: 12px;
}
.billing-onboarding__container
  .lets-handl-this
  .lets-handl-this-additional-text {
  max-width: 750px;
  width: 100%;
}

.lets-handl-this__desktop-only-sxn {
  align-items: center;
  justify-content: center;
  grid-gap: 42px;
  gap: 42px;
  text-align: center;
}

.lets-handl-this__desktop-only-sxn > div {
  align-items: center;
  justify-content: center;
  grid-gap: 8px;
  gap: 8px;
}
.lets-handl-this .lets-handl-this__desktop-only-sxn p {
  width: 100%;
  max-width: 233px;
}

@media screen and (max-width: 768px) {
  .billing-onboarding {
    background: linear-gradient(
        0deg,
        rgba(242, 241, 239, 0.8),
        rgba(242, 241, 239, 0.8)
      ),
      #ffffff;
  }
  .billing-onboarding .progress,
  .lets-handl-this .progress {
    height: 6px;
    margin-top: 50px;
    margin-bottom: 40px;
  }

  .billing-onboarding__container {
    position: relative;
  }
  .billing-onboarding__container
    h5:first-of-type:not(
      .hipaa-form__text-container-heading,
      .account-creation-header
    ) {
    width: 343px;
    max-width: 100%;
    letter-spacing: 0.353796px;
  }
  .billing-onboarding__container .onboarding-steps__next-btn {
    margin-bottom: 24px;
  }

  .billing-onboarding__container .input-container {
    margin-top: 48px;
  }
  .hipaa-form .input-container {
    margin-top: 40px;
  }
  .billing-onboarding .sign-in-form__remember-me {
    margin-top: 50px;
  }

  .billing-onboarding__container .pointer {
    margin: 40px auto 24px;
  }
  .billing-onboarding .sign-in__privacy-link {
    margin-top: 35%;
  }
  .billing-onboarding .account-creation__action-buttons .sign-in__privacy-link {
    margin-top: 40px;
  }
  .billing-onboarding .access-code-helper-container {
    margin-top: 40px;
  }
  .hipaa-form__pdf-container {
    width: 343px;
    max-width: 100%;
    margin: auto;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    padding-top: 16px;
    padding-bottom: 16px;
    grid-gap: 14px;
    grid-gap: 14px;
    gap: 14px;
    margin-top: 42.5px;
    margin-bottom: 24px;
  }
  .hipaa-form__canvas-container {
    width: 343px;
  }

  .hipaa-form__section .hipaa-form__submit-btn {
    margin-bottom: 40px;
  }

  .sigCanvas {
    background: #f6f6f6;
  }
  .hipaa-form__add-signature-text {
    position: absolute;
    top: 35%;
  }

  .hipaa-form__heading {
    margin: 24px auto 45px;
  }

  .hipaa-form__heading:nth-last-of-type() {
    margin: 26.5px auto 42.5px;
  }

  .billing-onboarding__container,
  .hipaa-form__section:not(
      .billing-onboarding__container .hipaa-form__section
    ) {
    padding-inline: 16px;
  }

  input[type="date"]::before {
    content: attr(placeholder);
    position: absolute;
    color: #080707;
  }

  input[type="date"] {
    color: #ffffff;
  }

  input[type="date"]:focus,
  input[type="date"]:valid {
    color: #080707;
  }

  input[type="date"]:focus::before,
  input[type="date"]:valid::before {
    content: "";
  }

  .billing-onboarding__account-creation {
    margin-top: -40px;
  }

  .lets-handl-this {
    background: #fff;
  }
  .lets-handl-this-main-section {
    padding: 0;
    margin: 0px auto 56px;
  }

  .lets-handl-this-heading {
    font-weight: 200;
  }
  .billing-onboarding__container .lets-handl-this .body-2 {
    max-width: 343px;
    width: 100%;
  }

  .lets-handl-this__mobile-only-img {
    position: absolute;
    bottom: 0px;
    width: 100%;
    margin-left: -16px;
  }
  .account-creation-signin-faq {
    margin: 0px -16px;
  }
  .billing-onboarding__container p:first-of-type {
    width: auto;
  }
}

.sort-modal {
}
.sort-modal .modal-header {
  padding: 0 !important;
}
.mobile-sort-container {
}

.mobile-sort-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  padding-left: 16px;
  margin-bottom: 27px;
}

.mobile-sort-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.132px;
  color: #363636;
}

.mobile-sort-options {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-between;
  padding-inline: 16px;
  margin-bottom: 37px;
}

.mobile-sort-container .sort-btn {
  margin-bottom: 40px;
}

.mobile-sort-radio:checked {
  accent-color: #000000;
}
.mobile-sort-options input[type="radio"] {
  -webkit-transform: scale(1.7);
          transform: scale(1.7);
}
@media screen and (max-width: 768px) {
  .sort-modal {
    max-width: 100% !important;
    position: absolute !important;
    margin-left: -24px !important;
    bottom: 0;
    width: 100vw !important;
    min-height: -webkit-fit-content !important;
    min-height: -moz-fit-content !important;
    min-height: fit-content !important;
    margin-bottom: 0 !important;
  }

  .mobile-sort-container {
    display: flex;
    border: 0;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 20px 20px 0px 0px;

    position: absolute;
    bottom: 0;
    width: 100vw;
    max-width: 100%;
    margin-left: -16px;

    -webkit-backdrop-filter: opacity(20%);

            backdrop-filter: opacity(20%);
  }
  .mobile-sort-container > div {
    align-items: center;
  }
  .mobile-sort-title {
    padding-top: 22px;
  }
}

.billing-dashboard {
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.2),
      rgba(219, 225, 241, 0.2)
    ),
    #ffffff;
  padding: 32px 40px;
  min-height: 100vh;
}

.homefeed {
  width: 894px;
  max-width: 100%;
  margin: auto;
}

.grid-container {
  display: grid;
  grid-template-columns: minmax(220px, 1fr) minmax(480px, 2fr);
  grid-gap: 42px;
  gap: 42px;
}

.grid-card {
  background-color: transparent;
  border-radius: 8px;
  width: 587px;
  max-width: 100%;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 32px;
}

.billing-dashboard p {
  margin-bottom: 0;
}

.incomplete {
  color: #e78a00;
}

.reviewed {
  color: #0da789;
}

.submitted {
  color: #4f4f4f;
}

/* SORT/FILTER STYLES */
.filter-option {
  padding: 4px 12px;
  height: -webkit-max-content;
  height: max-content;
  border: 0.8px solid #c6c6c6;
  border-radius: 20px;
  white-space: nowrap;
  cursor: pointer;
  color: #000000;
  margin-right: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.filter-option-active {
  padding: 4px 12px;
  height: -webkit-max-content;
  height: max-content;
  border-radius: 20px;
  white-space: nowrap;
  cursor: pointer;
  background: rgba(103, 179, 173, 0.2);
  color: #00403c;
  border: 1.8px solid #018077;
  margin-right: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.5px;
  display: flex;
  flex-wrap: wrap;
}

.filter-option-disabled {
  color: #c6c6c6;
  border: 0.8px solid #737272;
}

/* CHECKBOX STYLES */
.billing-dashboard-checkbox input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  width: 24px;
  height: 24px;
  border: 3px solid #000;
  border-radius: 5px;
  -webkit-transform: translateY(-0.075em);
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
.billing-dashboard-checkbox input[type="checkbox"]:checked:before {
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
}
.billing-dashboard-checkbox input[type="checkbox"]:before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: -webkit-transform 0.12s ease-in-out;
  transition: transform 0.12s ease-in-out;
  transition: transform 0.12s ease-in-out, -webkit-transform 0.12s ease-in-out;
  box-shadow: inset 1em 1em #000;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-clip-path: polygon(
    28% 38%,
    41% 53%,
    75% 24%,
    86% 38%,
    40% 78%,
    15% 50%
  );
  clip-path: polygon(28% 38%, 41% 53%, 75% 24%, 86% 38%, 40% 78%, 15% 50%);
}

.billing-dashboard .callout-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.billing-dashboard__results-container {
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  grid-gap: 24px;
  gap: 24px;
  width: 587px;
  max-width: 100%;
  background: rgba(219, 225, 241, 0.5);
  border-radius: 6px;
}

.billing-dashboard__results-card {
  display: flex;
  flex-direction: column;
  padding: 16px;
  grid-gap: 8px;
  gap: 8px;
  background: #ffffff;
  position: relative;
  cursor: pointer;
}

.billing-dashboard__results-card ul {
  margin-bottom: 0;
}
/* NEW TAG */
.billing-dashboard__new-tag {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  grid-gap: 10px;
  gap: 10px;
  position: absolute;
  width: 41px;
  height: 16px;
  top: -8px;
  background: #018077;
  border-radius: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
}

.billing-dashboard .mobile-search__close {
  margin: 0 0 42px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.remaining-tasks__li {
  list-style-type: disc;
  margin-left: 1.25rem;
}
@media screen and (max-width: 768px) {
  .billing-dashboard {
    background: #f2f1ef;
    padding: 0;
  }

  .gray-section {
    background: #f2f1ef;
  }

  .billing-dashboard .mobile-search__close {
    margin: 0 0 24px;
  }
  .hipaa-form .mobile-search__close {
    margin-top: 0px;
  }
  .text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 16px 32px;
  }
  .page-headings {
    font-family: "Crimson Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0.353796px;
    color: #000000;
  }

  .billing-dashboard__results-container {
    max-width: 100%;
    padding: 0;
    background-color: transparent;
  }

  .billing-dashboard__results-card-status {
    letter-spacing: 1.5px;
  }
  .billing-dashboard__results-card-main-text {
    font-weight: 500;
  }
  .billing-dashboard__results-card-bolded {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.5px;
    color: #000000;
  }
}

.no-bills {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 587px;
  height: 345px;
  background-color: rgba(219, 225, 241, 0.5);
  border-radius: 6px;
}

.no-bills p {
  text-align: center;
  padding-inline: 32px;
}
.no-bills .secondary-bold {
  margin-bottom: 6px;
}
.no-bills img {
  width: 80px;
  height: 80px;
  margin-bottom: 24px;
  margin-top: 40px;
}

@media screen and (max-width: 768px) {
  .no-bills {
    padding-inline: 16px;
    background-color: transparent;
    width: 100%;
    height: 100%;
  }

  .no-bills p {
    padding-inline: 0;
  }

  .no-bills .body-2 {
    margin-bottom: 24px;
  }
}

.bill-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  grid-gap: 24px;
  gap: 24px;
  width: 265px;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
}

.bill-sidebar p {
  text-align: center;
}

.bill-tracker {
  padding: 40px;
}
.bill-tracker__top-container {
  width: 872px;
  margin: auto;
  max-width: 100%;
}

.bill-tracker__tracker-section {
  margin-top: 72px;
}

.bill-tracker .mobile-search__close {
  margin: 0 0 16px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.bill-tracker__circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid #696969;
  border-radius: 50%;
  position: relative;
  top: 30px;
}

.bill-tracker__container:not(:last-child) .bill-tracker__circle::after {
  content: "";
  height: 80px;
  width: 0.5px;
  position: absolute;
  background-color: #696969;
  top: calc(100% + 5px);
  left: 50%;
}
.bill-tracker__box p {
  margin-bottom: 0;
}
.bill-tracker__box {
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 70px 12px 16px;
  grid-gap: 4px;
  gap: 4px;
  width: 552px;
  max-width: 100%;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #080707;
  margin-bottom: 32px;
  cursor: pointer;
}

.bill-tracker__container {
  width: 600px;
  max-width: 100%;
  margin: auto;
  grid-gap: 16px;
  gap: 16px;
}

.bill-tracker__lockedIcon {
  width: 24px;
  height: 24px;
}
/* BORDER STYLES */
.border-completed {
  border: 1px solid #018077;
}

.inactive-container div {
  border: 1px solid #868686;
  color: #868686;
}

.inactive-container p {
  color: #868686;
}
.green-circle {
  background-color: #018077;
}
@media screen and (max-width: 768px) {
  .bill-tracker {
    padding: 0px;
  }
  .bill-tracker__top-container {
    background: #dbe1f1;
    padding: 60px 16px 16px;
  }

  .bill-tracker__top-container h5 {
    width: 300px;
    max-width: 100%;
    letter-spacing: 0.353796px;
  }

  .bill-tracker__tracker-section {
    margin-top: 64px;
  }
  .bill-tracker__container {
    width: 311px;
  }
  .bill-tracker__circle {
    width: 24px;
    height: 24px;
    top: 11.5px;
  }

  .bill-tracker__container:not(:last-child) .bill-tracker__circle::after {
    top: calc(100% + 18px);
  }

  .bill-tracker__box {
    width: 271px;
    max-width: 100%;
    margin-bottom: 60px;
  }

  .bill-tracker__lockedIcon {
    width: 18px;
    height: 18px;
  }
}

.upload-section {
  display: flex;
  flex-direction: column;
  padding: 0 16px 24px;
  grid-gap: 40px;
  gap: 40px;
  margin-bottom: 40px;
}

.upload-section p {
  line-height: 1.5rem;
  margin-bottom: 0;
}

.submit-bill__text-area {
  width: 575px;
  height: 142px;
  padding: 16px 12px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  margin-bottom: 60px;
}
.submit-bill__text-area:focus {
  border: 1px solid #d9d9d9;
  outline: none;
}

/* MODAL */
.submit-bill-modal .modal-content {
  width: 800px !important;
  background: #ffffff;
  border-radius: 20px !important;
  padding: 48px 32px 32px;
}
.submit-bill-modal .modal-content .modal-header {
  padding: 0 !important;
}

.submit-bill-modal .modal-body {
  padding: 0 !important;
}

.submit-bill-modal__action-buttons {
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  gap: 40px;
}
@media screen and (max-width: 768px) {
  .submit-bill__text-area {
    width: 343px;
    height: 142px;
    margin-bottom: 40px;
  }
  .submit-bill-modal {
    margin: 0 !important;
  }
  .submit-bill-modal .modal-content {
    width: 343px !important;
  }
  .submit-bill-modal .sign-up__modal-text {
    width: 279px;
    max-width: 100%;
  }
}

.submission-details {
  width: 575px;
  max-width: 100%;
  margin: 64px auto 72px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
}

.submission-details a {
  color: inherit;
}
@media screen and (max-width: 768px) {
  .submission-details {
    padding: 24px 16px;
    margin: 0 auto 32px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  }
}

.what-to-expect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 40px;
  grid-gap: 24px;
  gap: 24px;
  width: 935px;
  max-width: 100%;
  margin: auto;
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.5),
      rgba(219, 225, 241, 0.5)
    ),
    #ffffff;
}
.what-to-expect__container {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .what-to-expect {
    align-items: flex-start;
    padding: 24px 16px;
    background: #ffffff;
  }
  .what-to-expect__container {
    flex-direction: column;
    width: 100%;
    justify-content: center;
  }
}

.home-top-container {
  background: linear-gradient(180deg, #dbe1f1 0%, rgba(237, 240, 248, 0) 100%);
  padding: 36px 34px 132px;
}

/* DESKTOP ONLY GRID SECTION */

.desktop-grid-image {
  grid-area: image;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.desktop-grid-textbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: textContent;
  background: linear-gradient(
      0deg,
      rgba(183, 196, 228, 0.25),
      rgba(183, 196, 228, 0.25)
    ),
    #ffffff;
  grid-gap: 85px;
  gap: 85px;
  height: 100%;
  justify-content: center;
}

.desktop-grid-textbox button {
  margin: 0;
}

.home-grid-section {
  width: 100%;
  margin: auto;
}
.home-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "image textContent";
}

.home-grid-container:last-of-type {
  grid-template-areas: "textContent image";
}

/* TEXT STYLES */
.home-title {
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.132px;
}

.home-subtitle {
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: 0.132px;
  color: #000000;
  margin-bottom: 32px;
  max-width: 845px;
}

.desktop-only-text {
  max-width: 662px;
  margin-bottom: 0;
}

.home-switch-container {
  display: flex;
  flex-direction: column;
}

.width-845 {
  max-width: 845px;
  width: 100%;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .home-top-container {
    background: #f8f9fc;
    padding: 32px 16px;
    margin-top: 42px;
  }
  .desktop-only-text {
    display: none;
  }
  .home-subtitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.5px;
    margin-bottom: 0;
  }
  .home-grid-section {
    display: none;
  }
  /* MOBILE ONLY SECTION */
  .home__mobile-only-cost-estimator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px 16px;
    background: #ffffff;
  }
  .home__mobile-only-bill-review {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 16px;
    background: linear-gradient(
        0deg,
        rgba(183, 196, 228, 0.25),
        rgba(183, 196, 228, 0.25)
      ),
      #ffffff;
  }
  .home-switch-container {
    flex-direction: column-reverse;
  }
}

.go-handl-health {
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.2),
      rgba(219, 225, 241, 0.2)
    ),
    #ffffff;
  padding: 80px 0;
}
.go-handl-health-card-container {
  max-width: 912px;
  width: 100%;
  margin: auto;
  position: relative;
}
.go-handl-health-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  grid-gap: 32px;
  gap: 32px;
  width: 288px;
  background: #ffffff;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin: auto;
}
/* SWIPER STYLES */
.swiper {
  padding-bottom: 55px !important;
}

.swiper-pagination-bullet {
  background: rgba(165, 160, 155, 0.5) !important;
  opacity: 1;
  width: 10.67px !important;
  height: 10.67px !important;
  margin: 0 6.5px 0 !important;
}

.swiper-pagination-bullet-active {
  background-color: #a5a09b !important;
}

.swiper-button-disabled {
  display: none !important;
}

.custom-ghh-swiper-button-prev {
  left: -32px;
  bottom: 50%;
  position: absolute;
  cursor: pointer;
}

.custom-ghh-swiper-button-next {
  right: -32px;
  bottom: 50%;
  position: absolute;
  cursor: pointer;
}

.go-handl-title {
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  letter-spacing: 0.132px;
  color: #080707;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .go-handl-health {
    padding: 52px 0 134px;
  }
  .swiper-button-disabled {
    display: block !important;
  }

  .custom-ghh-swiper-button-next,
  .custom-ghh-swiper-button-prev {
    bottom: 13px;
    position: absolute;
    z-index: 22;
    width: 10px;
  }

  .custom-ghh-swiper-button-prev {
    left: 33%;
  }

  .custom-ghh-swiper-button-next {
    right: 33%;
  }

  .go-handl-title {
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.5px;
    margin-bottom: 16px;
  }
}

.our-tools {
  padding: 32px 16px;
  position: relative;
}

.our-tools p {
  margin-bottom: 0;
}

.our-tools-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 16px;
  grid-gap: 24px;
  gap: 24px;
  max-width: 100%;
  border-radius: 10px;
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.5),
      rgba(219, 225, 241, 0.5)
    ),
    #ffffff;
}

.our-tools-container img {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.our-tools-container .flex-col {
  align-items: center;
  justify-content: center;
  align-self: center;
}

.our-tools-container .text-subheading {
  margin-bottom: 10px;
}
.our-tools-container .body-2 {
  text-align: center;
  max-width: 311px;
  margin-bottom: 24px;
}

/* ARROWS */
.our-tools .swiper {
  padding-bottom: 32px !important;
}
.custom-otc-swiper-button-prev,
.custom-otc-swiper-button-next {
  bottom: 44px;
  position: absolute;
  z-index: 22;
  cursor: pointer;
  width: 10px;
}

.custom-otc-swiper-button-prev {
  left: 35%;
}

.custom-otc-swiper-button-next {
  right: 35%;
}
/* LAST TOOL CONTAINER */
.last-tools-container .body-2,
.last-tools-container .text-subheading {
  text-align: center;
}
.last-tools-container .subheading-crimson-pro {
  margin-bottom: 32px;
}

.last-tools-container > div.flex-col {
  grid-gap: 95px;
  gap: 95px;
}
@media screen and (max-width: 768px) {
  .our-tools {
    background: linear-gradient(
        0deg,
        rgba(219, 225, 241, 0.2),
        rgba(219, 225, 241, 0.2)
      ),
      #ffffff;
  }
}

.legalese ul {
  list-style-type: disc;
  padding-left: 2rem;
}

.legalese {
  background: linear-gradient(
      0deg,
      rgba(219, 225, 241, 0.2),
      rgba(219, 225, 241, 0.2)
    ),
    #fff;
}
.legalese-container {
  padding: 64px 40px;
  width: 894px;
  max-width: 100%;
  margin: auto;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 3px 10px 2px rgb(0 0 0 / 7%);
}

.legalese li {
  padding-top: 16px;
}
.legalese p {
  line-height: 1.714;
  letter-spacing: 0.08rem;
}

.legalese a {
  color: #080707;
  text-decoration: underline;
}
.legalese table,
th,
td {
  border: 1px solid;
}

@media screen and (max-width: 768px) {
  .legalese-container {
    padding-inline: 16px;
  }
}

.toast-notification {
  position: fixed;
  width: 300px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px 20px;
  background-color: white;
  z-index: 99999;
  bottom: 10px;
  right: 10px;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 30%);
  border-radius: 24px;
  display: flex;
  align-items: center;
}

.hide {
  display: none;
}

