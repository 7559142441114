.content-page__navigation {
  display: flex;
  justify-content: space-evenly;
  background: #EFEFEF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  padding: 16px;
  position: fixed;
  width: 100%;
  z-index: 999;
}

.content-page__navigation-option {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 0px;
  cursor: pointer;
}

.content-page__navigation-option.active {
  text-decoration: underline;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .content-page__navigation {
    margin-top: 58px;
    padding: 0px;
    display: block;
    background-color: transparent;
  }

  .content-page__navigation.dropdown {
    position: fixed;
  }

  .content-page__navigation-toggle {
    border: none;
    font-family: Poppins;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #080707;
    margin: 0px;
    width: 100%;
    background: #EFEFEF;
    padding: 16px 20px 16px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navigation__arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    content:"";
    height: 6px;
  }

  .navigation__arrow.down{
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    
  }

  .navigation__arrow.up{
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }


  .content-page__navigation-options {
    list-style-type:none;
    padding: 0px;
    margin: 0px; 
    width: 100%;
    background-color: white;
  }

  .content-page__navigation-options li{
    padding: 16px 16px;
  }
}
