@import url("https://fonts.googleapis.com/css2?family=Poppins");

input,
input.form-control {
  border: none;
  font-family: Poppins;
  position: relative;
  z-index: 0;
  border: none;
}

.form-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  margin-top: 0 !important;
  margin-bottom: 8px;
  max-width: 424px;
}

.input-arrow-down {
  top: 25px;
  right: 25px;
}

.form-group.select-input {
  margin-bottom: 0px;
}

.select-input-options {
  margin-top: 8px !important;
  padding: 15px 0px;
}

.select-input-options li:hover {
  background-color: #efefef;
}
