.contact-section__contact-container {
  padding-top: 88px;
  padding-bottom: 69px;
}

.contact-section__contact-body {
  display: flex;
  flex-wrap: wrap;
}

.contact-section__contact-description,
.contact-section__contact-form {
  flex: 1 1 0px;
}

.contact-section__contact-description {
  padding-right: 64px;
}

.contact-section__body-heading {
  font-family: Crimson Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 43px;
  line-height: 48px;
  margin-bottom: 0px;
  padding-bottom: 24px;
}

.contact-section__body-text {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.132px;
}

.contact-section__body-text.mobile-hide {
  display: block;
}

.contact-section__body-text.desktop-hide {
  display: none;
}

@media only screen and (max-width: 768px) {
  .contact-section__contact-container {
    padding: 40px 0px 20px;
  }

  .contact-section__body-heading {
    font-size: 32px;
    line-height: 36px;
    padding: 0 16px 16px;
  }

  .contact-section__body-text {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.132px;
  }

  .contact-section__body-text.desktop-hide {
    display: block;
  }

  .contact-section__body-text.mobile-hide {
    display: none;
  }

  .contact-section__contact-body input.form-control.contact-form__input,
  .contact-section__contact-body textarea.form-control.contact-form__input {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.132px;
  }
}
