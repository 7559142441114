.home-top-container {
  background: linear-gradient(180deg, #dbe1f1 0%, rgba(237, 240, 248, 0) 100%);
  padding: 36px 34px 132px;
}

/* DESKTOP ONLY GRID SECTION */

.desktop-grid-image {
  grid-area: image;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.desktop-grid-textbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: textContent;
  background: linear-gradient(
      0deg,
      rgba(183, 196, 228, 0.25),
      rgba(183, 196, 228, 0.25)
    ),
    #ffffff;
  gap: 85px;
  height: 100%;
  justify-content: center;
}

.desktop-grid-textbox button {
  margin: 0;
}

.home-grid-section {
  width: 100%;
  margin: auto;
}
.home-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "image textContent";
}

.home-grid-container:last-of-type {
  grid-template-areas: "textContent image";
}

/* TEXT STYLES */
.home-title {
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.132px;
}

.home-subtitle {
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: 0.132px;
  color: #000000;
  margin-bottom: 32px;
  max-width: 845px;
}

.desktop-only-text {
  max-width: 662px;
  margin-bottom: 0;
}

.home-switch-container {
  display: flex;
  flex-direction: column;
}

.width-845 {
  max-width: 845px;
  width: 100%;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .home-top-container {
    background: #f8f9fc;
    padding: 32px 16px;
    margin-top: 42px;
  }
  .desktop-only-text {
    display: none;
  }
  .home-subtitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.5px;
    margin-bottom: 0;
  }
  .home-grid-section {
    display: none;
  }
  /* MOBILE ONLY SECTION */
  .home__mobile-only-cost-estimator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px 16px;
    background: #ffffff;
  }
  .home__mobile-only-bill-review {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 16px;
    background: linear-gradient(
        0deg,
        rgba(183, 196, 228, 0.25),
        rgba(183, 196, 228, 0.25)
      ),
      #ffffff;
  }
  .home-switch-container {
    flex-direction: column-reverse;
  }
}
