.loading {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.handl-logo{
  margin: 0px;
  font-weight: 900;
}
