.individual-article-page {
  background: #f8f8f8;
}
.article__article-title {
  letter-spacing: 0.6px;
  padding-top: 48px;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;
}

.article__article-subtitle {
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.6px;
  margin-bottom: 64px;
}

.article__article-container {
  max-width: 960px;
  width: 100%;
  margin: auto auto 40px;
  padding-bottom: 32px;
  border-radius: 0px 0px 20px 0px;
  background-color: white;
}

.article__article-width {
  max-width: 640px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.article__article-author {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.article__author-name {
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.6px;
  margin-bottom: 32px;
}

.article__article-author .article__author-name {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 0px;
}

.article__article-author .article__author-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 0px;
}

.article__author-icon {
  background-color: #c4c4c4;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  margin-right: 16px;
}

.article__article-date {
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.25px;
  margin-top: 56px;
  margin-bottom: 8px;
}

.article__article-content {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.6px;
  margin-bottom: 40px;
}

.article__article-image-container {
  position: relative;
  overflow: hidden;
  /* height: 432px; */
}

.article__article-image {
  width: 100%;
  height: 432px;
  object-fit: cover;
}

.article__article-sources {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.6px;

  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.article__article-sources a {
  font-style: italic;
  text-decoration: none;
  color: black;
}

.article__article-sources p {
  font-weight: 600;
  margin-bottom: 0px;
}

.article__author-bio {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.6px;
  margin-bottom: 0px;
}

.article__reccomended-reading {
  margin: 0px 64px 152px 64px;
}

.article__reccomended-reading .article__article-title {
  font-family: Crimson Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 71px;
  letter-spacing: 0.6px;
  padding-top: 72px;
  margin-bottom: 24px;
  border-top: solid 1px #979797;
}

ul.article-list {
  padding-left: 0px;
}

.article__article-content a {
  color: #4c6bbb;
}

@media only screen and (max-width: 768px) {
  .article__article-image-container {
    max-height: 216px;
  }

  .article__article-title {
    font-size: 28px;
    line-height: 42px;
    letter-spacing: 0.6px;
    margin-top: 32px;
    margin-bottom: 8px;
  }

  .article__article-width {
    padding: 0px 24px;
  }

  .article__reccomended-reading .article__article-title {
    padding-top: 72px;
    margin-right: 24px;
    margin-left: 24px;
  }

  .article__article-subtitle {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.5px;
    margin-bottom: 32px;
  }

  .article__article-content {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.6px;
  }

  .article__author-name {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 24px;
  }

  .article__author-icon {
    width: 48px;
    height: 48px;
  }

  .article__article-author .article__author-name {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
  }

  .article__article-author .article__author-title {
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
  }

  .article__article-date {
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
  }

  .article__reccomended-reading {
    margin: 0px 8px 152px 8px;
  }

  .article__reccomended-reading .article__article-title {
    font-size: 42px;
    line-height: 47px;
    letter-spacing: 0.6px;
    padding-top: 40px;
    margin-top: 40px;
    margin-bottom: 24px;
  }
}
