.no-bills {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 587px;
  height: 345px;
  background-color: rgba(219, 225, 241, 0.5);
  border-radius: 6px;
}

.no-bills p {
  text-align: center;
  padding-inline: 32px;
}
.no-bills .secondary-bold {
  margin-bottom: 6px;
}
.no-bills img {
  width: 80px;
  height: 80px;
  margin-bottom: 24px;
  margin-top: 40px;
}

@media screen and (max-width: 768px) {
  .no-bills {
    padding-inline: 16px;
    background-color: transparent;
    width: 100%;
    height: 100%;
  }

  .no-bills p {
    padding-inline: 0;
  }

  .no-bills .body-2 {
    margin-bottom: 24px;
  }
}
